import { Platform, useWindowDimensions } from "react-native";
import React, { memo, useEffect, useRef, } from "react";
import AddAlert from "components/AddAlert";
import { useTheme } from "@rneui/themed";
import ModalRenderer from "components/ModalRenderer";
import { STYLES } from "constants/STYLES";
import { ScrollView } from "react-native-gesture-handler";

const CreateAlertModal = ({ market, showCreateAlertModal, setShowCreateAlertModal }) => {
  const { theme } = useTheme();
  const ref = useRef();
  const { width } = useWindowDimensions();
  const isLargeScreen = Platform.OS === "web" && width >= theme.breakpoints.tablet;

  useEffect(() => {
    showCreateAlertModal && openModal();
  }, [showCreateAlertModal]);

  const openModal = () => {
    ref.current?.handleOpenModalPress();
  };

  const closeModal = () => {
    ref.current?.handleCloseModalPress();
  };

  return (
    <ModalRenderer
      ref={ref}
      showModal={showCreateAlertModal}
      setShowModal={setShowCreateAlertModal}
      title={isLargeScreen && "Create Alert"}
      content={
        <ScrollView style={{ flex: 1, width: STYLES.BOTTOM_SHEET.MAX_WIDTH }}>
          <AddAlert showLabel onCancel={closeModal} market={market}/>
        </ScrollView>
      }
      useAnimatedSnapPoints={false}
    />
  );
};

export default memo(CreateAlertModal, (prevProps, nextProps) => {
  prevProps.showCreateAlertModal === nextProps.showCreateAlertModal
});
