import React from "react";
import { ActivityIndicator, ScrollView, Text, useWindowDimensions, View } from "react-native";
import useDeleteAccount from "./useDeleteAccount";
import useStyles from "./styles";
import { Button, Icon, useTheme } from "@rneui/themed";
import { SCREENS_NAMES } from "constants/screens";
import { useNavigation } from "@react-navigation/native";

const DeleteAccount = () => {
  const dimensions = useWindowDimensions();
  const { theme } = useTheme();
  const styles = useStyles(dimensions.width);
  const navigation = useNavigation();
  const { deleteAccount, isLoading } = useDeleteAccount();

  return (
    <ScrollView>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <Text style={styles.title} adjustsFontSizeToFit>Delete Account</Text>
          <Text style={styles.description}>
            By proceeding, you acknowledge that you have reviewed and agree to the following terms. Upon deleting your
            Finalyst account:
          </Text>
          <Text style={styles.description}>
            1- All account data will be permanently erased.
          </Text>
          <Text style={styles.description}>
            2- You will lose access to login, premium features or money-based plans you have purchased.
          </Text>

          <View style={styles.actionCard}>
            <Button
              title="Delete Account"
              icon={isLoading
                ? <ActivityIndicator size={"small"} color={theme.colors.white} style={styles.iconStyle}/>
                : <Icon name="deleteuser" type="ant-design" size={20} color={theme.colors.white}
                        style={styles.iconStyle}/>}
              disabled={isLoading}
              onPress={deleteAccount}
              titleStyle={styles.buttonText}
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.button}
              color="error"
            />

            <Button
              title="Keep Account"
              disabled={isLoading}
              onPress={() => navigation.navigate(SCREENS_NAMES.ACCOUNT)}
              titleStyle={styles.buttonText}
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.button}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default DeleteAccount;

DeleteAccount.propTypes = {};

DeleteAccount.defaultProps = {};
