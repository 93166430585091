import PropTypes from "prop-types";
import { View, TouchableOpacity } from "react-native";
import React from "react";
import { Icon } from "@rneui/themed";

/**
 * Component for rendering a Custom Icon.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the icon.
 * @param {number} props.size - The size of the icon.
 * @param {string} props.type - The type of the icon.
 * @param {string} props.color - The color of the icon.
 * @param {string} props.reverseColor - The color for the reverse mode of the icon.
 * @param {Object} props.iconStyle - The style object for the icon.
 * @param {boolean} props.reverse - Whether to render the icon in reverse mode.
 * @param {Function} props.onPress - The function to be called on icon press.
 * @param {boolean} props.isClickable - The isClickable is used when icon just show Icon or button
 * @returns {React.JSX} - The rendered component.
 */

function CustomIcon({
  name,
  type,
  color,
  onPress,
  size,
  iconStyle,
  containerStyle,
  reverse,
  reverseColor,
  isClickable,
}) {
  const CustomIcon = () => (
    <Icon
      name={name}
      type={type}
      color={color}
      size={size}
      iconStyle={iconStyle}
      reverse={reverse}
      reverseColor={reverseColor}
    />
  );

  return (
    <View style={containerStyle}>
      {isClickable ? (
        <TouchableOpacity onPress={onPress}>
          <CustomIcon />
        </TouchableOpacity>
      ) : (
        <CustomIcon />
      )}
    </View>
  );
}
export default CustomIcon;

CustomIcon.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  onPress: PropTypes.func,
  iconStyle: PropTypes.object,
  reverseColor: PropTypes.string,
  reverse: PropTypes.bool,
  isClickable: PropTypes.bool,
};

CustomIcon.defaultProps = {
  name: "delete",
  size: 12,
  type: "material-community",
  color: "#DF803C", //orange
  reverse: false,
  reverseColor: "#fff",
  iconStyle: {},
  isClickable: true,
};
