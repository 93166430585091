import React from "react";
import ViewStrategy from "containers/ViewStrategy";

const Strategy = ({ route }) => {
  const strategyId =
    route.params && route.params.strategyId ? route.params.strategyId : null;

  return <ViewStrategy strategyId={strategyId} />;
};

export default Strategy;
