import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";
export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    alignItems: "center",
  },
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    margin: STYLES.PADDING.MD,
    padding: STYLES.PADDING.LG,
  },
  errorText: {
    margin: 5,
    marginBottom: 12,
    paddingHorizontal: 6,
    color: theme.colors.error,
    minHeight: 20,
  },
  title: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  description: {
    color: theme.colors.grey3,
    marginTop: 12,
  },
  inputsWrapper: {
    marginTop: 24,
  },
  inputLabelStyle: {
    fontWeight: "light",
  },
  inputContainerStyle: {
    paddingHorizontal: 0,
    marginBottom: 0,
    padding: 0,
  },
  inputInputContainerStyle: {
    marginTop: 6,
    borderBottomWidth: 2,
    borderWidth: 2,
    borderRadius: STYLES.BORDER_RADIUS.LG,
    padding: 6,
  },
  inputStyle: {
    color: theme.colors.whiteText,
  },
  forgotPasswordTitleStyle: {
    color: theme.colors.whiteText,
    fontSize: 16,
    fontWeight: "light",
  },
  forgotPasswordButtonStyle: {
    width: "auto",
    alignSelf: "flex-start",
    marginBottom: 12,
  },
  loginButtonStyle: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
  },
  loginTitleStyle: {
    color: theme.colors.white,
  },
}));
