const initialState = {
  isSignedIn: false,
  profile: {},
  token: null,
  refreshToken: null,
  isLoading: false,
  subscriptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      return { ...state, profile: action.payload, isLoading: false };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
        isSignedIn: !!action.payload,
        isLoading: false,
      };
    case "SET_REFRESH_TOKEN":
      return { ...state, refreshToken: action.payload, isLoading: false };

    case "SET_SUBSCRIPTIONS":
      return { ...state, subscriptions: action.payload };

    default:
      return state;
  }
};

export { initialState, reducer };
