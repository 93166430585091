import { View, useWindowDimensions, Platform } from "react-native";
import React, { memo, useEffect, useMemo, useState } from "react";
import { AlertsContext } from "context/alerts/AlertsContext";
import TabButton from "components/TabButton";
import TabView from "components/TabView";
import LatestAlerts from "../LatestAlerts";
import ActiveAlerts from "../ActiveAlerts";
import { Text, useTheme } from "@rneui/themed";
import { BlurView } from "expo-blur";

const AlertsView = ({ contextValue, contentWidth }) => {
  const dimensions = useWindowDimensions();
  const { theme } = useTheme();

  const { activeAlerts = [], isLoading, isSuccess } = contextValue;

  const failedLoadingAlerts = useMemo(() => {
    return !isLoading && (!Array.isArray(activeAlerts) || !isSuccess);
  }, [activeAlerts, isLoading, isSuccess]);

  const isEmpty = useMemo(() => {
    return (
      (!isLoading &&
        !failedLoadingAlerts &&
        Array.isArray(activeAlerts) &&
        activeAlerts.length === 0) ||
      !Array.isArray(activeAlerts)
    );
  }, [isLoading, activeAlerts]);
  const [tabIndex, setTabIndex] = useState(0);
  const ButtonItems = useMemo(
    () => (isEmpty ? ["Active Alerts"] : ["Alerts History", "Active Alerts"]),
    [isEmpty]
  );

  useEffect(() => {
    isEmpty && setTabIndex(0);
  }, [isEmpty]);

  if (contextValue.isFromScreen && dimensions.width > 971) {
    return (
      <AlertsContext.Provider value={contextValue}>
        <View
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignSelf: "center",
          }}
        >
          <View
            style={{
              display: isEmpty ? "none" : "flex",
              width: "60%",
              borderRadius: 24,
              padding: 12,
              maxWidth: 600,
            }}
          >
            <Text
              adjustsFontSizeToFit
              style={{
                display: "flex",
                fontSize: 22,
                color: theme.colors.primary,
                padding: 12,
                lineHeight: 24,
              }}
            >
              Alerts History
            </Text>
            {!isEmpty && <LatestAlerts />}
          </View>
          <BlurView
            style={{
              width: isEmpty ? "100%" : "40%",
              borderRadius: 24,
              padding: 12,
              maxWidth: isEmpty ? "none" : 400,
            }}
            intensity={50}
            tint={theme.mode}
          >
            <Text
              adjustsFontSizeToFit
              style={{
                fontSize: 22,
                color: theme.colors.primary,
                padding: 12,
                lineHeight: 24,
              }}
            >
              Active Alerts
            </Text>

            <ActiveAlerts />
          </BlurView>
        </View>
      </AlertsContext.Provider>
    );
  }

  return (
    <AlertsContext.Provider value={contextValue}>
      <View style={{ flex: 1, width: "100%" }}>
        <View
          style={{
            backgroundColor:
              theme.mode === "light" &&
              contextValue.isFromScreen &&
              Platform.OS !== "web"
                ? theme.colors.white
                : "transparent",
            width: "90%",
            alignSelf: "center",
            borderRadius: 12,
            maxWidth: 600,
            marginBottom: 12,
          }}
        >
          <TabButton
            items={ButtonItems}
            onChange={setTabIndex}
            selectedIndex={tabIndex}
            customStyles={{
              root: {
                paddingVertical: 6,
              },
            }}
          />
        </View>
        {isEmpty ? (
          <TabView
            key={isEmpty.toString()}
            style={{ flex: 1 }}
            contentWidth={contentWidth}
            tabIndex={tabIndex}
            onChange={setTabIndex}
          >
            <ActiveAlerts />
          </TabView>
        ) : (
          <TabView
            key={isEmpty.toString()}
            style={{ flex: 1 }}
            contentWidth={contentWidth}
            tabIndex={tabIndex}
            onChange={setTabIndex}
          >
            <LatestAlerts />
            <ActiveAlerts />
          </TabView>
        )}
      </View>
    </AlertsContext.Provider>
  );
};

export default memo(
  AlertsView,
  (prevProps, nextProps) => prevProps.contextValue === nextProps.contextValue
);
