import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, } from "react";
import { BottomSheetModal, BottomSheetView, useBottomSheetDynamicSnapPoints, } from "@gorhom/bottom-sheet";
import CustomBackdrop from "components/AlertsModal/CustomBackdrop";
import { useStyles } from "./styles";
import { BackHandler, useWindowDimensions, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

/**
 * A renderer component for a bottom sheet modal. It uses the BottomSheetModal from
 * gorhom/bottom-sheet and provides dynamic snap points based on the content's height.
 * It is designed to be used with a forwardRef to allow parent components to programmatically
 * open and close the bottom sheet.
 *
 * @component
 *
 * @param {React.Element} title - The title of the bottom sheet.
 * @param {React.Element} content - The content of the bottom sheet.
 * @param {Function} handleCloseModalPress - A callback function that is responsible for closing the bottom sheet.
 *  This function should be called from within the ModalRenderer component to properly manage the bottom sheet's visibility.
 * @param {boolean} useAnimatedSnapPoints - A flag indicating whether to enable animated transitions
 * for the bottom sheet's snap points. When `true`, the bottom sheet will smoothly animate between
 * different snap points, enhancing the user experience.
 * When `false`, a simple View component is rendered without the onLayout prop,
 * which may affect the scrolling behavior of the BottomSheetModal.
 * @param {boolean} useViewMode - A flag for markets view in ViewChart!
 * @returns {React.Element} The rendered bottom sheet modal component.
 */
const BottomSheetRenderer = forwardRef(
  ({ content, title, handleCloseModalPress, useAnimatedSnapPoints, useViewMode }, ref) => {
    const styles = useStyles();
    const bottomSheetRef = useRef(null);
    const { height } = useWindowDimensions();
    const insets = useSafeAreaInsets();
    const snapPoints = useMemo(() => [height - insets.top], []);
    const initialSnapPoints = useMemo(() => ["CONTENT_HEIGHT"], []);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

    const {
      animatedHandleHeight,
      animatedSnapPoints,
      animatedContentHeight,
      handleContentLayout,
    } = useBottomSheetDynamicSnapPoints(initialSnapPoints);

    const handleOpenBottomSheet = useCallback(() => {
      setIsBottomSheetOpen(true);
      bottomSheetRef.current?.present();
    }, []);

    const handleCloseBottomSheet = useCallback(() => {
      setIsBottomSheetOpen(false);
      bottomSheetRef.current?.close();
    }, []);

    useImperativeHandle(ref, () => ({
      handleOpenBottomSheet,
      handleCloseBottomSheet,
    }));

    useEffect(() => {
      const backAction = () => {
        if (isBottomSheetOpen) {
          handleCloseBottomSheet();
          return true; // Prevent default behavior (exit the app)
        }
        return false; // Default behavior (exit the app)
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

      return () => backHandler.remove(); // Clean up the event listener
    }, [isBottomSheetOpen, handleCloseBottomSheet]);

    return (
      !useViewMode ? (
        <BottomSheetModal
          ref={bottomSheetRef}
          index={0}
          enablePanDownToClose={true}
          style={styles.root}
          backgroundStyle={styles.background}
          handleStyle={styles.background}
          handleIndicatorStyle={styles.indicator}
          backdropComponent={CustomBackdrop}
          stackBehavior="push"
          onDismiss={handleCloseModalPress}
          {...(useAnimatedSnapPoints
            ? {
              snapPoints: animatedSnapPoints,
              handleHeight: animatedHandleHeight,
              contentHeight: animatedContentHeight
            }
            : {
              snapPoints: snapPoints
            })
          }
        >
          {useAnimatedSnapPoints ? (
            <BottomSheetView onLayout={handleContentLayout}>
              <View style={styles.width}>
                {title}
                {content}
              </View>
            </BottomSheetView>
          ) : (
            <>
              {title}
              {content}
            </>
          )}
        </BottomSheetModal>
      ) : (
        <View style={[{ width: "100%", flex: 1 }]}>
          {title}
          {content}
        </View>
      )

    );
  }
);

export default BottomSheetRenderer;
