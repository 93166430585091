import React, { lazy, Suspense } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const PaymentContainer = lazy(() => import("containers/Payment"));

const Payment = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <PaymentContainer />
    </Suspense>
  );
};

export default Payment;
