import React, { useState, useEffect, useContext, useMemo } from "react";
import useConfigurations from "hooks/useConfigurations";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { Image, useWindowDimensions } from "react-native";
import { useStyles } from "./styles";

const useViewStrategy = ({ strategyId }) => {
  const {
    strategies,
    strategyDescriptions,
    getAugmentStrategiesWithDescriptions,
  } =
    useContext(DataConfigsContext);
  const augmentStrategies = getAugmentStrategiesWithDescriptions(strategies, strategyDescriptions)
  const { markets, time_frame } = useConfigurations();
  const { width } = useWindowDimensions();
  const styles = useStyles(width);
  const [indicator, setIndicator] = useState(null);
  const [currentMarket, setCurrentMarket] = useState();
  const [currentStrategy, setCurrentStrategy] = useState(strategyId);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(null);

  useEffect(() => {
    setIndicator(augmentStrategies.find(strategy => strategy.strategy_id == strategyId));
  }, [strategyId, JSON.stringify(augmentStrategies)]);

  const handleChange = (type, item) => {
    if (type === "MARKET") setCurrentMarket(item.value);
    if (type === "STRATEGY") setCurrentStrategy(item.value);
    if (type === "TIME_FRAME") setCurrentTimeFrame(item.value);
  };

  const renderers = useMemo(() => ({
    img: (props) => {
      const { style, tnode, ...rest } = props;
      return (
        <Image
          source={{ uri: tnode.attributes?.src }}
          style={[{ width: "100%", height: "auto", borderRadius: 12 }, style]}
          resizeMode="contain"
          {...rest}
        />
      );
    },
    span: ({ TDefaultRenderer, ...props }) => (<TDefaultRenderer {...props} style={styles.text}/>),
  }), [indicator?.attributes?.content]); // Depend on styles here

  const tagsStyles = useMemo(
    () => ({
      strong: { ...styles.text },
      ul: { ...styles.text },
      null: { ...styles.text },
    }), [indicator?.attributes?.content])

  const renderHtmlProps = useMemo(() => {
    return ({
      source: { html: indicator?.attributes?.content },
      renderers,
      tagsStyles,
    })
  }, [indicator?.attributes?.content])

  return {
    indicator,
    markets,
    time_frame,
    currentMarket,
    currentStrategy,
    currentTimeFrame,
    handleChange,
    renderHtmlProps
  };
};

export default useViewStrategy;
