import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { ButtonGroup } from "@rneui/themed";
import useStyles from "./styles";

/**
 * Component for switch between 2 buttons.
 *
 * @component
 * @returns {React.JSX} - The rendered component.
 */
const SwitchButton = ({ names, onPress, selectedIndex }) => {
  const styles = useStyles();

  return (
    <View style={styles.flexRowCenter}>
      <ButtonGroup
        buttons={names}
        onPress={onPress}
        selectedIndex={selectedIndex}
        containerStyle={styles.containerStyle}
        textStyle={styles.textStyle}
        buttonContainerStyle={styles.buttonContainerStyle}
        selectedButtonStyle={styles.selectedButtonStyle}
        selectedTextStyle ={styles.selectedTextStyle }
      />
    </View>
  );
};

export default SwitchButton;

SwitchButton.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  selectedIndex: PropTypes.number,
  onPress: PropTypes.func,
};

SwitchButton.defaultProps = {
  names: [],
  selectedIndex: 0,
  onPress: () => {},
};
