import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

const useStyles = makeStyles((theme, width) => {
  return {
    container1: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: STYLES.MARGIN.SM,
      borderRadius: STYLES.BORDER_RADIUS.LG,
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    },
    container2: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      margin: STYLES.MARGIN.SX,
    },
    row: {
      marginHorizontal: 12,
    },
    iconStyle: {
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey4 : theme.colors.grey5,
      padding: 10,
      borderRadius: STYLES.BORDER_RADIUS.MD,
      color: STYLES.COLORS.ICON.MIDDLE_GRAY,
    },
    iconColor: {
      color: STYLES.COLORS.ICON.MIDDLE_GRAY,
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color:
        theme.mode === "dark"
          ? theme.colors.whiteText
          : STYLES.COLORS.TEXT.DARK_GRAY,
      textTransform: "capitalize",
      marginTop: 0,
    },
    subTitle: {
      fontSize: 12,
      color: STYLES.COLORS.TEXT.MIDDLE_GRAY,
    },
    iconBtnStyle: {
      backgroundColor: "transparent",
      marginRight: STYLES.MARGIN.SM,
      borderRadius: STYLES.BORDER_RADIUS.MD,
      color: STYLES.COLORS.ICON.MIDDLE_GRAY,
    },
  };
});

export default useStyles;
