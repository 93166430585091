import React, { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const SettingsContainer = lazy(() => import("containers/Settings"));

const Settings = ({ navigation }) => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <SettingsContainer navigation={navigation} />
    </Suspense>
  );
};

export default Settings;
