import { useMutation } from "react-query";
import { deleteUser } from "api/auth";
import { SCREENS_NAMES } from "constants/screens";
import Toast from "react-native-toast-message";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useUserContext } from "context/user/UserProvider";

const useDeleteAccount = () => {
  const navigation = useNavigation();
  const { signOut } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation(() => {
    return deleteUser();
  });

  const onSave = async () => {
    const result = await mutation.mutateAsync({
      onError: (e) => {
        throw new Error(e);
      },
    });

    if (result?.status === 200) {
      signOut();
      navigation.navigate(SCREENS_NAMES.MARKETS);
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully deleted user",
      });
    }
  };

  const deleteAccount = async () => {
    try {
      setIsLoading(true);
      await onSave();
    } catch (error) {
      Toast.show({
        text1: "Couldn't delete user",
        text2: error.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return {
    deleteAccount,
    isLoading,
  };
};

export default useDeleteAccount;
