import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "@rneui/themed";
import { StyleSheet } from "react-native";
import { useQueryClient } from "react-query";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { useForm } from "react-hook-form";

const useSearchAlerts = ({ filters }) => {
  const { theme } = useTheme();
  const { plans } = useContext(DataConfigsContext);
  const queryClient = useQueryClient();
  const userInfoQuery = queryClient.getQueryData("userInfo");
  const userInfoData = (userInfoQuery || {}).data;

  // Determining the user's plan
  const userPlan = useMemo(() => {
    if (
      !userInfoData?.plan ||
      userInfoData.plan.plan !== "ACTIVE" ||
      !Array.isArray(plans) ||
      !userInfoData.plan.plan_term
    ) {
      return null;
    }
    return plans.find((pl) => pl.id === userInfoData.plan.plan_id);
  }, [userInfoData?.plan, userInfoData?.plan?.plan_term, plans]);

  const {
    markets,
    time_frame,
    strategies,
  } = useContext(DataConfigsContext);

  const getMarketItems = useCallback((marketValues) => {
    return marketValues?.map((market) => ({
      label: market.market,
      value: market.market,
    }));
  }, []);
  const getStrategyItems = useCallback((strategyValues) => {
    return strategyValues.map((strategy) => ({
      label: strategy.name,
      value: strategy.strategy_id,
    }));
  }, []);
  const getTimeFrameItems = useCallback(
    (timeFrameValues) => {
      return timeFrameValues?.map((time) => ({
        label: time,
        value: time,
        ...{
          disabled: userPlan ? !userPlan?.time_frames?.includes(time) : false,
        },
      }));
    },
    [userPlan]
  );
  const normalizeToArray = (value) => (Array.isArray(value) ? value : value ? [value] : []);
  const initializeValue = (value) => filters.isMultiple ? normalizeToArray(value) : value ?? null;

  const [isMultiple, setIsMultiple] = useState(filters.isMultiple);
  const [openMarket, setOpenMarket] = useState(false);
  const [valueMarket, setValueMarket] = useState(initializeValue(filters.market));
  const [marketItems, setMarketItems] = useState(getMarketItems(markets));
  const [openStrategy, setOpenStrategy] = useState(false);
  const [valueStrategy, setValueStrategy] = useState(initializeValue(filters.strategyId));
  const [strategyItems, setStrategyItems] = useState(getStrategyItems(strategies));
  const [openTimeFrame, setOpenTimeFrame] = useState(false);
  const [valueTimeFrame, setValueTimeFrame] = useState(initializeValue(filters.timeFrame));
  const [timeFrameItems, setTimeFrameItems] = useState(getTimeFrameItems(time_frame));

  const { control, handleSubmit} = useForm();

  const handleOpenMarkets = (open) => {
    setOpenMarket(open);
    setOpenStrategy(false);
    setOpenTimeFrame(false);
  };
  const handleOpenStrategies = (open) => {
    setOpenStrategy(open);
    setOpenMarket(false);
    setOpenTimeFrame(false);
  };
  const handleOpenTimeFrames = (open) => {
    setOpenTimeFrame(open);
    setOpenMarket(false);
    setOpenStrategy(false);
  };

  const dropDownStyle = useMemo(() => {
    return {
      borderWidth: StyleSheet.hairlineWidth,
      marginBottom: 0,
      borderColor: theme.colors.greyOutline,
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    };
  }, []);

  const dropDownContainerStyle = useMemo(() => {
    return {
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
      borderWidth: 0,
      borderTopWidth: 0,
      marginBottom: 0,
      marginTop: 12,
      borderRadius: 12,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,

      elevation: 4,
    };
  }, [theme]);

  const itemAndLabelStyle = useMemo(() => {
    return {
      fontWeight: "semibold",
      color: theme.colors.whiteText,
    };
  }, [theme]);

  const handleChangeMultiple = useCallback(
    (isMultiple) => {
      setOpenStrategy(false);
      setOpenMarket(false);
      setOpenTimeFrame(false);
      if (!isMultiple) {
        setIsMultiple(isMultiple);
      }
      if (isMultiple) {
        setValueMarket([]);
        setValueStrategy([]);
        setValueTimeFrame([]);
        setIsMultiple(true);
      } else {
        setValueMarket(null);
        setValueStrategy(null);
        setValueTimeFrame(null);
      }
    },
    [
      setOpenStrategy,
      setOpenMarket,
      setOpenTimeFrame,
      markets,
      strategies,
      time_frame,
      setIsMultiple,
      setValueMarket,
      setValueStrategy,
      setValueTimeFrame,
    ]
  );

  useEffect(() => {
    setValueMarket(initializeValue(filters.market));
    setValueStrategy(initializeValue(filters.strategyId));
    setValueTimeFrame(initializeValue(filters.timeFrame));
    setIsMultiple(filters.isMultiple);
  }, [filters]);

  return {
    isMultiple,
    openMarket,
    openStrategy,
    openTimeFrame,
    marketItems,
    strategyItems,
    timeFrameItems,
    valueMarket,
    valueStrategy,
    valueTimeFrame,
    setValueMarket,
    setValueStrategy,
    setValueTimeFrame,
    setMarketItems,
    setStrategyItems,
    setTimeFrameItems,
    handleOpenMarkets,
    handleOpenStrategies,
    handleOpenTimeFrames,
    dropDownStyle,
    dropDownContainerStyle,
    itemAndLabelStyle,
    handleSubmit,
    control,
    handleChangeMultiple,
  };
};

export default useSearchAlerts;
