import React from "react";
import PropTypes from "prop-types";
import { Text } from "@rneui/themed";
import useStyles from "./styles";

const Title = ({ title }) => {
  const styles = useStyles();

  return (
    <Text h3 style={styles.title} numberOfLines={2}>
      {title}
    </Text>
  );
};

export default Title;

Title.propTypes = {
  title: PropTypes.string,
};

Title.defaultProps = {
  title: "",
};
