import Toast from "react-native-toast-message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSchema from "hooks/useSchema";
import { useState } from "react";
import Constants from "expo-constants";
import { useUserContext } from "context/user/UserProvider";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useMutation } from "react-query";
import { setHasSeenOnboarding } from "api/onBoarding";
import navigation from "containers/Navigation";
import { SCREENS_NAMES } from "constants/screens";

const useLogin = () => {
  const { params } = useRoute();
  const schema = useSchema();
  const { navigate, popToTop } = useNavigation();
  const { signIn } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = {
    username: schema.EMAIL(),
    password: schema.PASSWORD(),
  };
  const onBoardingMutation = useMutation({
    mutationFn: setHasSeenOnboarding,
  });
  const onSignInSuccess = async () => {
    if (params?.isFromOnBoarding) {
      await popToTop();
      await onBoardingMutation.mutateAsync();
    }
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object(validationSchema)),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        grant_type: "password",
        username: data.username,
        password: data.password,
        client_id: Constants.expoConfig.extra.env.KEYCLOAK_CLIENT_ID,
        scope: "openid email profile offline_access",
      };
      await signIn(payload);
      await onSignInSuccess();
    } catch (error) {
      //TODO: Should use better condition
      if (error.message === "Error: Account is not fully set up")
        navigate(SCREENS_NAMES.EMAIL_VERIFICATION, { email: data.username, from: SCREENS_NAMES.LOGIN });
      Toast.show({
        text1: "Couldn't Sign In",
        text2: error.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    control,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
  };
};

export default useLogin;

/*
  const onRegister1 = async () => {
    const payload = {
      email: "user24@gmail.com",
      password: "Welcome123",
    };

    try {
      const res = await registerUser({ payload });
      if (res.status === 200) {
        Toast.show({
          type: "success",
          position: "top",
          text1: "Successfully register",
        });
        navigation.navigate(SCREENS_NAMES.ACCOUNT);
      }
    } catch (error) {
      console.log("===error===", error);
    }
  };

*/
