import React, { useEffect } from "react";
import { ActivityIndicator, FlatList, View } from "react-native";
import PaymentItem from "../PaymentItem";
import usePayments from "./usePayments";
import { Card, Text, useTheme } from "@rneui/themed";
import { CARD_STYLES } from "constants/STYLES";
import SubscriptionModal from "components/SubscriptionModal";

const Payments = () => {
  const { theme } = useTheme();
  const { payments, isLoading, isSuccess, showBuySubscription, setShowBuySubscription } = usePayments();

  useEffect(() => {
    if (isSuccess && payments.length === 0) {
      setShowBuySubscription(true)
    }
  }, [isSuccess, payments.length]);

  const renderItem = ({ item }) => <PaymentItem payment={item} key={item.id}/>;

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      {isLoading && <ActivityIndicator animating style={{ marginVertical: 50 }}/>}
      {isSuccess && (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            paddingHorizontal: 12,
          }}
        >
          {payments.length === 0
            ? <Card
              containerStyle={{
                ...CARD_STYLES,
                backgroundColor: theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
              }}>
              <Text>As you have not subscribed to any plans, there is no payment history available.</Text>
              <SubscriptionModal
                showBuySubscription={showBuySubscription}
                setShowBuySubscription={setShowBuySubscription}
              />
            </Card>
            : <FlatList
              data={payments}
              keyExtractor={(payment) => payment.id}
              renderItem={renderItem}
            />
          }
        </View>
      )}
    </View>
  );
};

export default Payments;

Payments.propTypes = {};

Payments.defaultProps = {};
