import React, { useMemo, useState } from "react";
import { Platform, useWindowDimensions, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Button, Card, Skeleton, Text, useTheme } from "@rneui/themed";
import Icon from "components/UI/Icon";
import { SCREENS_NAMES } from "constants/screens";
import DescriptionsList from "components/DescriptionsList";
import { useUserContext } from "context/user/UserProvider";
import useStyles from "./styles";
import moment from "moment";
import PremiumIcon from "components/UI/PremiumIcon";
import ProIcon from "components/UI/ProIcon";
import BasicIcon from "components/UI/BasicIcon";

const PriceCurrency = ({ item }) => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const isSmallScreen = width < theme.breakpoints.laptop;
  const styles = useStyles({ type: item.type, isSmallScreen });
  return item.type === "Basic" ? (
    <View style={styles.subHeader}>
      <View style={{ ...styles.flexRowCenter }}>
        <Text style={{ ...styles.subHeaderText }}>{item.days}</Text>
        <Text style={{ ...styles.subHeaderSubText }}>{item.dayInfo}</Text>
      </View>
    </View>
  ) : (
    <View style={styles.subHeader}>
      <View style={{ ...styles.flexRowCenter }}>
        <Icon
          name={item.icon}
          type="material-community"
          iconStyle={{ ...styles.iconCurrencyStyle }}
          size={24}
          isClickable={false}
        />
        <Text style={{ ...styles.subHeaderText }}>{item.price}</Text>
        <Text style={{ ...styles.subHeaderSubText }}>{item.currency}</Text>
      </View>
      <Text style={{ ...styles.discriptionText }}>{item?.discount}</Text>
    </View>
  );
};

/**
 * PricingCard displays a card for a subscription plan with details and actions.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.item - The item object containing plan details.
 * @param {string} props.item.type - The type of the plan (Basic, Pro, Premium).
 * @param {Object} props.currentActiveSubscription - The current active subscription object.
 * @param {Function} props.changeTab - A function to change the tab.
 * @returns {ReactElement} - The PricingCard component.
 */
const PricingCard = ({
  item = {},
  currentActiveSubscription,
  changeTab,
  disableButtons,
  isPaymentsFetch,
}) => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const isSmallScreen = width < theme.breakpoints.laptop;
  const styles = useStyles({ type: item.type, isSmallScreen });
  const navigation = useNavigation();
  const { token } = useUserContext();

  const isActive = useMemo(() => {
    return (
      currentActiveSubscription?.plan?.id === item.plan_id &&
      currentActiveSubscription?.term === item.term
    );
  }, [
    item?.plan_id,
    item?.term,
    currentActiveSubscription?.term,
    currentActiveSubscription?.plan?.id,
  ]);

  const onPress = () => {
    if (token) {
      navigation.navigate(SCREENS_NAMES.PAYMENT, {
        item,
      });
    } else {
      Platform.OS === "web"
        ? navigation.navigate(SCREENS_NAMES.HOME, {
            screen: SCREENS_NAMES.ACCOUNT,
          })
        : changeTab(0);
    }
  };

  const iconMapping = {
    Basic: { component: BasicIcon, color: theme.colors.grey0 },
    Pro: { component: ProIcon, color: theme.colors.primary },
    Premium: { component: PremiumIcon, color: theme.colors.warning },
  };
  const { component: IconComponent, color } =
    iconMapping[item.type] || iconMapping.Basic;

  const [showItemDetails, setShowItemDetails] = useState({
    basic: false,
    pro: false,
    premium: false,
  });
  const showDetails = (type) => {
    setShowItemDetails((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  return (
    <Card
      containerStyle={[styles.container, styles.card]}
      wrapperStyle={[Platform.OS === "web" && styles.cardWrapper]}
    >
      <View style={styles.titleContainer}>
        <View style={styles.titleWrapper}>
          <IconComponent
            color={color}
            width={isSmallScreen ? 40 : 50}
            height={isSmallScreen ? 40 : 50}
            style={styles.itemIcon}
          />
          <Text style={styles.titleText}>{item.title.toUpperCase()}</Text>
        </View>
        {isSmallScreen && (
          <View style={styles.dropdownWrapper}>
            <PriceCurrency item={item} />
            <Icon
              name="chevron-down"
              type="material-community"
              color={theme.colors.grey0}
              iconStyle={styles.dropdownIcon}
              size={25}
              onPress={() => showDetails(item.type)}
            />
          </View>
        )}
      </View>
      <View
        style={[
          styles.viewMode,
          showItemDetails[item.type] && { display: "block" },
        ]}
      >
        <DescriptionsList
          descriptionsList={item.info}
          iconName="check-circle"
          listContainerStyle={styles.listContainerStyle}
          listContentContainerStyle={styles.listContentContainerStyle}
        />
        {!isSmallScreen && <PriceCurrency item={item} />}
        {isPaymentsFetch
          ? item.type !== "Basic" &&
            (!currentActiveSubscription ||
              currentActiveSubscription?.plan?.name === "Basic") && (
              <View style={styles.flexRowCenter}>
                <Button
                  type="solid"
                  color={color}
                  containerStyle={styles.buttonContainerStyle}
                  buttonStyle={styles.buttonStyle}
                  title={item.button.title}
                  titleStyle={styles.buttonTitleStyle}
                  loadingProps={{ animating: true }}
                  disabled={disableButtons}
                  onPress={onPress}
                />
              </View>
            )
          : item.type !== "Basic" && (
              <Skeleton
                animation="wave"
                style={styles.skeleton}
                width={200}
                height={40}
              />
            )}

        {isActive && (
          <View>
            <View style={styles.activeTextWrapper}>
              <Icon
                type="ant-design"
                name="star"
                size={16}
                color={
                  item.type === "Premium"
                    ? theme.colors.warning
                    : theme.colors.primary
                }
              />
              <Text style={styles.activeText}>Current Plan</Text>
            </View>
            <Text style={styles.timeText}>
              Ends in:{" "}
              {moment(currentActiveSubscription?.end_time).format(
                "MMMM Do YYYY"
              )}
            </Text>
          </View>
        )}
      </View>
    </Card>
  );
};

export default PricingCard;
