import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { resendEmail } from "api/auth";
import Toast from "react-native-toast-message";

const useEmailVerification = () => {
  const { params } = useRoute();
  const [isLoading, setIsLoading] = useState(false);

  const onClickResendEmail = async () => {
    try {
      setIsLoading(true);
      const payload = {
        email: params?.email,
      };
      await sendEmail(payload);
    } catch (error) {
      Toast.show({
        text1: "Error!",
        text2: error.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmail = async (payload) => {
    try {
      await resendEmail({ payload });
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully send email",
      });
    } catch (error) {
      Toast.show({
        text1: "Error!",
        text2: error.message,
        type: "error",
      });
    }
  };

  return {
    isLoading,
    onClickResendEmail
  };
};

export default useEmailVerification;
