import React from "react";
import { ScrollView, useWindowDimensions, View } from "react-native";
import AddAlert from "components/AddAlert";
import Title from "components/Title";
import useViewStrategy from "./useViewStrategy";
import { RenderHTML } from "react-native-render-html";
import { useStyles } from "./styles";
import { Text } from "@rneui/themed";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ViewStrategy = ({ strategyId }) => {
  const { width } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const styles = useStyles({ width, bottom: insets.bottom });
  const { indicator, markets, currentStrategy, handleChange, renderHtmlProps } =
    useViewStrategy({ strategyId });

  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.card}>
          <View style={styles.content}>
            <Title title={indicator?.name} />
            <RenderHTML contentWidth={width} {...renderHtmlProps} />
          </View>
          <View style={styles.form}>
            <Text
              h4
              style={styles.addAlertTitle}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              Create Alert With {indicator?.name}
            </Text>
            <AddAlert
              pickersToDisplay={["markets", "timeFrames"]}
              markets={markets}
              strategy={currentStrategy}
              onSelect={handleChange}
              showLabel
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default ViewStrategy;
