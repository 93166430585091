import { Platform, StyleSheet } from "react-native";
import React from "react";
import { BlurView } from "expo-blur";
import { useTheme } from "@rneui/themed";

const ScreenContainer = ({ children }) => {
  const { theme } = useTheme();

  if (Platform.OS === "web")
    return (
      <BlurView
        style={[
          {
            flex: 1,
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
          },
        ]}
        intensity={50}
        tint={theme.mode}
      >
        {children}
      </BlurView>
    )
  else
    return children
};

export default ScreenContainer;

const styles = StyleSheet.create({});
