import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Appearance, Platform, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { elementstheme } from "Themes/elementsTheme";
import { DefaultTheme, DarkTheme } from "@react-navigation/native";
import { createTheme, useTheme as useMainTheme } from "@rneui/themed";
import { setStatusBarBackgroundColor } from "expo-status-bar";

export const useTheme = (props = {}) => {
  const colorScheme = useColorScheme();
  const [mode, setMode] = useState(colorScheme);

  const theme = useMemo(
    () =>
      createTheme({
        ...elementstheme,
        mode: mode === "dark" ? "dark" : "light",
      }),
    [mode]
  );

  const NavigationTheme = useMemo(() => {
    return mode === "dark"
      ? {
          ...DarkTheme,
          colors: {
            ...DarkTheme.colors,
            primary: "rgb(255, 255, 255)",
            background:
              Platform.OS === "web" ? "rgba(0, 0, 0, 0)" : "rgb(0, 0, 0)",
            card: "rgb(0, 0, 0)",
          },
        }
      : {
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            primary: "rgb(0, 153, 173)",
            background:
              Platform.OS === "web" ? "rgba(0, 0, 0, 0)" : "rgb(230, 236, 242)",
            card: "rgb(230, 236, 242)",
            text: "rgb(93, 93, 100)",
          },
        };
  }, [mode]);

  useLayoutEffect(() => {
    getStorage();
  }, []);

  useEffect(() => {
    setStorage(mode);
  }, [mode]);

  const getStorage = async () => {
    try {
      const res = await AsyncStorage.getItem("colorScheme");
      setMode(res);
      if (Platform.OS === "web") {
        document.documentElement.setAttribute(
          "data-color-scheme",
          mode === "dark" ? "dark" : "light"
        );
      }
    } catch (error) {
      console.error("Error getting dark mode preference:", error);
      return false;
    }
  };

  const setStorage = async (value) => {
    try {
      await AsyncStorage.setItem("colorScheme", value);
    } catch (error) {
      console.error("Error saving dark mode preference:", error);
    }
  };

  return {
    colorScheme: mode,
    setMode,
    mode,
    theme,
    NavigationTheme,
    setStorage,
  };
};
