import * as React from "react";
import { Circle, G, Path, Svg } from "react-native-svg";
const NoWifiApp = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 550 550",
    }}
    viewBox="0 0 550 550"
    {...props}
  >
    <G
      style={{
        opacity: 0.19,
      }}
    >
      <Path
        d="M550 482.9c0 14.3-5.8 27.3-15.2 36.7-9.4 9.4-22.4 15.2-36.7 15.2H56c-28.7 0-52-23.3-52-52 0-14.4 5.8-27.4 15.2-36.8 9.4-9.4 22.4-15.2 36.8-15.2h25.9c14.3 0 27.4-5.8 36.8-15.2 9.4-9.4 15.2-22.4 15.2-36.8 0-28.7-23.3-52-52-52H52c-28.7 0-52-23.3-52-52 0-14.4 5.8-27.3 15.2-36.8 9.4-9.2 22.4-15 36.8-15h77.7c14.3 0 27.4-5.8 36.8-15.2 9.4-9.4 15.2-22.4 15.2-36.8 0-28.7-23.3-52-52-52H52C23.3 119 0 95.7 0 67c0-14.3 5.8-27.4 15.2-36.8C24.6 20.8 37.6 15 51.9 15h425.5c28.7 0 52 23.3 52 52 0 14.3-5.8 27.3-15.2 36.8-9.4 9.4-22.4 15.2-36.8 15.2h-56.8c-1.6 0-3.2.1-4.8.2l-35-74.3C373 28.2 353 21.1 336.2 29L190.5 97.7c-16.8 7.9-24 27.9-16 44.7l28 59.4c-9.3 4.5-17.3 8.9-21.3 12.2-13.1 10.8-23.4 14.1-23.4 31.4s13.6 73.6 12.2 90c-.8 8.8-7.7 70.8-13.7 125.1-54.7 6.2-90.9 16.9-90.9 29.1 0 12.2 33.4 22 84.6 28.3 32.9 4 73.1 6.4 116.5 6.4 6.9 0 13.6-.1 20.3-.2 101.5-1.8 180.8-16.6 180.8-34.6s-80.1-33-182.4-34.6c.1-4.9.2-8.3.5-9.3 1.8-5.8 17.7-24.9 19.1-26.6 8 16.5 27.9 23.6 44.5 15.7l83.8-39.5c6.8 20.7 26.3 35.7 49.4 35.7H498c28.7 0 52 23.3 52 52z"
        fill="#0a96a9"
      />
      <Circle cx={119.2} cy={172} r={39.7} fill="#0a96a9" />
      <Path
        d="M530.8 377.7c0 21.9-17.8 39.7-39.7 39.7-19 0-34.9-13.4-38.8-31.2l42.8-20.2c9.2-4.3 15.5-12.3 18-21.3 10.7 7.1 17.7 19.3 17.7 33z"
        fill="#0a96a9"
      />
    </G>
    <Path
      d="M467.6 489.5c0 18-79.3 32.8-180.8 34.6-.7-20.6-1.7-53.1-1.5-69.2 102.2 1.6 182.3 16.5 182.3 34.6zm-311.4-29.1c-2.4 21.6-4.6 42.1-6.2 57.4-51.2-6.3-84.6-16.6-84.6-28.3s36.1-22.8 90.8-29.1z"
      fill="#0a96a9"
    />
    <Path
      d="m285.2 525.7-.1-1.6c-.4-11.6-1.7-50.8-1.5-69.2v-1.7h1.7c91.4 1.5 184 14.4 184 36.2 0 21.9-91.8 34.6-182.4 36.2l-1.7.1zm1.7-69.1c-.1 18.1 1 53 1.5 65.8 48.6-.9 93.5-4.9 126.6-11.1 31.9-6.1 51-14.2 51-21.8-.1-14.4-68.5-31-179.1-32.9zm-135.5 63.1-1.7-.2c-32.1-3.9-86.1-13.1-86.1-30 0-6.7 8.8-12.9 26.1-18.3 15.9-5 38.8-9.3 66.3-12.4l2.1-.2-.2 2.1c-2.2 20.4-4.6 41.5-6.2 57.4l-.3 1.6zm2.9-57.4c-26.3 3.1-48.3 7.2-63.6 12-20.6 6.5-23.7 12.3-23.7 15.2 0 7.9 21.5 18.9 81.5 26.5 1.6-15.1 3.7-34.6 5.8-53.7zM495.1 366l-145.7 68.7c-16.7 7.9-36.5.8-44.5-15.7l.1-.1-77.2-164.7s5.3-3.5 12.2-9.6l67.8 143.8c5.6 11.9 19.8 17 31.6 11.4L474.5 336c11.9-5.6 17-19.8 11.4-31.6L368.5 55.5c-5.6-11.9-19.8-17-31.6-11.4l-135.2 63.7c-11.9 5.6-17 19.8-11.4 31.6l26.3 55.7c-4.9 2.2-9.7 4.4-14.2 6.6l-28-59.4c-7.9-16.8-.7-36.8 16-44.7L336.2 29c16.8-7.9 36.8-.7 44.7 16l130.3 276.3c7.9 16.8.7 36.8-16.1 44.7z"
      fill="#0a96a9"
    />
    <Path
      d="M335.2 439.5c-13.1 0-25.7-7.3-31.8-19.8l-.4-.9.1-.1-77.4-165.1 1.2-.8s5.3-3.5 12-9.5l1.6-1.5 68.7 145.8c2.5 5.3 7 9.4 12.5 11.4 5.6 2 11.6 1.7 16.9-.8l135.2-63.7c11-5.2 15.8-18.4 10.6-29.4L367 56.2c-5.2-11-18.4-15.8-29.4-10.6l-135.2 63.7c-11 5.2-15.8 18.4-10.6 29.4l27 57.2-1.5.7c-5.1 2.3-9.9 4.5-14.1 6.5l-1.5.7-28.8-60.8c-4-8.5-4.5-18.1-1.3-26.9 3.2-8.9 9.6-15.9 18.1-20l145.7-68.7c17.6-8.3 38.6-.7 46.9 16.8l130.3 276.3c8.3 17.6.7 38.6-16.8 46.9l-145.7 68.7c-4.8 2.4-9.9 3.4-14.9 3.4zm-28.4-20.3c7.9 15 26.4 21.3 41.9 14l145.7-68.7c15.9-7.5 22.8-26.6 15.3-42.5L379.4 45.7c-7.5-15.9-26.6-22.8-42.5-15.3L191.2 99.2c-7.7 3.6-13.5 10.1-16.4 18.1-2.9 8-2.5 16.7 1.2 24.4l27.3 57.9c3.5-1.7 7.2-3.4 11.2-5.2L189 140.2c-6-12.7-.5-27.9 12.2-33.8l135.2-63.7c12.7-6 27.9-.5 33.8 12.2l117.3 248.8c6 12.7.5 27.9-12.1 33.8L340 401.2c-6.1 2.9-13 3.2-19.4.9-6.4-2.3-11.5-6.9-14.4-13.1l-66.9-141.8c-4.3 3.7-7.8 6.3-9.5 7.5l77 164.5zM495.1 366z"
      fill="#2b2b2b"
    />
    <Path
      d="m474.5 336-135.2 63.7c-11.9 5.6-26 .5-31.6-11.4l-67.8-143.8c7.8-7 17.6-17.4 23.8-30.1 11.7-23.9 0-38-7-35.6-3.8 1.3-22.1 8.4-40 16.4l-26.3-55.7c-5.6-11.9-.5-26 11.4-31.6L337 44.2c11.9-5.6 26-.5 31.6 11.4l117.3 248.8c5.6 11.8.5 26-11.4 31.6z"
      style={{
        opacity: 0.25,
        fill: "#87ddfd",
        enableBackground: "new",
      }}
    />
    <Circle
      cx={415.3}
      cy={385.7}
      r={10.8}
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="m294.7 247.3 90.7-42.7c1.8-.8 3.9-.1 4.8 1.7l8.1 17.3c.8 1.8.1 3.9-1.7 4.8L305.9 271c-1.8.8-3.9.1-4.8-1.7L293 252c-.9-1.7-.1-3.9 1.7-4.7z"
      style={{
        opacity: 0.75,
        fill: "#e86b18",
        enableBackground: "new",
      }}
    />
    <Path
      d="M304.4 273c-2 0-3.9-1.1-4.7-3l-8.1-17.3c-1.2-2.6-.1-5.7 2.5-7l90.7-42.7c1.3-.6 2.7-.7 4-.2 1.3.5 2.4 1.4 3 2.7l8.1 17.3c.6 1.3.7 2.7.2 4-.5 1.3-1.4 2.4-2.7 3l-90.7 42.7c-.8.4-1.6.5-2.3.5zm82.5-67.2c-.3 0-.6.1-.8.2l-90.7 42.7c-1 .5-1.4 1.6-.9 2.6l8.1 17.3c.5 1 1.6 1.4 2.6.9l90.7-42.7c.5-.2.8-.6 1-1.1.2-.5.1-1-.1-1.5l-8.1-17.3c-.2-.5-.6-.8-1.1-1-.3 0-.5-.1-.7-.1zm-144.2-53.7c-.2 0-.4 0-.6-.1-.9-.3-1.3-1.3-1-2.1 7.3-20.3 22-36.5 41.5-45.6 19.5-9.2 41.3-10.2 61.6-2.9.9.3 1.3 1.3 1 2.1-.3.9-1.3 1.3-2.1 1-19.4-7-40.4-6-59.1 2.8s-32.8 24.3-39.8 43.8c-.2.6-.8 1-1.5 1z"
      style={{
        fill: "#e5640c",
      }}
    />
    <Path
      d="M265.8 158.5c-.2 0-.4 0-.6-.1-.9-.3-1.3-1.3-1-2.1 5-13.8 15-24.9 28.3-31.1 13.3-6.3 28.2-7 42-2 .9.3 1.3 1.3 1 2.1-.3.9-1.3 1.3-2.1 1-13-4.7-27-4-39.5 1.9s-21.9 16.3-26.6 29.3c-.2.6-.9 1-1.5 1zm23.6 6.4c-.2 0-.4 0-.6-.1-.9-.3-1.3-1.3-1-2.1 2.6-7.2 7.8-12.9 14.7-16.1 6.9-3.2 14.6-3.6 21.8-1 .9.3 1.3 1.3 1 2.1-.3.9-1.3 1.3-2.1 1-6.3-2.3-13.2-1.9-19.3.9-6.1 2.9-10.7 7.9-13 14.3-.1.5-.8 1-1.5 1z"
      style={{
        fill: "#d16013",
      }}
    />
    <Path
      d="m335.7 182.2-1.5 4c-.5 1.3-2 2-3.3 1.6l-10.6-3.6-4 10.4c-.5 1.3-2 2-3.3 1.6l-4-1.4c-1.3-.5-2-1.9-1.5-3.2l4-10.4-10.6-3.6c-1.3-.5-2-1.9-1.5-3.2l1.5-4c.5-1.3 2-2 3.3-1.6l10.6 3.6 4-10.4c.5-1.3 2-2 3.3-1.6l4 1.4c1.3.5 2 1.9 1.5 3.2l-4 10.4 10.6 3.6c1.4.4 2 1.9 1.5 3.2z"
      style={{
        opacity: 0.75,
        fill: "#e07832",
        enableBackground: "new",
      }}
    />
    <Path
      d="M314.2 370.3c-.6 0-1.2-.3-1.5-.9-.4-.8-.1-1.8.7-2.2l153.4-77.5c.8-.4 1.8-.1 2.2.7.4.8.1 1.8-.7 2.2l-153.4 77.5c-.2.2-.5.2-.7.2z"
      fill="#2b2b2b"
    />
    <Path
      d="M432.9 155.3c-.1 0-.1 0 0 0-.1 0-.1 0 0 0zM432.9 155.3c-.1 0-.1 0 0 0-.1 0-.1 0 0 0zM449.1 189.8zM449.1 189.7c0 .1 0 .1 0 0 0 .1 0 .1 0 0z"
      fill="#87ddfd"
    />
    <Path
      d="M266.5 525.9c-42.1 0-82.5-2.2-116.7-6.4l-1.6-.2.2-1.6c1.7-15.8 4-36.9 6.2-57.4 1.6-14.6 3.3-29.8 4.9-44.4 4.4-39.8 8.2-74.2 8.8-80.7.7-8.7-2.9-29.1-6.4-48.9-3-16.9-5.8-32.9-5.8-41 0-14.7 7.3-20.1 16.6-27 2.4-1.7 4.8-3.6 7.4-5.7 13.5-11.1 70.1-33.5 76-35.5 3-1 6.7.3 9.4 3.5 5.1 5.9 7.7 18-.4 34.5-10.4 21.3-30.3 36.1-35.4 39.6l77.1 164.4-.7.8c-4.8 5.7-17.5 21.4-18.9 26.2-.1.3-.4 1.7-.4 8.8-.2 18.3 1.1 57.5 1.5 69.1l.1 1.7h-1.7c-6.8.1-13.6.2-20.2.2zm-114.7-9.5c33.7 4.1 73.3 6.2 114.7 6.2 6.1 0 12.3 0 18.6-.1-.5-13.3-1.7-50-1.5-67.6.1-7.2.4-9 .6-9.8 1.8-5.7 15.3-22.3 18.9-26.5l-77.4-165 1.2-.8c.2-.2 24-15.9 35.4-39.1 7.4-15 5.1-26 .9-30.8-1.9-2.1-4.1-3.1-5.9-2.5-8.3 2.8-62.3 24.4-75 34.9-2.7 2.2-5.1 4-7.5 5.8-9.2 6.8-15.3 11.3-15.3 24.4 0 7.8 2.8 23.7 5.8 40.4 3.5 20 7.2 40.6 6.4 49.7-.6 6.5-4.4 40.9-8.8 80.7-1.6 14.6-3.3 29.7-4.9 44.4-2.3 19.7-4.6 40-6.2 55.7z"
      fill="#2b2b2b"
    />
    <Path
      d="M228.9 376.4h-.4c-.9-.2-1.4-1.1-1.2-2 9.1-38.6 8.6-79.3-1.5-117.7-.2-.9.3-1.8 1.2-2 .9-.2 1.8.3 2 1.2 10.2 38.9 10.7 80.1 1.5 119.3-.2.7-.9 1.2-1.6 1.2zm7.2 71.1h-.2c-12-1.7-24-4.4-35.6-8-.9-.3-1.4-1.2-1.1-2.1s1.2-1.4 2.1-1.1c11.4 3.6 23.2 6.2 35 7.9.9.1 1.5 1 1.4 1.9-.1.9-.8 1.5-1.6 1.4zm36.3 2.9c-9.3 0-18.6-.6-27.7-1.9-.9-.1-1.5-1-1.4-1.9.1-.9 1-1.5 1.9-1.4 12.5 1.7 25.1 2.2 37.7 1.6.9-.1 1.7.7 1.7 1.6 0 .9-.7 1.7-1.6 1.7-3.5.2-7 .3-10.6.3z"
      fill="#2b2b2b"
    />
    <Path
      d="M254.4 477.8c-.4 0-.7-.1-1-.4-.7-.6-.8-1.6-.2-2.3 8.4-10.2 17.8-19.5 28.1-27.7.7-.6 1.8-.4 2.3.3.6.7.4 1.8-.3 2.3-10.1 8-19.4 17.2-27.6 27.2-.3.4-.8.6-1.3.6zM430.9 155l-16.7-35.5 1.8-.6c3.9-1.3 17.2-4.4 23.6 7.1 7.1 12.9-5.6 25.9-7 27.3l-1.7 1.7zm-12.1-33.5 13 27.7c3.2-3.8 9.6-13 4.9-21.6-4.4-8-13-7.3-17.9-6.1z"
      fill="#2b2b2b"
    />
    <Path
      d="m445.6 186.4-.9-2-15.6-33.1 1.7-.6c.1 0 .3-.1.5-.3 18.9-9.5 27.5-1.4 30.9 4.3 4.1 6.9 5 17.6-14.2 29.8-.3.2-.7.5-.8.5l-1.6 1.4zm-12-33.4 13.3 28.3c13.2-8.5 17.4-16.9 12.6-24.9-6.1-10.1-17.3-7.6-25.9-3.4z"
      fill="#2b2b2b"
    />
    <Path
      d="m475.8 250.4-15.2-32.2-5 1.7 4-3.8-15.6-33.2 1.5-.7c6.3-2.8 18.7-4.4 24.6 7.4 4.8 9.7.3 18.5-3.5 23.6 5.7-.8 14-.2 18.7 8.2 7.1 12.9-5.6 26-7.1 27.4l-.2.2-.2.1-2 1.3zm-12-33.2 13.2 28.1c3-3.4 10.4-13.2 5.4-22.2-4.7-8.6-14.3-7.1-18.6-5.9zm-15.3-32.6 13.6 28.9c3.3-3.6 10-12.6 5.1-22.4-4.9-9.9-14.8-7.8-18.7-6.5z"
      fill="#2b2b2b"
    />
    <Path d="M-102 15.1h82.4v82.4H-102z" fill="#0a96a9" />
  </Svg>
);
export default NoWifiApp;
