import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.colors.white,
    borderRadius: STYLES.BORDER_RADIUS.CARD,
    maxWidth: 450,
    maxHeight: 550,
    alignItems: "center",
  },
  titleText: {
    color: theme.colors.black,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center"
  },
  width: {
    maxWidth: 350
  }
}));
