import { lightColors } from "@rneui/themed";

export const STYLES = {
  PADDING: {
    SX: 8,
    SM: 16,
    MD: 24,
    LG: 32,
  },
  MARGIN: {
    SX: 8,
    SM: 16,
    MD: 24,
    LG: 32,
    MARGIN_BOTTOM_FOR_TAB_SCREEN: 100,
  },
  BORDER_RADIUS: {
    SM: 2,
    MD: 5,
    LG: 10,
    ICON: 5,
    BUTTON: 10,
    CARD: 12,
    OVAL: 30,
  },
  ICON_SIZE: {
    LG: 60,
    X_LG: 60,
    XX_LG: 60,
    SMALL: 8,
  },
  COLORS: {
    TEXT: {
      DARK_GRAY: lightColors.grey1,
      MIDDLE_GRAY: lightColors.grey3,
      MIDDLE_GRAY_2: lightColors.grey4,
      LIGHT_GRAY: lightColors.grey5,
    },
    ICON: {
      DARK_GRAY: lightColors.grey1,
      MIDDLE_GRAY: lightColors.grey3,
      MIDDLE_GRAY_2: lightColors.grey4,
      LIGHT_GRAY: lightColors.grey5,
    },
  },
  BOTTOM_SHEET: {
    MAX_WIDTH: 350
  }
};

export const FLEX_POSITIONS = {
  // ROW
  ROW_CENTER: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  ROW_START: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  ROW_SPACE_BETWEEN: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ROW_END: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  // COLUMN
  COLUMN_CENTER: {
    alignItems: "center",
    justifyContent: "center",
  },
};

export const CARD_STYLES = {
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowColor: "#000",
  shadowOpacity: 0,
  shadowRadius: 0,
  elevation: 0,
  borderRadius: 12,
  borderWidth: 0,
  // borderBottomWidth: StyleSheet.hairlineWidth,
  borderColor: lightColors.greyOutline,
};

export const INPUT_STYLES = {
  borderWidth: 1,
  borderColor: lightColors.grey4,
  borderStyle: "solid",
  borderRadius: 10,
  marginVertical: 5,
  paddingHorizontal: 12,
};
