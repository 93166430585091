import { TouchableOpacity, View } from "react-native";
import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "@rneui/themed";

const StrategySlider = ({
                          strategies,
                          currentStrategy,
                          theme,
                          handleChange,
                        }) => {
  const selectedStrategyColor = theme.colors.primary;
  const strategyColor = theme.colors.grey5;
  const selectedStrategyFontColor = theme.colors.white;
  const strategyFontColor = theme.colors.black;
  return (
    <ScrollView
      style={{ flex: 1 }}
      horizontal
    >
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {strategies.map((st) => (
          <TouchableOpacity
            key={st.id}
            onPress={() => handleChange("STRATEGY", { value: st.id })}
            activeOpacity={0.6}
            style={{
              backgroundColor: currentStrategy.includes(st.id)
                ? selectedStrategyColor
                : strategyColor,
              marginHorizontal: 6,
              height: 40,
              padding: 8,
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: currentStrategy.includes(st.id)
                  ? selectedStrategyFontColor
                  : strategyFontColor,
              }}
              adjustsFontSizeToFit
            >
              {st.name}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  );
};

export default StrategySlider;
