import server from "./server";

const snapshotsBaseUrl = "/pychart/web-api/tickers";

export const getSnapshotsHistory = async () => {
  try {
    const url = `${snapshotsBaseUrl}/history`;
    const response = await server.get(url);
    const snapshots = response?.data?.snapshots ?? [];
    const indicators = response?.data?.indicators ?? [];
    return snapshots.map(snapshot => ({
      ...snapshot,
      ...indicators.find(indicator => indicator.s === snapshot.s)
    }));
  } catch (e) {
    console.log("=====error setBasicPlan", e);
  }
};
