import { makeStyles } from "@rneui/themed";

const useStyles = makeStyles((theme, alert = {}) => ({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 0,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subline: {
    fontSize: 12,
  },
  badge: {
    borderRadius: 100,
    width: 10,
    height: 10,
    marginVertical: 5,
  },
  header: {
    // textAlign: "center",
  },
  card: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,

    elevation: 0,
    borderWidth: 0,
    borderBottomWidth: 0,
    borderColor: theme.colors.greyOutline,

    borderRadius: 12,
    margin: 0,
    backgroundColor: "transparent",
  },

  mr: {
    marginRight: 5,
  },
  iconStyle: {
    alignItems: "center",
  },
  text: {
    color: theme.colors.whiteText,
  },
}));

export default useStyles;
