import React, { memo } from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  useWindowDimensions,
  View,
  Text,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import PropTypes from "prop-types";
import { useStyles } from "./AddAlertStyles";
import useAddAlert from "./hooks/useAddAlert";
import { Button, Icon, Skeleton, useTheme } from "@rneui/themed";
import { Controller } from "react-hook-form";
import SubscriptionModal from "components/SubscriptionModal";
import Checkbox from "expo-checkbox";

const pickers = ["markets", "strategies", "timeFrames"];

const Label = (props) => {
  const { theme } = useTheme();
  const { text } = props;
  return (
    <Text
      style={{
        fontSize: 20,
        fontWeight: "500",
        marginBottom: 5,
        color: theme.colors.whiteText,
      }}
    >
      {text}
    </Text>
  );
};

const AddAlert = ({
  market,
  strategy,
  timeFrame,
  onSelect = () => {},
  pickersToDisplay = pickers,
  displayAddButton = true,
  pickerProps = {},
  customStyles = {},
  showLabel = false,
  onCancel = () => {},
  showCancelButton,
}) => {
  const { theme } = useTheme();
  const dimensions = useWindowDimensions();
  const styles = useStyles(dimensions.width);

  const {
    configurationsIsLoading,
    isMultiple,
    setIsMultiple,
    handleChangeMultiple,
    openMarket,
    openStrategy,
    openTimeFrame,
    marketItems,
    strategyItems,
    timeFrameItems,
    valueMarket,
    valueStrategy,
    valueTimeFrame,
    addAlertLoading,
    setValueMarket,
    setValueStrategy,
    setValueTimeFrame,
    setMarketItems,
    setStrategyItems,
    setTimeFrameItems,
    handleSelectItem,
    AddAlert,
    handleOpenMarkets,
    handleOpenStrategies,
    handleOpenTimeFrames,
    dropDownStyle,
    dropDownContainerStyle,
    itemAndLabelStyle,
    handleSubmit,
    control,
    errors,
    showBuySubscription,
    setShowBuySubscription,
    activeAlertsLength,
    isLoadingActiveAlerts,
    userPlan,
    multipleAlerts
  } = useAddAlert({
    market,
    strategy,
    timeFrame,
    onSelect,
    onCancel,
    pickersToDisplay,
  });

  const dropDownProps = {
    autoScroll: true,
    dropDownDirection: pickerProps.dropDownDirection,
    listMode: pickerProps.listMode || "FLATLIST",
    multiple: pickerProps.multiSelect,
    theme: theme.mode.toUpperCase(),

    containerStyle: pickerProps.containerStyle,
    disableBorderRadius: false,
    dropDownContainerStyle: [
      dropDownContainerStyle,
      pickerProps.dropDownContainerStyle,
    ],
    labelProps: pickerProps.labelProps,
    labelStyle: [itemAndLabelStyle, pickerProps.labelStyle],
    selectedItemLabelStyle: [
      itemAndLabelStyle,
      pickerProps.selectedItemLabelStyle,
    ],
    showArrowIcon: pickerProps.showArrowIcon,
    style: [dropDownStyle, pickerProps.style],
    textStyle: pickerProps.textStyle,
  };

  if (configurationsIsLoading) return <ActivityIndicator animating/>;
  return (
    <View style={[styles.selectContainer, customStyles.selectContainer]}>
      <View
        style={[
          {
            flex: 1,
            marginVertical: 12,
            minHeight: 120,
          },
          styles.dropDownWrapper,
        ]}
      >
        <View
          style={{
            flex: 1,
            alignItems: "baseline",
            minHeight: 50,
          }}
        >
          <Text style={{color: theme.colors.grey2}}>
            You have
            <Text style={{ fontWeight: "bold", marginHorizontal: 3 }}>
              {` ${isLoadingActiveAlerts ? <Skeleton width={20} animation="wave"/> : activeAlertsLength} `}
            </Text>active alerts
          </Text>
          {!isLoadingActiveAlerts && userPlan && userPlan?.signal_quota !== -1 && (
            <Text style={{color: theme.colors.grey2}}>
              You can add
              <Text style={{ fontWeight: "bold", marginHorizontal: 3 }}>
                {` ${userPlan?.signal_quota - activeAlertsLength} `}
              </Text>more alerts.
            </Text>
          )}
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            minHeight: 30,
          }}
        >
          <Checkbox
            value={isMultiple}
            onValueChange={handleChangeMultiple}
            color={
              theme.mode === "light" ? theme.colors.primary : theme.colors.grey2
            }
          />
          <Text
            style={{
              fontWeight: "bold",
              marginLeft: 6,
              color: theme.colors.primary,
            }}
          >
            Multiple Selection
          </Text>
        </View>
        <View style={{ flex: 1, minHeight: 70 }}>
          <Text style={{ color: theme.colors.grey2 }}>
            When this option is enabled, you can easily mix markets, indicators
            and time frames to make alerts in an instant click.
          </Text>
        </View>
      </View>
      {pickersToDisplay.includes("markets") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.marketDropDown,
            openMarket && { zIndex: 3 },
          ]}
        >
          {showLabel && <Label text="Market"/>}
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={marketItems}
                open={openMarket}
                setItems={setMarketItems}
                setOpen={handleOpenMarkets}
                setValue={setValueMarket}
                value={valueMarket}
                onChangeValue={onChange}
                onSelectItem={(item) => handleSelectItem("MARKET", item)}
              />
            )}
            name={"MARKET"}
          />
          {errors["MARKET"] && (
            <Text style={styles.errorText}>{errors["MARKET"]?.message}</Text>
          )}
        </View>
      )}
      {pickersToDisplay.includes("strategies") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.strategyDropDown,
            openStrategy && { zIndex: 3 },
          ]}
        >
          {showLabel && <Label text="Indicator"/>}

          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={strategyItems}
                open={openStrategy}
                setItems={setStrategyItems}
                setOpen={handleOpenStrategies}
                setValue={setValueStrategy}
                value={valueStrategy}
                onChangeValue={onChange}
                onSelectItem={(item) => handleSelectItem("STRATEGY", item)}
              />
            )}
            name={"STRATEGY"}
          />
          {errors["STRATEGY"] && (
            <Text style={styles.errorText}>{errors["STRATEGY"]?.message}</Text>
          )}
        </View>
      )}
      {pickersToDisplay.includes("timeFrames") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.timeFrameDropDown,
            openTimeFrame && { zIndex: 3 },
          ]}
        >
          {showLabel && <Label text="Time Frame"/>}
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={timeFrameItems}
                open={openTimeFrame}
                setItems={setTimeFrameItems}
                setOpen={handleOpenTimeFrames}
                setValue={setValueTimeFrame}
                value={valueTimeFrame}
                onChangeValue={onChange}
                disabledItemLabelStyle={{
                  opacity: 0.5,
                }}
                onSelectItem={(item) => handleSelectItem("TIME_FRAME", item)}
                dropDownDirection="TOP"
              />
            )}
            name={"TIME_FRAME"}
          />
          {errors["TIME_FRAME"] && (
            <Text style={styles.errorText}>
              {errors["TIME_FRAME"]?.message}
            </Text>
          )}
        </View>
      )}

      {userPlan && userPlan?.signal_quota !== -1 && isMultiple && multipleAlerts.length > userPlan?.signal_quota - activeAlertsLength && (
        <View style={{ marginBottom: 15 }}>
          <Text style={{color: theme.colors.grey2}}>You have chosen
            <Text
              style={{ fontWeight: "bold"}}>
              {` ${multipleAlerts.length} `}
            </Text>
            alerts.
          </Text>
          <Text style={{ color: theme.colors.error }}>Your request exceeds your subscription limit by
            <Text
              style={{ fontWeight: "bold" }}>
              {` ${multipleAlerts.length - (userPlan?.signal_quota - activeAlertsLength)} `}
            </Text>
            alerts.
          </Text>
        </View>
      )}
      {(
        (userPlan && userPlan?.signal_quota - activeAlertsLength === 0) ||
        (userPlan && userPlan?.signal_quota !== -1 && isMultiple && multipleAlerts.length > userPlan?.signal_quota - activeAlertsLength)
      ) && (
        <View style={{ marginBottom: 15 }}>
          <Text style={{color: theme.colors.grey2}}>You can
            <Text
              style={{ color: theme.colors.primary }}
              onPress={() => setShowBuySubscription(true)}> Upgrade </Text>
            your plan subscription
          </Text>
        </View>
      )}

      {displayAddButton && (
        <View style={[styles.addAlertBtn, customStyles.addAlertWrapper]}>
          {showCancelButton && (
            <Button
              type="clear"
              title="Cancel"
              titleStyle={[
                styles.addAlertActionBtnTitleStyle,
                {
                  color: theme.colors.error,
                },
              ]}
              onPress={onCancel}
            />
          )}
          <Button
            title="Create"
            disabled={
              addAlertLoading ||
              userPlan?.signal_quota - activeAlertsLength === 0 ||
              (userPlan && userPlan?.signal_quota !== -1 && isMultiple && multipleAlerts.length > userPlan?.signal_quota - activeAlertsLength)
            }
            icon={
              addAlertLoading ? (
                <ActivityIndicator size={"small"} color={theme.colors.white}/>
              ) : (
                <Icon
                  type="material-community"
                  name="bell-plus-outline"
                  size={20}
                  color={theme.colors.white}
                />
              )
            }
            containerStyle={[
              styles.addAlertActionBtn,
              customStyles.addAlertActionBtn,
            ]}
            titleStyle={styles.addAlertActionBtnTitleStyle}
            onPress={handleSubmit(AddAlert)}
          />
        </View>
      )}
      <SubscriptionModal
        showBuySubscription={showBuySubscription}
        setShowBuySubscription={setShowBuySubscription}
      />
    </View>
  );
};

export default memo(
  AddAlert,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);

AddAlert.propTypes = {
  market: PropTypes.number,
  strategy: PropTypes.string,
  timeFrame: PropTypes.string,
  onSelect: PropTypes.func,
};
