import { useChart } from "hooks/useChart";
import { ChartLoaderContext } from "./ChartLoaderContext";
import { useMemo } from "react";
import { Platform } from "react-native";

export const ChartLoaderProvider = ({ children }) => {
  const { stateType, baseChartUrl, isSplashScreenHidden } = useChart();

  const contextValue = useMemo(() => {
    return { stateType, baseChartUrl, isSplashScreenHidden };
  }, [stateType, baseChartUrl, isSplashScreenHidden]);

  return (
    <ChartLoaderContext.Provider value={contextValue}>
      {children}
    </ChartLoaderContext.Provider>
  );
};
