import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
const Email = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 550 550",
    }}
    viewBox="0 0 550 550"
    {...props}
  >
    <Path
      d="M286 499.6c-102.7-44-150.8 22.8-200.8-26.4-50.6-49.8-15-113.2-36-187.7C-33-5.4 203.2 124.3 244.6 95.8 410.9-18.5 539 55.7 515.2 155.7c-9.9 41.6-42.3 91.7-21.2 144.7 47 118.6-81 253.5-208 199.2z"
      style={{
        opacity: 0.19,
        fill: "#0a96a9",
        enableBackground: "new",
      }}
    />
    <Path
      d="M164.1 426.4c-1.2 0-2.1-.9-2.2-2.1l-2.1-149.2c0-1.2.9-2.1 2.1-2.2l245.1-3.8c1.2 0 2.1.9 2.2 2.1l2.3 149.2c0 1.2-.9 2.1-2.1 2.2l-245.3 3.8z"
      style={{
        fill: "#6be9f2",
      }}
    />
    <Path
      d="m406.8 271.2 2.3 149.2-245.1 3.9-2.3-149.2 245.1-3.9m0-4.1-245.1 3.8c-2.4 0-4.1 1.9-4.2 4.3l2.3 149.2c0 2.4 1.9 4.1 4.3 4.2l245.1-3.8c2.4 0 4.1-1.9 4.2-4.3l-2.3-149.2c-.1-2.4-2.1-4.3-4.3-4.2z"
      fill="#0D3747"
    />
    <Path
      d="M161.8 277.2c-.9 0-1.8-.5-2.1-1.5-.4-.8-.1-1.8.7-2.3l121.1-89.3c.4-.3.8-.4 1.2-.4.5 0 .9.1 1.2.4l123.9 85.4c.7.5 1.1 1.5.8 2.3-.2.8-1 1.5-1.9 1.5l-244.9 3.9z"
      style={{
        fill: "#80e6ed",
      }}
    />
    <Path
      d="m283 185.8 123.9 85.4-245.1 3.8L283 185.8m-.1-4.2c-.9 0-1.7.2-2.5.8l-121.1 89.3c-1.5 1.1-2.1 3-1.5 4.8.5 1.8 2.3 2.9 4.1 2.8l245.1-3.8c1.8-.1 3.5-1.2 4-3s-.1-3.6-1.6-4.6l-123.9-85.4c-.9-.7-1.7-.9-2.6-.9z"
      fill="#0D3747"
    />
    <Path
      d="M189.7 386.6c-1.2 0-2.1-.9-2.2-2.1l-4-251.2c0-1.2.9-2.1 2.1-2.2l155.7-2.5c.6 0 1.1.2 1.5.6l37.6 36.4c.3.4.6.9.6 1.5l3.4 214.2c0 1.2-.9 2.1-2.1 2.2l-192.6 3.1z"
      fill="#FFFFFF"
    />
    <Path
      d="m341.4 130.8 37.6 36.4 3.4 214.2-192.6 2.9-4-251.2 155.6-2.3m0-4.1-155.7 2.5c-2.4 0-4.1 1.9-4.2 4.3l4 251.2c0 2.4 1.9 4.1 4.3 4.2l192.6-2.9c2.4 0 4.1-1.9 4.2-4.3l-3.4-214.2c0-1.2-.4-2.2-1.2-2.9l-37.6-36.4c-.8-1.2-1.9-1.5-3-1.5z"
      fill="#0D3747"
    />
    <Path
      d="M339.8 172.2c-.6 0-1.1-.2-1.5-.6-.5-.4-.6-.9-.6-1.7l1.7-39.2c.1-.9.6-1.6 1.3-1.9.2-.1.5-.2.8-.1.6 0 1.1.2 1.5.6l37.6 36.4c.6.6.8 1.4.5 2.2-.2.8-1 1.4-1.9 1.4-.1 0-39.4 2.9-39.4 2.9z"
      fill="#FFFFFF"
    />
    <Path
      d="m341.4 130.8 37.6 36.4-39.1 2.8 1.5-39.2m0-4.1c-.5 0-1.1.1-1.6.3-1.5.6-2.5 2.1-2.7 3.7l-1.7 39.2c0 1.2.4 2.3 1.3 3.2.8.8 1.9 1.1 3.1 1.2.1 0 .1 0 .3-.1l39.1-2.8c1.7-.1 3-1.2 3.6-2.8.6-1.6.2-3.4-1.1-4.5l-37.6-36.4c-.5-.7-1.6-1-2.7-1z"
      fill="#0D3747"
    />
    <Path
      d="m217.7 192.1 130.5-2.1M218.1 214.7l130.6-2M218.4 237.3l130.6-2.1M218.9 259.9l130.5-2M219.2 282.5l130.5-2M219.4 305.2l130.7-2.1M219.8 327.9l130.6-2.1M220.1 350.4l130.6-2"
      fill="none"
      stroke="#0D3747"
      strokeWidth="12.1543"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <Path
      d="M164.1 426.4c-1.2 0-2.1-.9-2.2-2.1l-2.1-149.2c-.1-.8.4-1.5 1.1-1.9.4-.2.7-.3 1-.3.5 0 .9.1 1.2.4l122.7 84.5 120-88.3c.4-.2.8-.4 1.2-.4s.7.1 1 .3c.6.3 1.2 1.1 1.3 1.9l2.3 149.2c0 1.2-.9 2.1-2.1 2.2l-245.4 3.7z"
      fill="#0A96A9"
    />
    <Path
      d="m406.8 271.2 2.3 149.2-245.1 3.9-2.3-149.2 123.9 85.4 121.2-89.3m0-4.1c-.9 0-1.7.2-2.5.8l-118.7 87.4-121.4-83.7c-.7-.5-1.6-.8-2.5-.7-.6 0-1.4.2-1.9.5-1.4.8-2.3 2.2-2.3 3.8l2.3 149.2c0 2.4 1.9 4.1 4.3 4.2l245.1-3.8c2.4 0 4.1-1.9 4.2-4.3l-2.3-149.2c0-1.6-.9-3-2.4-3.7-.6-.4-1.3-.6-1.9-.5z"
      fill="#0D3747"
    />
    <Path
      d="M164.1 426.4c-.9 0-1.7-.7-2.1-1.5-.3-1 .1-1.9.9-2.4L284.2 346c.4-.2.7-.4 1.1-.3.5 0 .7.1 1.1.3L410 418.7c.7.5 1.2 1.4 1 2.4-.3 1-1.1 1.6-2 1.7l-244.9 3.6z"
      style={{
        fill: "#81e3ea",
      }}
    />
    <Path
      d="M285.4 347.8 409 420.5l-245 3.8 121.4-76.5m-.1-4.2c-.8.1-1.6.3-2.1.7l-121.3 76.5c-1.6 1-2.4 3-1.8 4.8.5 1.8 2.2 3 4.2 3l245.1-3.8c1.9 0 3.5-1.4 4-3.1s-.2-3.8-1.9-4.7l-123.6-72.7c-1-.5-1.7-.7-2.6-.7z"
      fill="#0D3747"
    />
    <Path
      d="M408.2 490.3c-2.4 0-5.8-.6-9-4.4l-25.9-30.3-6.7 5.8c-2.2 1.8-4.4 2.8-6.6 2.8-4.2.1-7.3-3-8.2-8.1l-11.2-60c-.7-3.3 0-6.3 1.7-8.3 1.4-1.8 3.6-2.7 6-2.7 1.4-.1 2.8.2 4.2.8l57.6 20.3c3.6 1.4 6.2 3.7 6.7 6.8.6 3-.8 6.2-3.7 8.8l-6.7 5.8 25.9 30.3c4.6 5.3 4.1 12.1-1.1 16.7l-15.3 13c-2.5 1.6-5.1 2.7-7.7 2.7z"
      style={{
        fill: "#ea864e",
      }}
    />
    <Path
      d="M348.1 387.6c1 0 2.2.2 3.4.7l57.6 20.3c5.8 2 6.8 7 2 11l-8.7 7.5 27.7 32.3c4 4.7 2.9 9.6-.9 12.9l-15.3 13c-1.8 1.6-3.8 2.3-5.9 2.5-2.4 0-4.9-1.1-7-3.5L373.6 452l-8.7 7.5c-1.8 1.4-3.4 2.1-4.9 2.1-2.7.1-4.9-2-5.7-6l-11.2-60c-1-4.8 1.1-7.9 5-8m-.1-5.3c-3.3.1-6.1 1.4-8.1 3.8-2.1 2.7-2.9 6.3-2.1 10.4l11.2 60c1.2 6.3 5.5 10.4 10.9 10.2 2.9-.1 5.7-1.2 8.3-3.4l4.8-4.1 24.3 28.4c3 3.6 6.9 5.4 11.1 5.5 3.3-.1 6.5-1.3 9.3-3.7l15.3-13c6.4-5.5 7-13.8 1.4-20.3L410 427.6l4.8-4.1c3.8-3.2 5.4-7.2 4.7-11.1-.8-4-3.7-7.2-8.3-8.8l-57.6-20.3c-2.1-.7-3.8-1-5.6-1z"
      fill="#0D3747"
    />
    <Path
      d="m409.1 408.6-57.6-20.3c-3.1-1.1-5.6-.7-7.1.8l77 90 7.8-6.7c3.8-3.2 4.9-8.3.9-12.9l-27.6-32.3 8.7-7.5c4.8-4.1 3.8-9.1-2.1-11.1z"
      style={{
        fill: "#e6e7e8",
      }}
    />
    <Path
      d="m316.6 368.4 17.3 16.9M350.2 354.2l.2 24.1M303 402.2l24.1-.1M330.8 358.4l10 22M367.2 357.1l-8.3 22.6M307.4 421.7l21.8-10.1M306 385.2l22.5 8.3"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeDasharray="11.2833,2.8208,18.8055"
    />
    <Circle cx={768.9} cy={317} r={23.1} fill="#0A96A9"/>
    <Circle cx={456} cy={282.7} r={16.4} fill="#E86B18"/>
    <Circle cx={139.9} cy={432.6} r={11.6} fill="#E86B18"/>
    <Circle cx={120.2} cy={172.8} r={26.9} fill="#E86B18"/>
    <Circle cx={409} cy={109.7} r={10.9} fill="#E86B18"/>
  </Svg>
)
export default Email
