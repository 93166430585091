import { View } from "react-native";
import React from "react";
import { Button, useTheme } from "@rneui/themed";
import { useStyles } from "./styles";

const TimeFrameRow = ({
  onChange,
  timeFrames = [],
  selectedTimeFrame,
  customStyles = {},
}) => {
  const styles = useStyles();
  const { theme } = useTheme();
  return (
    <View style={[styles.container, customStyles.container]}>
      {timeFrames.map((timeFrame, index) => {
        const isSelected = selectedTimeFrame === timeFrame;
        return (
          <Button
            key={index}
            buttonStyle={styles.button}
            type={isSelected ? "solid" : "clear"}
            color={theme.colors.primary}
            title={timeFrame}
            titleStyle={{
              ...styles.title,
              color:
                theme.mode === "dark" && isSelected
                  ? theme.colors.white
                  : theme.mode === "dark" && !isSelected
                  ? theme.colors.primary
                  : isSelected
                  ? theme.colors.white
                  : theme.colors.primary,
            }}
            onPress={() => onChange(timeFrame)}
          />
        );
      })}
    </View>
  );
};

export default TimeFrameRow;
