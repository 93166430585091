import React, { Suspense, lazy, memo } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const MarketsContainer = lazy(() => import("containers/Markets"));

const Markets = memo(() => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <MarketsContainer />
    </Suspense>
  );
});

export default Markets;
