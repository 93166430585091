import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { useEffect, useRef, useState } from "react";
import Toast from "react-native-toast-message";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useStrategies from "./useStrategies";
import useConfigurations from "./useConfigurations";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useUserContext } from "context/user/UserProvider";

Notifications.setNotificationHandler({
  handleNotification: () => ({
    shouldShowAlert: false,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notifications!");
      return;
    }
    const url = Constants.expoConfig.extra.env.BASE_API_URL + "/users/expo";
    const access_token = await AsyncStorage.getItem("token");
    const originalFetch = fetch;

    // Define your custom fetch function with default headers
    function fetchWithDefaultHeaders(url, options) {
      // Set default headers if they are not provided in the options
      if (!options.headers) {
        options.headers = {};
      }

      // Set the default authorization header
      if (!options.headers["Authorization"]) {
        options.headers["Authorization"] = `Bearer ${access_token}`;
      }

      // Call the original fetch function with the modified options
      return originalFetch(url, options);
    }

    // Monkey patch the fetch function with your custom implementation
    global.fetch = fetchWithDefaultHeaders;

    token = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig.extra.eas.projectId,
      url: url,
    });
  } else {
    // alert("Must use physical device for Push Notifications!");
  }
  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  return token;
}

export const useNotifications = ({ navigation }) => {
  const insets = useSafeAreaInsets();
  const { isSignedIn } = useUserContext();
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const { strategies, getStrategyNameById } = useStrategies();
  const { markets } = useConfigurations();

  // const onShare = useCallback(async () => {
  //   try {
  //     const result = await Share.share({
  //       message: expoPushToken.data,
  //     });
  //     if (result.action === Share.sharedAction) {
  //       if (result.activityType) {
  //         // shared with activity type of result.activityType
  //       } else {
  //         // shared
  //       }
  //     } else if (result.action === Share.dismissedAction) {
  //       // dismissed
  //     }
  //   } catch (error) {
  //     Alert.alert(error.message);
  //   }
  // }, [Share.share, expoPushToken]);

  // useEffect(() => {
  //   if (!!expoPushToken) {
  //     console.log(
  //       "🚀 ~ file: useNotifications.js:77 ~ useEffect ~ expoPushToken:",
  //       expoPushToken
  //     );
  //     onShare();
  //   }
  // }, [expoPushToken]);

  const checkForRegistration = async () => {
    const pushTokenFromStorage = await AsyncStorage.getItem("expoPushToken");
    if (!!pushTokenFromStorage) {
      setExpoPushToken(pushTokenFromStorage);
    } else {
      registerForPushNotificationsAsync()
        .then(async (token) => {
          setExpoPushToken(token);
          await AsyncStorage.setItem("expoPushToken", token);
        })
        .catch((e) => {
          console.log(
            "🚀 ~ file: useNotifications.js:101 ~ registerForPushNotificationsAsync ~ e:",
            e
          );
        });
    }
  };

  useEffect(() => {
    if (isSignedIn && Platform.OS !== "web") checkForRegistration();
  }, [isSignedIn]);

  useEffect(() => {
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
        Toast.show({
          type: "alertNotification",
          text1: notification.request.content.title,
          text2: notification.request.content.body,
          topOffset: insets.top,
          autoHide: true,
          visibilityTime: 4000,
          props: {
            data: notification.request.content.data?.props,
            navigation,
            markets,
            strategies,
            getStrategyNameById,
          },
        });
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log("notifResponse", response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return {
    expoPushToken,
    notification,
  };
};
