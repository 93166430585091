import { View } from "react-native";
import React, { useContext } from "react";
import { Button, Icon, Text, makeStyles, useTheme } from "@rneui/themed";
import { SCREENS_NAMES } from "constants/screens";
import { POSITION_COLOR, POSITION_ICON } from "constants/POSITION";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import * as Linking from "expo-linking";
import { useStyles } from "./AlertNotificationToastStyles";

const AlertNotificationToast = (toastProps) => {
  const { theme } = useTheme();
  const { text1, text2, props, hide } = toastProps;
  const { market, event, position, strategy_id, price, time, time_frame, url } =
    props.data;
  const { markets, strategies } = useContext(DataConfigsContext);
  const { navigation } = props;
  const navigateToChartView = () => {
    hide();
    Linking.openURL(Linking.createURL(url));
  };

  const dismiss = () => {
    hide();
  };

  const styles = useStyles();
  return (
    <View style={styles.root}>
      <View style={styles.wrapper}>
        <View style={styles.titleWrapper}>
          <View style={styles.row}>
            <Icon
              type={
                (
                  POSITION_ICON[position.toLocaleUpperCase()] ||
                  POSITION_ICON.NEUTRAL
                )?.type
              }
              name={
                (
                  POSITION_ICON[position.toLocaleUpperCase()] ||
                  POSITION_ICON.NEUTRAL
                )?.name
              }
              color={
                POSITION_COLOR[position.toLocaleUpperCase()] ||
                POSITION_COLOR.NEUTRAL
              }
              size={18}
            />

            <Text
              h4
              h4Style={[
                {
                  fontWeight: "bold",
                  color:
                    POSITION_COLOR[position.toLocaleUpperCase()] ||
                    POSITION_COLOR.NEUTRAL,
                  marginLeft: 6,
                },
              ]}
              adjustsFontSizeToFit
            >
              {market}
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              h4
              h4Style={[
                {
                  fontWeight: "bold",
                  flexDirection: "row",
                },
              ]}
              adjustsFontSizeToFit
            >
              {price}$
            </Text>
          </View>
        </View>

        <View style={styles.bodyWrapper}>
          <Text style={styles.eventText}>{event}</Text>
        </View>
        <View style={styles.footerWrapepr}>
          <View style={styles.actionsWrapper}>
            <Button
              containerStyle={styles.actionBtn}
              buttonStyle={styles.viewBtnStyle}
              onPress={navigateToChartView}
              color={theme.colors.primary}
              titleStyle={styles.viewBtnTitleStyle}
            >
              View on Chart
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

export default AlertNotificationToast;
