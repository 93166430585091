import { Text, View } from "react-native";
import React, { memo, useCallback, useMemo } from "react";
import { Button } from "@rneui/themed";
import { makeStyles } from "@rneui/themed";

const TabButton = ({ items, onChange, selectedIndex, customStyles = {} }) => {
  const styles = useStyles();
  const onButtonPress = useCallback(
    (index) => {
      onChange(index);
    },
    [onChange]
  );
  const renderedButtons = useMemo(() => {
    return items.map((item, index) => (
      <Button
        key={index}
        onPress={() => onButtonPress(index)}
        type={"clear"}
        containerStyle={[
          selectedIndex === index && styles.selectedButtonContainerStyle,
          {
            width: `${100 / items.length - 5}%`,
          },
        ]}
        titleStyle={[
          styles.titleStyle,
          selectedIndex === index && styles.selectedTitleStyle,
        ]}
        titleProps={{ adjustsFontSizeToFit: true, numberOfLines: 1 }}
      >
        <Text
          adjustsFontSizeToFit
          style={[
            styles.titleStyle,
            selectedIndex === index && styles.selectedTitleStyle,
            {
              width: "100%",
            },
          ]}
          numberOfLines={1}
        >
          {item}
        </Text>
      </Button>
    ));
  }, [items, selectedIndex, styles.selectedButtonContainerStyle]);
  return (
    <View style={[styles.root, customStyles.root]}>{renderedButtons}</View>
  );
};

export default memo(TabButton, (prevProps, nextProps) => {
  JSON.stringify(prevProps.items === nextProps.items) &&
    JSON.stringify(prevProps.selectedIndex) ===
      JSON.stringify(nextProps.selectedIndex) &&
    prevProps.onChange === nextProps.onChange;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    // paddingHorizontal: 24,
  },
  selectedButtonContainerStyle: {
    // backgroundColor: theme.colors.primary,
    // borderRadius: 6,
  },
  titleStyle: {
    fontSize: 16,
    color: theme.colors.grey2,
    textAlign: "center",
  },
  selectedTitleStyle: {
    color: theme.colors.primary,
    fontWeight: "bold",
    fontSize: 18,
  },
}));
