import Alerts from "./Alerts";
import Markets from "./Markets";
import Strategies from "./Strategies";
import Settings from "./Settings";
import Home from "./Home";
import ViewStrategy from "./ViewStrategy";
import ViewChart from "./ViewChart";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import Account from "./Account";
import Notifications from "./Notifications";
import Pricing from "./Pricing";
import Payment from "./Payment";
import { SCREENS_NAMES } from "constants/screens";
import { ViewChartFullScreen } from "./ViewChartFullScreen";
import CreateAlert from "./CreateAlert";
import Login from "containers/Settings/components/Login";
import Preferences from "containers/Settings/components/Prefernces";
import PaymentsHistory from "./PaymentsHistory";
import OnBoarding from "./OnBoarding";
import DeleteAccount from "./DeleteAccount";
import withInternetCheck from "../HOC/withInternetCheck";
import EmailVerification from "./EmailVerification";
import NotFound from "./NotFound";

export const HOME_TAB_SCREENS = [
  {
    name: SCREENS_NAMES.MARKETS,
    title: "Markets",
    component: withInternetCheck(Markets),
  },
  {
    name: SCREENS_NAMES.ALERTS,
    title: "Alerts",
    component: withInternetCheck(Alerts),
  },
  {
    name: SCREENS_NAMES.CREATE_ALERT,
    title: "Create Alert",
    component: withInternetCheck(CreateAlert),
  },
  {
    name: SCREENS_NAMES.STRATEGIES,
    title: "Indicators",
    component: withInternetCheck(Strategies),
  },
  {
    name: SCREENS_NAMES.ACCOUNT,
    title: "More",
    component: withInternetCheck(Account),
  },
];

export const HOME_DRAWER_SCREENS = [
  {
    name: SCREENS_NAMES.MARKETS,
    title: "Markets",
    component: withInternetCheck(Markets),
  },
  {
    name: SCREENS_NAMES.ALERTS,
    title: "Alerts",
    component: withInternetCheck(Alerts),
  },
  {
    name: SCREENS_NAMES.CREATE_ALERT,
    title: "Create Alert",
    component: withInternetCheck(CreateAlert),
  },
  {
    name: SCREENS_NAMES.STRATEGIES,
    title: "Indicators",
    component: withInternetCheck(Strategies),
  },
  {
    name: SCREENS_NAMES.PRICING,
    title: "Pricing",
    component: withInternetCheck(Pricing),
  },
  {
    name: SCREENS_NAMES.PREFERENCES,
    title: "Preferences",
    component: withInternetCheck(Preferences),
  },
  {
    name: SCREENS_NAMES.ACCOUNT,
    title: "Account",
    component: withInternetCheck(Account),
  },
];

export const ON_BOARDING_SCREENS = [
  {
    name: SCREENS_NAMES.ON_BOARDING,
    title: "On Boarding",
    component: withInternetCheck(OnBoarding),
  },
  {
    name: SCREENS_NAMES.LOGIN,
    title: "Login",
    component: withInternetCheck(Login),
  },
  {
    name: SCREENS_NAMES.REGISTER,
    title: "Sign Up",
    component: withInternetCheck(Register),
  },
  {
    name: SCREENS_NAMES.EMAIL_VERIFICATION,
    title: "Email Verification",
    component: withInternetCheck(EmailVerification),
  },
];

export const SCREENS = [
  {
    name: SCREENS_NAMES.HOME,
    title: "Home",
    component: Home,
  },
  {
    name: SCREENS_NAMES.VIEW_STRATEGY,
    title: "View Indicator",
    component: withInternetCheck(ViewStrategy),
  },
  {
    name: SCREENS_NAMES.VIEW_CHART,
    title: "View Chart",
    component: withInternetCheck(ViewChart),
  },
  {
    name: SCREENS_NAMES.FULL_SCREEN_VIEW_CHART,
    title: "Full Screen Chart",
    component: withInternetCheck(ViewChartFullScreen),
  },
  {
    name: SCREENS_NAMES.PAYMENT,
    title: "Payment",
    component: withInternetCheck(Payment),
  },
  {
    name: SCREENS_NAMES.PAYMENTS_HISTORY,
    title: "Payments History",
    component: withInternetCheck(PaymentsHistory),
  },
  {
    name: SCREENS_NAMES.LOGIN,
    title: "Login",
    component: withInternetCheck(Login),
  },
  {
    name: SCREENS_NAMES.REGISTER,
    title: "Sign Up",
    component: withInternetCheck(Register),
  },
  {
    name: SCREENS_NAMES.FORGOT_PASSWORD,
    title: "Forgot Password",
    component: withInternetCheck(ForgotPassword),
  },
  {
    name: SCREENS_NAMES.ACCOUNT,
    title: "Account",
    component: withInternetCheck(Account),
  },
  {
    name: SCREENS_NAMES.DELETE_ACCOUNT,
    title: "Delete Account",
    component: withInternetCheck(DeleteAccount),
  },
  {
    name: SCREENS_NAMES.EMAIL_VERIFICATION,
    title: "Email Verification",
    component: withInternetCheck(EmailVerification),
  },
  {
    name: SCREENS_NAMES.NOTIFICATIONS,
    title: "Notifications",
    component: withInternetCheck(Notifications),
  },
  {
    name: SCREENS_NAMES.SETTINGS,
    title: "Settings",
    component: withInternetCheck(Settings),
  },
  {
    name: SCREENS_NAMES.NOT_FOUND,
    title: "Not Found",
    component: withInternetCheck(NotFound),
  },
];
