import React, { useState } from "react";
import { Text, useWindowDimensions } from "react-native";
import Animated from "react-native-reanimated";
import { useStyles } from "./NetInfoStyles";
import { useTheme } from "@rneui/themed";
import NoWifiApp from "components/UI/NoWifiApp";
import NoWifiWeb from "components/UI/NoWifiWeb";

const NetInfoStatus = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const isLargeScreen = width >= theme.breakpoints.tablet;

  return (
    <Animated.View style={styles.container}>
      {isLargeScreen ? (
        <NoWifiWeb width={150} height={150} />
      ) : (
        <NoWifiApp width={150} height={150} />
      )}
      <Text style={styles.text1}>No Internet Connection</Text>
      <Text numberOfLines={1} style={styles.text2}>
        Please check your connection.
      </Text>
    </Animated.View>
  );
};
export default NetInfoStatus;
