import { useQuery } from "react-query";
import { getAllPayments } from "api/payment";
import { useState } from "react";

const usePayments = () => {
  const [showBuySubscription, setShowBuySubscription] = useState(false);
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useQuery("getAllPayments", getAllPayments);

  const payments = data?.data || [];

  return {
    payments,
    isLoading,
    isSuccess,
    showBuySubscription,
    setShowBuySubscription,
  };
};

export default usePayments;
