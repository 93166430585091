import { Platform, useWindowDimensions, View } from "react-native";
import React, { memo, useEffect, useMemo } from "react";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useNavigation } from "@react-navigation/native";
/**
 * A component that displays tabbed content. It allows switching between different
 * content views represented by the `children` prop. The `tabIndex` prop determines
 * which child content is currently active. The optional `contentWidth` prop can be
 * used to specify a custom width for the content area, useful when the content's
 * natural width is smaller than the device screen width, ensuring the content fits
 * well within the available space.
 *
 * @param {Object} props - The properties passed to the TabView component.
 * @param {ReactNode[]} props.children - An array of React nodes representing the
 *   different content views for each tab.
 * @param {number} [props.tabIndex=0] - The index of the currently active tab.
 * @param {number} [props.contentWidth] - An optional prop to specify the width of
 *   the content area. This is particularly useful when the content's natural width
 *   is smaller than the device screen width, allowing for precise control over the
 *   layout and ensuring the content fits well within the available space.
 */
const TabView = ({ children, tabIndex = 0, contentWidth }) => {
  const navigation = useNavigation();
  const navigator = navigation.getParent("drawerNavigator");
  const { width } = useWindowDimensions();
  const tabValue = useSharedValue(tabIndex);
  const renderedTab = useMemo(() => {
    if (!Array.isArray(children)) return null;
    return children[tabIndex];
  }, [tabIndex, children]);

  useEffect(() => {
    tabValue.value = withTiming(tabIndex, {
      duration: 300,
      easing: Easing.out(Easing.quad),
    });
  });

  const animatedStyle = useAnimatedStyle(() => {
    if (Platform.OS !== "web") {
      return {
        left: 0 - tabValue.value * (contentWidth ?? width),
      };
    }
    return {
      left: `${0 - tabValue.value * 100}%`,
    };
  }, [contentWidth, width, tabValue]);

  const viewStyle = useMemo(
    () => ({ flex: 1, width: "100%", overflow: "hidden" }),
    []
  );

  const animatedViewStyle = useMemo(
    () => [
      { flex: 1, flexDirection: "row", width: `${children.length * 100}%` },
      animatedStyle,
    ],
    [animatedStyle, children.length]
  );

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <View style={viewStyle}>
      <Animated.View style={animatedViewStyle}>{childrenMemo}</Animated.View>
    </View>
  );
  return <View style={{ flex: 1 }}>{renderedTab}</View>;
};

export default memo(
  TabView,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.tabIndex === nextProps.tabIndex
);
