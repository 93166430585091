import React, { useContext, useMemo } from "react";
import { ScrollView, View } from "react-native";
import SwitchButton from "components/SwitchButton";
import PricingCard from "containers/Pricing/components/PricingCard";
import useMainPricing from "./useMainPricing";
import useStyles from "./styles";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { useUserContext } from "context/user/UserProvider";
import Icon from "components/UI/Icon";
import { Text, useTheme } from "@rneui/themed";

const MainPricing = ({ changeTab }) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const { isLastPaymentPending, isPaymentsFetch, selectedIndex, onPress, getCards } =
    useMainPricing();
  const { plans } = useContext(DataConfigsContext);
  const { subscriptions } = useUserContext();
  const currentActiveSubscription = useMemo(() => {
    return subscriptions.find((sub) => sub.status === "ACTIVE");
  }, [JSON.stringify(subscriptions)]);

  return (
    <ScrollView>
      <View style={styles.container}>
        <SwitchButton
          names={["Monthly", "Annually"]}
          onPress={onPress}
          selectedIndex={selectedIndex}
        />
        {isLastPaymentPending && (
          <View style={styles.warningTextWrapper}>
            <Icon
              type="ant-design"
              name="warning"
              size={16}
              color={theme.colors.grey1}
            />
            <Text style={styles.warningText}>Your last payment has to be verified by the support team.</Text>
          </View>
        )}
        <View style={styles.webContainer}>
          {Array.isArray(plans) &&
            getCards(selectedIndex).map((card) => (
              <PricingCard
                key={card.key}
                item={card}
                currentActiveSubscription={currentActiveSubscription}
                isActive={currentActiveSubscription?.plan?.id === card.plan_id}
                disableButtons={isLastPaymentPending}
                isPaymentsFetch={isPaymentsFetch}
                changeTab={changeTab}
              />
            ))}
        </View>
      </View>
    </ScrollView>
  );
};

export default MainPricing;