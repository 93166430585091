import React, { memo, useCallback, useContext, useState } from "react";
import { Platform, useWindowDimensions, View } from "react-native";
import { Image, Text, useTheme } from "@rneui/themed";
import { getMarketImageUrl } from "api/media";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { MarketViewContext } from "context/MarketViewContext";
import { TouchableOpacity } from "react-native-gesture-handler";
import MarketSearchBottomSheet from "components/MarketSearchBottomSheet";
import { Icon } from "@rneui/base";

const ChartHeader = ({ params }) => {
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const { markets } = useContext(DataConfigsContext);
  const { currentMarket, setCurrentMarket } = useContext(MarketViewContext);
  const [showMarketModal, setShowMarketModal] = useState(false);

  const toggleMarkets = useCallback(() => {
    setShowMarketModal(true);
  }, [setShowMarketModal]);

  const onSelectMarket = useCallback(
    (marketId) => {
      if (currentMarket !== marketId) setCurrentMarket(marketId);
    },
    [currentMarket, setCurrentMarket]
  );

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Image
        source={{
          uri: getMarketImageUrl({ market: params.children.toLowerCase() }),
          cache: true,
        }}
        style={{
          width: 20,
          height: 20,
          borderRadius: 50,
        }}
      />
      <TouchableOpacity
        onPress={toggleMarkets}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <Text
          style={{
            color: theme.colors.whiteText,
            fontSize: 18,
            fontWeight: "light",
            marginLeft: 6,
          }}
          adjustsFontSizeToFit
        >
          {params.children}
        </Text>
        {(Platform.OS !== "web" || width < theme.breakpoints.laptop) && (
          <Icon
            type="material"
            name="expand-more"
            size={18}
            color={theme.colors.whiteText}
          />
        )}
      </TouchableOpacity>
      <MarketSearchBottomSheet
        showMarketModal={showMarketModal}
        setShowMarketModal={setShowMarketModal}
        onSelectMarket={onSelectMarket}
        marketItemProps={{ showIndicator: false }}
        {...{ markets }}
      />
    </View>
  );
};

export default memo(ChartHeader, (prevProps, nextProps) => {
  return prevProps?.params?.children === nextProps?.params?.children;
});
