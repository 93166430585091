import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MarketViewContext } from "./MarketViewContext";
import AlertsModal from "components/AlertsModal";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";

export const MarketViewProvider = ({ children }) => {
  const navigation = useNavigation();
  const {
    markets,
    time_frame,
    marketsIsLoading,
    strategies,
    getStrategyNameById,
    strategiesIsLoading,
    isLoadingDone,
  } = useContext(DataConfigsContext);

  const [currentMarket, setCurrentMarket] = useState(null);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(null);
  const [currentStrategy, setCurrentStrategy] = useState([]);
  const [currentSignalTime, setCurrentSignalTime] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [alertNotificationsVisibility, setAlertNotificationsVisibility] =
    useState(true);
  const [marketsVisibility, setMarketsVisibility] = useState(true);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const updateStates = useCallback(
    ({ market, timeFrame, strategy, signalTime, isFullScreen }) => {
      setCurrentMarket(market);
      setCurrentTimeFrame(timeFrame);
      setCurrentStrategy(strategy);
      setCurrentSignalTime(signalTime);
      setIsFullScreen(isFullScreen);
    },
    [
      setCurrentMarket,
      setCurrentTimeFrame,
      setCurrentStrategy,
      setCurrentSignalTime,
      setIsFullScreen,
    ]
  );

  const handleChange = useCallback(
    (type, item) => {
      if (type === "MARKET") {
        setCurrentMarket(item.value);
        navigation.navigate(SCREENS_NAMES.VIEW_CHART, { marketId: item.value });
      }
      if (type === "STRATEGY") {
        if (Array.isArray(item)) {
          setCurrentStrategy(item.map((i) => i.value));
        } else {
          setCurrentStrategy(
            currentStrategy.includes(item.value)
              ? currentStrategy.filter((st) => st !== item.value)
              : [...currentStrategy, item.value]
          );
        }
      }
      if (type === "TIME_FRAME") setCurrentTimeFrame(item.value);
      // setSignalTime(null);
    },
    [
      setCurrentMarket,
      navigation.navigate,
      setCurrentStrategy,
      JSON.stringify(currentStrategy),
    ]
  );

  const openModal = useCallback(() => {
    setShowAlertModal(true);
  }, []);

  useEffect(() => {
    const onFullScreenChange = () => {
      if (Platform.OS === "web") {
        setIsFullScreen(!!document.fullscreenElement);
      }
    };
    if (Platform.OS === "web") {
      document.addEventListener("fullscreenchange", onFullScreenChange);
      return () => {
        document.removeEventListener("fullscreenchange", onFullScreenChange);
      };
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      currentMarket,
      setCurrentMarket,
      currentTimeFrame,
      setCurrentTimeFrame,
      currentStrategy,
      setCurrentStrategy,
      currentSignalTime,
      setCurrentSignalTime,
      updateStates,
      handleChange,
      openModal,
      isFullScreen,
      setIsFullScreen,
      alertNotificationsVisibility,
      setAlertNotificationsVisibility,
      marketsVisibility,
      setMarketsVisibility,
      showAlertModal,
      setShowAlertModal,
    };
  }, [
    currentMarket,
    setCurrentMarket,
    currentTimeFrame,
    setCurrentTimeFrame,
    currentStrategy,
    setCurrentStrategy,
    currentSignalTime,
    setCurrentSignalTime,
    updateStates,
    handleChange,
    openModal,
    isFullScreen,
    alertNotificationsVisibility,
    marketsVisibility,
    showAlertModal,
    setShowAlertModal,
  ]);

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <MarketViewContext.Provider value={contextValue}>
      <View
        style={{
          flex: 1,
        }}
      >
        {childrenMemo}
        <AlertsModal
          {...{
            showAlertModal,
            setShowAlertModal,
            markets,
            marketId: currentMarket,
            strategies,
            strategyId: currentStrategy,
            getStrategyNameById,
            handleChange,
            setSignalTime: setCurrentSignalTime,
          }}
        />
      </View>
    </MarketViewContext.Provider>
  );
};
