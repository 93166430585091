import { makeStyles } from "@rneui/themed";
import { STYLES, FLEX_POSITIONS } from "constants/STYLES";

const useStyles = makeStyles((theme) => ({
  flexRowCenter: {
    ...FLEX_POSITIONS.ROW_CENTER,
  },
  containerStyle: {
    minWidth: 200,
    backgroundColor: "transparent",
    borderRadius: STYLES.BORDER_RADIUS.ICON,
  },
  buttonContainerStyle: {
  },
  selectedButtonStyle: {
    minHeight: 30,
    color: theme.colors.grey0,
    backgroundColor: theme.colors.grey4,
  },
  textStyle: {
  },
  selectedTextStyle : {
    color: theme.colors.black,
  },
}));

export default useStyles;
