import { makeStyles } from "@rneui/themed";
import { STYLES, FLEX_POSITIONS } from "constants/STYLES";

const useStyles = makeStyles((theme) => ({
  listContainerStyle: {
    ...FLEX_POSITIONS.ROW_CENTER,
    backgroundColor: "transparent",
    padding: 0,
    flexDirection: "column",
  },

  listContentContainerStyle: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
  },

  listItemText: {
    ...FLEX_POSITIONS.ROW_CENTER,
    fontSize: 12,
    color:
      theme.mode === "dark"
        ? theme.colors.whiteText
        : STYLES.COLORS.TEXT.DARK_GRAY,
  },

  iconListStyle: {
    marginRight: STYLES.MARGIN.SX,
    color: STYLES.COLORS.ICON.MIDDLE_GRAY_2,
  },
}));

export default useStyles;
