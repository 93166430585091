import { makeStyles } from "@rneui/themed";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    backgroundColor: theme.colors.grey5,
  },
  text1: {
    fontWeight: '300',
    marginBottom: 5,
    color: theme.colors.grey2,
  },
  text2: {
    fontWeight: '300',
    color: theme.colors.grey2,
  },
}));
