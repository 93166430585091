import "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClient, QueryClientProvider } from "react-query";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { createContext, useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import { useTheme } from "hooks/useTheme";
import MainLayout from "./src/Layout/MainLayout";
import { LogBox, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

LogBox.ignoreAllLogs();

SplashScreen.preventAutoHideAsync();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 1,
    },
  },
});

export const AppThemeContext = createContext();

export default function App() {
  const { colorScheme, theme, NavigationTheme, mode, setMode } =
    useTheme();

  useEffect(() => {
    const updateThemeBasedOnLocalStorage = async () => {
      try {
        const storedColorScheme = await AsyncStorage.getItem('colorScheme');
        if (storedColorScheme) {
          setMode(storedColorScheme);
        }
      } catch (error) {
        console.error('Failed to fetch the color scheme from storage:', error);
      }
    };

    // Event handler for storage event
    const handleStorageChange = (event) => {
      if (event.key === 'colorScheme') {
        updateThemeBasedOnLocalStorage();
      }
    };

    // Listen for storage events to update theme in other tabs
    if (Platform.OS === "web")
      window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      if (Platform.OS === "web")
        window.removeEventListener('storage', handleStorageChange);
    };
  }, [setMode]);

  return (
    <SafeAreaProvider>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <QueryClientProvider client={queryClient}>
          <AppThemeContext.Provider
            value={{
              colorScheme,
              mode,
              NavigationTheme,
              theme,
              setMode,
            }}
          >
            <MainLayout/>
          </AppThemeContext.Provider>
        </QueryClientProvider>
      </GestureHandlerRootView>
    </SafeAreaProvider>
  );
}
