import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import alertsApi from "api/alerts";
import { useUserContext } from "context/user/UserProvider";
import Toast from "react-native-toast-message";
import { useFocusEffect } from "@react-navigation/native";

export const useActiveAlerts = ({ list }) => {
  const queryClient = useQueryClient();
  const { token } = useUserContext();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);

  const deleteRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const searchRef = useRef();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [filters, setFilters] = useState({
    isMultiple: false,
    market: null,
    strategyId: null,
    timeFrame: null
  });
  const [isFilterableData, setIsFilterableData] = useState(false);
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  const displayedAlerts = useMemo(() =>
      isFilterableData ? filteredAlerts : list
    , [list, filteredAlerts]);

  const { mutate: onRemove } = useMutation({
    mutationFn: async (ids) => {
      setLoading(true);
      await alertsApi.removeActiveAlertsById({ ids });
    },
    onSuccess: async (data, id) => {
      await queryClient.invalidateQueries({
        queryKey: [`getAllActiveAlerts${token}`],
      });
      isFilterableData && await handleSearch(filters);
      setLoading(false);
      onToggleEdit();
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully removed active alert.",
      });
    },
    onError: (error, id) => {
      setLoading(false);
      Toast.show({
        type: "error",
        position: "top",
        text1: "There is a problem.",
      });
    },
  });

  useEffect(() => {
    showDeleteModal && openDeleteModal();
  }, [showDeleteModal]);

  const openDeleteModal = () => {
    deleteRef.current?.handleOpenModalPress();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    deleteRef.current?.handleCloseModalPress();
  };

  const handleConfirmDelete = () => {
    onRemove(selectedItems);
    closeDeleteModal();
  };

  const onChangeActiveAlertSelect = useCallback(
    (id) => {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(id)
          ? prevSelectedItems.filter((item) => item !== id)
          : [...prevSelectedItems, id]
      );
    },
    [setSelectedItems]
  );

  const onPressAddAlert = () => {
    setShowCreateAlertModal(true);
  };

  const isAllItemsSelected = useMemo(() => {
    return selectedItems.length === displayedAlerts.length;
  }, [list, selectedItems, filteredAlerts, isFilterableData, displayedAlerts]);

  const onToggleEdit = useCallback(() => {
    setIsEditing((prevIsEditing) => !prevIsEditing);
    setSelectedItems([]);
  }, [setIsEditing, setSelectedItems]);

  const onSelectAllPress = useCallback(() => {
    if (isAllItemsSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(displayedAlerts.map((item) => item.id));
    }
  }, [
    setSelectedItems,
    list,
    isAllItemsSelected,
    filteredAlerts,
    isFilterableData,
    displayedAlerts
  ]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setIsEditing(false);
        setSelectedItems([]);
      };
    }, [onToggleEdit])
  );

  useEffect(() => {
    showSearchModal && openSearchModal();
  }, [showSearchModal]);

  const openSearchModal = () => {
    searchRef.current?.handleOpenModalPress();
  };

  const closeSearchModal = () => {
    setShowSearchModal(false);
    searchRef.current?.handleCloseModalPress();
  };

  const handleSearch = async ({ isMultiple, market, strategyId, timeFrame }) => {
    try {
      if (!isMultiple || (Array.isArray(market) && Array.isArray(strategyId) && Array.isArray(timeFrame))) {
        const filteredAlerts = findAlert({ market, strategyId, timeFrame });
        setFilters({ isMultiple, market, strategyId, timeFrame });
        setIsFilterableData(true);
        setFilteredAlerts(filteredAlerts);
        closeSearchModal();
      }
    } catch (error) {
      Toast.show({
        text1: "Error",
        text2: error.message,
        type: "error",
      });
    }
  };

  const handleClearSearch = async () => {
    setFilters({
      isMultiple: false,
      market: null,
      strategyId: null,
      timeFrame: null
    });
    setIsFilterableData(false);
    setFilteredAlerts([]);
  };

  const findAlert = ({ market, strategyId, timeFrame }) => {
    const { data } = queryClient.getQueryData(`getAllActiveAlerts${token}`);

    return data?.filter((item) =>
      (!market || (Array.isArray(market) ? Array.from({ length: market.length }).every(i => market.includes(item.market)) : item.market === market)) &&
      (!strategyId || (Array.isArray(strategyId) ? Array.from({ length: strategyId.length }).every(i => strategyId.includes(item.strategy_id)) : item.strategy_id === strategyId)) &&
      (!timeFrame || (Array.isArray(timeFrame) ? Array.from({ length: timeFrame.length }).every(i => timeFrame.includes(item.time_frame)) : item.time_frame === timeFrame))
    );
  };

  return {
    list,
    displayedAlerts,
    isEditing,
    setIsEditing,
    selectedItems,
    setSelectedItems,
    loading,
    setLoading,
    handleConfirmDelete,
    onChangeActiveAlertSelect,
    onPressAddAlert,
    isAllItemsSelected,
    onToggleEdit,
    onSelectAllPress,
    showCreateAlertModal,
    setShowCreateAlertModal,
    deleteRef,
    showDeleteModal,
    setShowDeleteModal,
    closeDeleteModal,
    searchRef,
    showSearchModal,
    setShowSearchModal,
    handleSearch,
    handleClearSearch,
    filters,
    isFilterableData,
    filteredAlerts,
  };
};
