import { StyleSheet, Text, View } from "react-native";
import React, { useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import Navigation from "containers/Navigation";
import { StatusBar } from "expo-status-bar";
import Toast from "react-native-toast-message";
import { AppThemeContext } from "App";
import { toastConfigs } from "common/toastConfigs";
import { ThemeProvider, createTheme } from "@rneui/themed";
import { linking } from "Screens/linking";
import { ChartLoaderProvider } from "context/chartLoader";
import { UserProvider } from "context/user/UserProvider";
import { KeyclockOpenIdProvider } from "containers/oAuth/KeyclockOpenIdProvider";
import { DataConfigsProvider } from "context/dataconfigs";
import { MarketViewProvider } from "context/MarketViewContext";

const MainLayout = () => {
  const { theme, NavigationTheme, mode, updateTheme } =
    useContext(AppThemeContext);
  if (theme === undefined) return null;
  return (
    <>
      <ThemeProvider useDark={mode === "dark"} theme={theme}>
        <ChartLoaderProvider>
          <UserProvider>
            <KeyclockOpenIdProvider>
              <DataConfigsProvider>
                <NavigationContainer linking={linking} theme={NavigationTheme}>
                  <BottomSheetModalProvider>
                    <MarketViewProvider>
                      <Navigation />
                      <StatusBar style={mode === "dark" ? "light" : "dark"} />
                    </MarketViewProvider>
                  </BottomSheetModalProvider>
                  <Toast position="top" config={toastConfigs} />
                </NavigationContainer>
              </DataConfigsProvider>
            </KeyclockOpenIdProvider>
          </UserProvider>
        </ChartLoaderProvider>
      </ThemeProvider>
    </>
  );
};

export default MainLayout;

const styles = StyleSheet.create({});
