import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import { Icon, Image, Text, useTheme } from "@rneui/themed";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import StrategySlider from "components/StrategySlider";
import TimeFrameRow from "components/TimeFrameRow";
import { getMarketImageUrl } from "api/media";
import { TouchableOpacity } from "react-native-gesture-handler";
import MarketSearchBottomSheet from "components/MarketSearchBottomSheet";
import { STYLES } from "constants/STYLES";
import ModalRenderer from "components/ModalRenderer";

const ChartSettingsBottomSheet = ({
  strategies,
  currentStrategy,
  markets,
  currentMarket,
  time_frame,
  currentTimeFrame,
  handleChange,
  showSettingModal,
  setShowSettingModal,
}) => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const ref = useRef();
  const isLargeScreen = useMemo(
    () => Platform.OS === "web" && width >= theme.breakpoints.tablet,
    [width]
  );
  const [showMarketModal, setShowMarketModal] = useState(false);

  useEffect(() => {
    showSettingModal && openModal();
  }, [showSettingModal]);

  const openModal = () => {
    ref.current?.handleOpenModalPress();
  };

  const openMarketSearch = () => {
    setShowMarketModal(true);
  };

  const marketName = useMemo(() => {
    const market = markets?.find((market) => {
      return market.id === currentMarket;
    });
    return market?.market;
  }, [currentMarket, markets]);

  const onSelectMarket = useCallback(
    (value) => {
      setShowMarketModal(false);
      handleChange("MARKET", { value });
    },
    [setShowMarketModal, handleChange]
  );

  const renderedBody = (
    <View
      style={[
        {
          width: "100%",
          flex: 1,
          padding: 12,
          ...(isLargeScreen && { borderRadius: STYLES.BORDER_RADIUS.CARD }),
        },
      ]}
    >
      <View
        style={{
          marginBottom: 12,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontWeight: "light",
              color: theme.colors.grey1,
            }}
          >
            Market
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <TouchableOpacity
            style={{
              width: "100%",
              height: 60,
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={openMarketSearch}
          >
            <View>
              <Image
                source={{
                  uri: getMarketImageUrl({ market: marketName }),
                  cache: true,
                }}
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  marginRight: 6,
                }}
              />
            </View>
            <Text
              style={{
                color: theme.colors.whiteText,
                fontSize: 18,
              }}
            >
              {marketName}
            </Text>
            <Icon
              type="material"
              name="expand-more"
              size={16}
              color={theme.colors.whiteText}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          marginBottom: 12,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontWeight: "light",
              color: theme.colors.grey1,
            }}
          >
            Indicators
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <View style={{ width: "100%", height: 60, marginTop: 10 }}>
            <StrategySlider
              {...{ currentStrategy, strategies, theme, handleChange }}
            />
          </View>
        </View>
      </View>
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontWeight: "light",
              color: theme.colors.grey1,
            }}
          >
            Time Frame
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              maxWidth: 100,
            }}
          >
            <TimeFrameRow
              onChange={(value) => handleChange("TIME_FRAME", { value })}
              timeFrames={time_frame}
              selectedTimeFrame={currentTimeFrame}
              customStyles={{
                container: {
                  justifyContent: "space-between",
                  paddingHorizontal: 12,
                },
              }}
            />
          </View>
        </View>
      </View>
      <MarketSearchBottomSheet
        markets={markets}
        marketItemProps={{ showIndicator: false }}
        onSelectMarket={onSelectMarket}
        showMarketModal={showMarketModal}
        setShowMarketModal={setShowMarketModal}
      />
    </View>
  );

  return (
    <ModalRenderer
      ref={ref}
      showModal={showSettingModal}
      setShowModal={setShowSettingModal}
      title={isLargeScreen && "Settings"}
      content={renderedBody}
    />
  );
};

export default ChartSettingsBottomSheet;

const styles = StyleSheet.create({});
