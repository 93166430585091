import React from "react";
import { useStyles } from "./styles";
import { Dialog } from "@rneui/themed";

/**
 * A renderer component for a dialog modal. It uses the Dialog component from @rneui/themed
 * to display a modal with a title and content. The visibility of the modal is controlled by
 * the `showBuySubscription` prop, and it can be closed by pressing the backdrop.
 *
 * @component
 *
 * @param {Function} handleCloseModalPress - A function to close the dialog.
 * @param {boolean} showModal - A boolean indicating whether the dialog is visible.
 * @param {string | React.Element} title - The title of the dialog, which can be a string or a React element.
 * @param {React.Element} content - The content of the dialog, which can be a string or a React element.
 * @returns {React.Element} The rendered dialog component.
 */
const DialogRenderer = ({ handleCloseModalPress, content, showModal, title }) => {
  const styles = useStyles();

  return (
    <Dialog
      isVisible={showModal}
      onBackdropPress={handleCloseModalPress}
      overlayStyle={styles.dialog}
    >
      {title &&
        <Dialog.Title
          titleStyle={styles.titleText}
          title={title}/>
      }
      {content}
    </Dialog>
  );
};

export default DialogRenderer;
