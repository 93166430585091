import * as React from "react";
import { Circle, Path, Svg } from "react-native-svg";

const NoWifiWeb = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 550 550",
    }}
    viewBox="0 0 550 550"
    {...props}
  >
    <Path
      d="M3.9 275c0-13.4 5.4-25.6 14.2-34.4 8.8-8.8 20.9-14.2 34.4-14.2h11.9v97.2H52.5c-26.8 0-48.6-21.8-48.6-48.6zM29.1 80.5c0-13.4 5.4-25.6 14.2-34.4 8.8-8.8 20.9-14.2 34.4-14.2h372.8c26.9 0 48.6 21.8 48.6 48.6 0 13.4-5.4 25.6-14.2 34.4-8.8 8.8-21 14.2-34.4 14.2h-18.1c-5.9-4.6-13.4-7.3-21.5-7.3h-40.7V68.5c0-6.6-5.4-12-12-12H163.6c-6.6 0-12 5.4-12 12v53.3H99.5c-8.1 0-15.5 2.7-21.5 7.3h-.3c-26.9.1-48.6-21.7-48.6-48.6zm489.2 389c0 13.4-5.4 25.6-14.2 34.4-8.8 8.8-21 14.2-34.4 14.2H56.2c-26.8 0-48.6-21.8-48.6-48.6 0-13.4 5.4-25.6 14.2-34.4 8.8-8.8 20.9-14.2 34.4-14.2h24.2c13.4 0 25.6-5.4 34.4-14.2 2.4-2.4 4.6-5.1 6.5-8h82.4v75.5h-29.9c-2.8 0-5.1 2.3-5.1 5.1v13c0 2.8 2.3 5.1 5.1 5.1h174.1c2.8 0 5.1-2.3 5.1-5.1v-13c0-2.8-2.3-5.1-5.1-5.1H318v-75.5h87V427h-9c-5 0-9 4-9 9v52.2c0 5 4 9 9 9h66.1c5 0 9-4 9-9V436c0-5-4-9-9-9h-9.6v-6.2c.9 0 1.7.1 2.6.1h14.5c27-.1 48.7 21.7 48.7 48.6zM546.1 275c0 13.4-5.4 25.6-14.2 34.4-8.8 8.8-20.9 14.2-34.4 14.2h-42.3c-1 0-2.1 0-3.1.1v-34.2c0-4.9-2-9.2-5.1-12.4-.3-.3-.6-.6-1-.9v-49.8h51.5c26.8 0 48.6 21.8 48.6 48.6zm-94 72v-11.4c3.5-1.1 7.2-1.7 11.1-1.7 4.9 0 9.5.9 13.8 2.7V347c0 3.4-1.4 6.5-3.6 8.8-2.3 2.3-5.4 3.6-8.8 3.6-6.9.1-12.5-5.5-12.5-12.4zm48.2 24.1c0 20.5-16.6 37.1-37.1 37.1-3.7 0-7.2-.5-10.6-1.5v-5.9c0-4.8 2-9.2 5.1-12.4 3.2-3.2 7.6-5.1 12.4-5.1 14.1 0 25.8-10 28.5-23.3 1.1 3.5 1.7 7.2 1.7 11.1z"
      fill="#0A96A9"
      style={{
        opacity: 0.19,
      }}
    />
    <Path
      d="M400.9 377.5H99.5c-7.7 0-13.9-6.2-13.9-13.9V156.9c0-7.7 6.2-13.9 13.9-13.9h52.1v-21.1H99.5c-19.4 0-35 15.7-35 35v206.7c0 19.3 15.7 35 35 35H405v-10.3c0-4.1-1.5-7.9-4.1-10.8zM411 121.8h-40.7v21.1H411c7.7 0 13.9 6.2 13.9 13.9v118c2.8-1.8 6.1-2.9 9.7-2.9 4.4 0 8.4 1.6 11.5 4.3V156.9c-.1-19.4-15.8-35.1-35.1-35.1z"
      fill="#0A96A9"
    />
    <Path
      d="M424.9 156.9v118c-4.8 3.1-7.9 8.5-7.9 14.7V328h-19.3v-8c0-3.5-1.4-6.7-3.7-9-2.3-2.3-5.5-3.7-9-3.7-7 0-12.7 5.7-12.7 12.7v7.9H85.5v-171c0-7.7 6.2-13.9 13.9-13.9h52.1v143c0 6.6 5.4 12 12 12h146.3c8.6 6.6 19.4 10.5 31 10.5 25.3 0 46.3-18.4 50.5-42.5.5-2.8.7-5.7.7-8.7 0-17-8.3-32-21-41.3l-.9-.6v-72.3H411c7.6-.2 13.9 6.1 13.9 13.8z"
      fill="#FFFFFF"
    />
    <Path
      d="M406.7 400.2H99.5c-20.2 0-36.7-16.5-36.7-36.7V156.9c0-20.2 16.5-36.7 36.7-36.7h53.8v24.4H99.5c-6.8 0-12.3 5.5-12.3 12.3v206.7c0 6.8 5.5 12.3 12.3 12.3h302.2l.5.6c2.9 3.3 4.5 7.5 4.5 11.9v11.8zM99.5 123.5c-18.4 0-33.4 15-33.4 33.4v206.7c0 18.4 15 33.4 33.4 33.4h303.9v-8.6c0-3.4-1.1-6.6-3.2-9.2H99.5c-8.6 0-15.6-7-15.6-15.6V156.9c0-8.6 7-15.6 15.6-15.6H150v-17.8H99.5zm348.2 156.3-2.7-2.4c-2.9-2.5-6.6-3.9-10.4-3.9-3.1 0-6.2.9-8.7 2.6l-2.6 1.7v-121c0-6.8-5.5-12.3-12.3-12.3h-42.4v-24.4H411c20.2 0 36.7 16.5 36.7 36.7v123zm-13.2-9.5c3.5 0 6.9.9 9.8 2.7V156.9c0-18.4-15-33.4-33.4-33.4h-39.1v17.8H411c8.6 0 15.6 7 15.6 15.6V272c2.4-1.1 5.2-1.7 7.9-1.7z"/>
    <Path
      d="M417 327.9v6.9c0 2.7-1.1 5.1-2.8 6.8-1.7 1.7-4.1 2.8-6.8 2.8-5.3 0-9.6-4.3-9.6-9.6v-6.9H417zM400.9 377.5H99.5c-7.7 0-13.9-6.2-13.9-13.9V328h286.8v27.7c0 4.5 1.8 8.6 4.8 11.6 3 2.9 7 4.8 11.6 4.8 4.7-.1 9.1 2 12.1 5.4z"
      fill="#0A96A9"
    />
    <Path
      d="M407.3 346c-6.2 0-11.3-5.1-11.3-11.3v-8.5h22.6v8.5c0 3-1.2 5.9-3.3 8-2.1 2.2-4.9 3.3-8 3.3zm-7.9-16.5v5.2c0 4.4 3.6 8 8 8 2.1 0 4.1-.8 5.6-2.3 1.5-1.5 2.3-3.5 2.3-5.6v-5.2h-15.9zm5.2 49.6H99.5c-8.6 0-15.6-7-15.6-15.6v-37.3H374v29.4c0 3.9 1.5 7.6 4.3 10.4 2.8 2.8 6.5 4.3 10.4 4.3 5.2 0 10.1 2.2 13.5 6.1l2.4 2.7zM87.2 329.5v34c0 6.8 5.5 12.3 12.3 12.3h297c-2.3-1.4-5-2.2-7.7-2.2-4.8 0-9.3-1.9-12.7-5.3-3.4-3.4-5.3-7.9-5.3-12.7v-26.1H87.2z"/>
    <Circle cx={260.9} cy={350.4} r={14.7} fill="#FFFFFF"/>
    <Path
      d="M260.9 366.7c-9 0-16.3-7.3-16.3-16.3s7.3-16.3 16.3-16.3 16.3 7.3 16.3 16.3-7.3 16.3-16.3 16.3zm0-29.4c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13c0-7.1-5.8-13-13-13z"/>
    <Path
      d="M370.2 68.5v146.7c-8.3-5.8-18.4-9.3-29.4-9.3-20.8 0-38.8 12.4-46.8 30.3-2.9 6.4-4.5 13.5-4.5 20.9 0 3 .3 5.9.7 8.7 1.4 8 4.6 15.3 9.1 21.5 2.9 4 6.4 7.5 10.3 10.5h-146c-6.6 0-12-5.4-12-12V68.5c0-6.6 5.4-12 12-12h194.7c6.6 0 11.9 5.4 11.9 12z"
      fill="#FFFFFF"
    />
    <Path
      d="M314.7 299.6H163.6c-7.5 0-13.6-6.1-13.6-13.6V68.5c0-7.5 6.1-13.6 13.6-13.6h194.7c7.5 0 13.6 6.1 13.6 13.6v149.9l-2.6-1.8c-8.3-5.9-18.2-9-28.4-9-19.5 0-37.3 11.5-45.3 29.3-2.9 6.4-4.3 13.2-4.3 20.3 0 2.8.2 5.7.7 8.4 1.3 7.5 4.3 14.7 8.8 20.8 2.8 3.8 6.2 7.3 10 10.2l3.9 3zM163.6 58.2c-5.7 0-10.3 4.6-10.3 10.3V286c0 5.7 4.6 10.3 10.3 10.3h141.7c-2.6-2.4-5-5-7.1-7.9-4.8-6.6-8.1-14.2-9.4-22.2-.5-2.9-.8-6-.8-9 0-7.5 1.6-14.8 4.6-21.6 8.5-19 27.5-31.3 48.3-31.3 9.9 0 19.4 2.7 27.7 7.8V68.5c0-5.7-4.6-10.3-10.3-10.3H163.6z"
      fill="#0A96A9"
    />
    <Path
      d="M195.1 152.3c-.4-.1-.8-.4-1.1-.7-1-1-1-2.7.1-3.7 19.1-18.5 44.2-28.5 70.8-28.1s51.4 11.1 69.9 30.2c1 1 1 2.7-.1 3.7-1 1-2.7 1-3.7-.1-17.5-18.1-41.1-28.2-66.2-28.6-25.2-.4-49 9.1-67 26.6-.9.7-1.9.9-2.7.7z"
      fill="#E86B18"
    />
    <Path
      d="M217 171.7c-.4-.1-.8-.4-1.1-.7-1-1-1-2.7.1-3.7 13-12.7 30.2-19.5 48.4-19.2 18.2.3 35.2 7.6 47.8 20.6 1 1 1 2.7-.1 3.7-1 1-2.7 1-3.7-.1-11.7-12-27.4-18.8-44.1-19-16.8-.2-32.6 6.1-44.7 17.7-.7.8-1.7 1-2.6.7zm22.7 19.9c-.4-.1-.8-.4-1.1-.7-1-1-1-2.7.1-3.7 6.8-6.6 15.8-10.2 25.3-10 9.5.1 18.4 4 25 10.8 1 1 1 2.7-.1 3.7-1 1-2.7 1-3.7-.1-5.6-5.8-13.2-9.1-21.3-9.2-8.1-.1-15.8 2.9-21.6 8.6-.8.6-1.7.8-2.6.6z"
      fill="#E86B18"
    />
    <Circle cx={263.7} cy={209.7} r={12.4} fill="#E86B18"/>
    <Path
      d="m371.1 215.9-.9-.6c-8.3-5.8-18.4-9.3-29.4-9.3-20.8 0-38.8 12.4-46.8 30.3-2.9 6.4-4.5 13.5-4.5 20.9 0 3 .3 5.9.7 8.7 1.4 8 4.6 15.3 9.1 21.5 2.9 4 6.4 7.5 10.3 10.5 8.6 6.6 19.4 10.5 31 10.5 25.3 0 46.3-18.4 50.5-42.5.5-2.8.7-5.7.7-8.7.3-17-8-32-20.7-41.3zm-8.6 58.5-4.5 4.5c-1.5 1.5-3.9 1.5-5.4 0l-11.8-11.8-11.8 11.8c-1.5 1.5-3.9 1.5-5.4 0l-4.5-4.5c-.4-.4-.7-.8-.8-1.3-.4-.9-.4-2 0-2.9.2-.5.5-.9.8-1.3l11.8-11.8-11.8-11.8c-.1-.1-.2-.2-.2-.3-.2-.2-.3-.4-.4-.6 0 0 0-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3 0-.1 0-.2-.1-.3-.1-.6 0-1.2.3-1.8.2-.5.5-.9.8-1.3l4.5-4.5c1.5-1.5 3.9-1.5 5.4 0l11.8 11.8 11.8-11.8c1.5-1.5 3.9-1.5 5.4 0l4.5 4.5c1.5 1.5 1.5 3.9 0 5.4l-11.8 11.8 11.8 11.8c1.6 1.6 1.6 4 .1 5.5z"
      fill="#FFFFFF"
    />
    <Path
      d="M340.9 310.1c-11.7 0-22.8-3.7-32-10.8-4.1-3.1-7.6-6.7-10.6-10.8-4.8-6.6-8.1-14.2-9.4-22.2-.5-2.9-.8-6-.8-9 0-7.5 1.5-14.8 4.6-21.6 8.5-19 27.5-31.3 48.3-31.3 10.9 0 21.4 3.3 30.3 9.6l.9.6c13.6 9.9 21.7 25.9 21.7 42.7 0 3-.3 6-.8 9-4.5 25.3-26.4 43.8-52.2 43.8zm0-102.5c-19.5 0-37.3 11.5-45.3 29.3-2.9 6.4-4.3 13.2-4.3 20.3 0 2.8.2 5.7.7 8.4 1.3 7.5 4.3 14.7 8.8 20.8 2.8 3.8 6.2 7.3 10 10.2 8.7 6.6 19.1 10.1 30 10.1 24.2 0 44.8-17.3 48.8-41.1.5-2.8.7-5.6.7-8.4 0-15.7-7.6-30.7-20.3-40-.3-.2-.6-.4-.8-.6-8.3-5.9-18.1-9-28.3-9z"
      style={{
        fill: "#076f77",
      }}
    />
    <Path
      d="M355.3 281.6c-1.5 0-2.8-.6-3.9-1.6l-10.6-10.6-10.6 10.6c-1 1-2.4 1.6-3.9 1.6s-2.8-.6-3.9-1.6l-4.5-4.5c-.5-.5-.9-1.1-1.2-1.8-.5-1.3-.5-2.8 0-4.1.3-.7.7-1.3 1.2-1.8l10.6-10.6-10.5-10.6c-.1-.1-.2-.2-.3-.4-.2-.2-.4-.5-.6-.8 0-.1-.1-.1-.1-.1v-.1c0-.1-.1-.2-.1-.3l-.1-.2c-.1-.1-.1-.3-.1-.4 0-.1 0-.2-.1-.2l-.3-.5.2-.3c-.1-.8.1-1.7.4-2.5.3-.7.7-1.3 1.2-1.8l4.5-4.5c2.1-2.1 5.6-2.1 7.7 0l10.6 10.6 10.6-10.6c1-1 2.4-1.6 3.9-1.6s2.8.6 3.9 1.6l4.5 4.5c1 1 1.6 2.4 1.6 3.9s-.6 2.8-1.6 3.9l-10.6 10.6 10.6 10.6c1 1 1.6 2.4 1.6 3.9s-.6 2.8-1.6 3.9l-4.5 4.5c-1.1.8-2.5 1.3-4 1.3zm-14.4-16.9 12.9 12.9c.4.4 1 .6 1.5.6.6 0 1.1-.2 1.5-.6l4.5-4.5c.4-.4.6-1 .6-1.5s-.2-1.1-.6-1.5l-12.9-12.9 12.9-13c.4-.4.6-1 .6-1.5 0-.6-.2-1.1-.6-1.5l-4.5-4.5c-.4-.4-1-.6-1.5-.6-.6 0-1.1.2-1.5.6l-12.9 12.9-13-12.9c-.8-.8-2.2-.8-3.1 0l-4.5 4.5c-.2.2-.4.5-.5.7-.1.3-.2.7-.1 1v.2l.2.5v.1c.1.1.1.2.2.3v.1l.1.1 12.9 13-12.9 12.9c-.2.2-.4.5-.5.7-.2.5-.2 1.1 0 1.6.1.3.3.5.5.7l4.5 4.5c.8.8 2.2.8 3.1 0l13.1-12.9z"
      fill="#E86B18"
    />
    <Path
      d="m362.5 274.4-4.5 4.5c-1.5 1.5-3.9 1.5-5.4 0l-11.8-11.8-11.8 11.8c-1.5 1.5-3.9 1.5-5.4 0l-4.5-4.5c-1.5-1.5-1.5-3.9 0-5.4l11.8-11.8-11.8-11.8c-1.5-1.5-1.5-3.9 0-5.4l4.5-4.5c1.5-1.5 3.9-1.5 5.4 0l11.8 11.8 11.8-11.8c1.5-1.5 3.9-1.5 5.4 0l4.5 4.5c1.5 1.5 1.5 3.9 0 5.4l-11.8 11.8 11.8 11.8c1.5 1.5 1.5 3.9 0 5.4z"
      style={{
        opacity: 0.75,
        fill: "#e86b18",
        enableBackground: "new",
      }}
    />
    <Path d="M203.7 398.6h114.4v75.5H203.7z" fill="#0A96A9"/>
    <Path d="M319.7 475.7H202.1v-78.8h117.7v78.8zm-114.3-3.3h111.1v-72.2H205.4v72.2z"/>
    <Path
      d="M173.8 474.1h174.1c2.8 0 5.1 2.3 5.1 5.1v13c0 2.8-2.3 5.1-5.1 5.1H173.8c-2.8 0-5.1-2.3-5.1-5.1v-13c.1-2.9 2.4-5.1 5.1-5.1z"
      fill="#0A96A9"
    />
    <Path
      d="M347.9 498.8H173.8c-3.7 0-6.7-3-6.7-6.7v-13c0-3.7 3-6.7 6.7-6.7h174.1c3.7 0 6.7 3 6.7 6.7v13c.1 3.7-3 6.7-6.7 6.7zm-174.1-23.1c-1.9 0-3.4 1.5-3.4 3.4v13c0 1.9 1.5 3.4 3.4 3.4h174.1c1.9 0 3.4-1.5 3.4-3.4v-13c0-1.9-1.5-3.4-3.4-3.4H173.8z"/>
    <Path
      d="M471.1 436v52.2c0 5-4 9-9 9H396c-5 0-9-4-9-9V436c0-5 4-9 9-9h66.1c5 0 9 4 9 9z"
      fill="#0A96A9"
    />
    <Path
      d="M462.2 498.8H396c-5.9 0-10.6-4.8-10.6-10.6V436c0-5.9 4.8-10.6 10.6-10.6h66.1c5.9 0 10.6 4.8 10.6 10.6v52.2c.1 5.8-4.7 10.6-10.5 10.6zM396 428.7c-4 0-7.3 3.3-7.3 7.3v52.2c0 4 3.3 7.3 7.3 7.3h66.1c4 0 7.3-3.3 7.3-7.3V436c0-4-3.3-7.3-7.3-7.3H396z"
      fill="#09646B"
    />
    <Path
      d="M499.2 336.5v17.7c0 16-13 29-29 29-4.9 0-9.2 2-12.4 5.1-3.2 3.2-5.1 7.6-5.1 12.4V427H405v-38.7c0-9-7.3-16.3-16.4-16.3-4.5 0-8.6-1.8-11.6-4.8s-4.8-7.1-4.8-11.6V320c0-7 5.7-12.7 12.7-12.7 3.5 0 6.7 1.4 9 3.7 2.3 2.3 3.7 5.5 3.7 9v14.8c0 5.3 4.3 9.6 9.6 9.6 2.7 0 5.1-1.1 6.8-2.8 1.7-1.7 2.8-4.1 2.8-6.8v-45.3c0-9.7 7.9-17.6 17.6-17.6 4.9 0 9.2 2 12.4 5.1 3.2 3.2 5.1 7.6 5.1 12.4V347c0 6.9 5.6 12.4 12.4 12.4 3.4 0 6.6-1.4 8.8-3.6 2.2-2.2 3.6-5.4 3.6-8.8v-10.5c0-6.1 5-11.1 11.1-11.1 3.1 0 5.8 1.2 7.8 3.3 2.4 1.9 3.6 4.7 3.6 7.8z"
      fill="#FFFFFF"
    />
    <Path
      d="M454.3 428.6h-50.9v-40.3c0-8.1-6.6-14.7-14.7-14.7-4.8 0-9.3-1.9-12.7-5.3-3.4-3.4-5.3-7.9-5.3-12.7V320c0-7.9 6.4-14.3 14.3-14.3 3.8 0 7.4 1.5 10.1 4.2 2.7 2.7 4.2 6.3 4.2 10.1v14.8c0 4.4 3.6 8 8 8 2.1 0 4.1-.8 5.6-2.3 1.5-1.5 2.3-3.5 2.3-5.6v-45.3c0-10.6 8.6-19.2 19.2-19.2 5.1 0 10 2 13.6 5.6 3.6 3.6 5.6 8.5 5.6 13.6V347c0 6 4.8 10.8 10.8 10.8 2.9 0 5.6-1.1 7.6-3.2 2-2 3.2-4.8 3.2-7.6v-10.6c0-7 5.7-12.7 12.7-12.7 3.4 0 6.6 1.3 9 3.7 2.4 2.4 3.7 5.6 3.7 9v17.7c0 16.9-13.8 30.7-30.7 30.7-4.3 0-8.2 1.7-11.3 4.7-3 3-4.7 7-4.7 11.3l.4 27.8zm-47.6-3.3H451v-24.6c0-5.1 2-9.9 5.6-13.6 3.6-3.6 8.5-5.6 13.6-5.6 15.1 0 27.4-12.3 27.4-27.4v-17.7c0-2.5-1-4.9-2.8-6.7-1.8-1.8-4.2-2.8-6.7-2.8-5.2 0-9.4 4.2-9.4 9.4V347c0 3.8-1.5 7.3-4.1 10-2.7 2.7-6.2 4.1-10 4.1-7.8 0-14.1-6.3-14.1-14.1v-57.5c0-4.2-1.7-8.2-4.7-11.3-3-3-7-4.7-11.3-4.7-8.8 0-15.9 7.1-15.9 15.9v45.3c0 3-1.2 5.9-3.3 8-2.1 2.1-5 3.3-8 3.3-6.2 0-11.3-5.1-11.3-11.3V320c0-3-1.1-5.7-3.2-7.8-2.1-2.1-4.9-3.2-7.8-3.2-6.1 0-11 5-11 11v35.6c0 3.9 1.5 7.6 4.3 10.4 2.8 2.8 6.5 4.3 10.4 4.3 9.9 0 18 8.1 18 18v37z"
      fill="#09646B"
    />
    <Circle cx={425.8} cy={297.5} r={4.2} fill="#0A96A9"/>
    <Circle cx={436.5} cy={310.5} r={2.1} fill="#0A96A9"/>
    <Circle cx={438.5} cy={288.5} r={1.9} fill="#0A96A9"/>
    <Circle cx={422.1} cy={323.4} r={2.5} fill="#0A96A9"/>
    <Circle cx={436.8} cy={334} r={6.8} fill="#0A96A9"/>
    <Circle cx={443.9} cy={319.8} r={1.1} fill="#0A96A9"/>
    <Circle cx={421.1} cy={350.2} r={2} fill="#0A96A9"/>
    <Circle cx={436.4} cy={356.7} r={1.9} fill="#0A96A9"/>
    <Circle cx={388.4} cy={330.9} r={3.6} fill="#0A96A9"/>
    <Circle cx={395.6} cy={342.5} r={2.6} fill="#0A96A9"/>
    <Circle cx={385.1} cy={351.5} r={2.8} fill="#0A96A9"/>
    <Circle cx={400.4} cy={364.7} r={3.3} fill="#0A96A9"/>
    <Circle cx={401.8} cy={349.5} r={1.4} fill="#0A96A9"/>
    <Circle cx={416.9} cy={382} r={4.1} fill="#0A96A9"/>
    <Circle cx={424.1} cy={401.6} r={3.1} fill="#0A96A9"/>
    <Circle cx={413.5} cy={406.6} r={2} fill="#0A96A9"/>
    <Circle cx={420.2} cy={418.4} r={1.1} fill="#0A96A9"/>
    <Circle cx={429.3} cy={369.4} r={2.1} fill="#0A96A9"/>
    <Circle cx={445} cy={393.8} r={5.8} fill="#0A96A9"/>
    <Circle cx={455.3} cy={381.5} r={1.2} fill="#0A96A9"/>
    <Circle cx={452.9} cy={363.5} r={2.1} fill="#0A96A9"/>
    <Circle cx={433} cy={419.4} r={1.5} fill="#0A96A9"/>
    <Circle cx={469.8} cy={370.5} r={2.5} fill="#0A96A9"/>
    <Circle cx={484.7} cy={368} r={4.5} fill="#0A96A9"/>
    <Circle cx={488.6} cy={345} r={2.5} fill="#0A96A9"/>
    <Path
      d="m160.9 441-2.7 2.7c-.9.9-2.3.9-3.2 0l-7-7-7 7c-.9.9-2.3.9-3.2 0l-2.7-2.7c-.9-.9-.9-2.3 0-3.2l7-7-7-7c-.9-.9-.9-2.3 0-3.2l2.7-2.7c.9-.9 2.3-.9 3.2 0l7 7 7-7c.9-.9 2.3-.9 3.2 0l2.7 2.7c.9.9.9 2.3 0 3.2l-7 7 7 7c.8.9.8 2.3 0 3.2zM404.8 97.2l-1.6 1.6c-.5.5-1.4.5-1.9 0l-4.2-4.2-4.2 4.2c-.5.5-1.4.5-1.9 0l-1.6-1.6c-.5-.5-.5-1.4 0-1.9l4.2-4.2-4.2-4.2c-.5-.5-.5-1.4 0-1.9l1.6-1.6c.5-.5 1.4-.5 1.9 0l4.2 4.2 4.2-4.2c.5-.5 1.4-.5 1.9 0l1.6 1.6c.5.5.5 1.4 0 1.9l-4.2 4.2 4.2 4.2c.5.5.5 1.4 0 1.9zm-279.9 8-1.6 1.6c-.5.5-1.4.5-1.9 0l-4.2-4.2-4.2 4.2c-.5.5-1.4.5-1.9 0l-1.6-1.6c-.5-.5-.5-1.4 0-1.9l4.2-4.2-4.2-4.2c-.5-.5-.5-1.4 0-1.9l1.6-1.6c.5-.5 1.4-.5 1.9 0l4.2 4.2 4.2-4.2c.5-.5 1.4-.5 1.9 0l1.6 1.6c.5.5.5 1.4 0 1.9l-4.2 4.2 4.2 4.2c.5.6.5 1.4 0 1.9z"
      fill="#E86B18"
      style={{
        opacity: 0.75,
        enableBackground: "new",
      }}
    />
  </Svg>
);
export default NoWifiWeb;
