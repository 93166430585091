import Server from "./server";
import { ROOT } from "constants/ROOT";

const usersBaseUrl = "api/v1/users";

//********************** TYPE **********************//

/**
 * Custom data type defining a User.
 * @typedef {Object} User - Represents client credentials for authentication.
 * @property {string} client_id - The client identifier.
 * @property {string} username - The username for authentication.
 * @property {string} password - The password for authentication.
 * @property {string} grant_type - The type of grant for authentication.
 */

//********************** API **********************//

/**
 * @description - The userApi object encapsulates functionality related to users.
 * @type {Object}
 */

const userApi = {
  /**
   * This function get info of authenticated user.
   * @returns {Promise<User>} - The current user info
   * @final
   */
  getProfile: async () => {
    try {
      const url = `auth/realms/master/protocol/openid-connect/userinfo`;
      return await Server.get(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      console.log("===ERROR API :: get profile===", error);
    }
  },

  /**
   * This function gets the token of an authenticated user.
   * @param {User} payload - The payload object containing user credentials.
   * @param {string} payload.client_id - The client identifier.
   * @param {string} payload.username - The username for authentication.
   * @param {string} payload.password - The password for authentication.
   * @param {string} payload.grant_type - The type of grant for authentication.
   * @returns {Promise<string>} - The token of the authenticated user.
   * @final
   */
  getToken: async ({ payload }) => {
    try {
      const url = `auth/realms/master/protocol/openid-connect/token`;
      console.info(`Preparing API request to ${url} with payload:`, payload);
      return await Server.post(url, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (e) {
      throw new Error(e?.response?.data?.error_description || ROOT.LOGIN_ERROR);
    }
  },

  refreshToken: async ({ payload }) => {
    try {
      const url = `auth/realms/master/protocol/openid-connect/token`;
      return await Server.post(url, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  getProfileWithOpenId: ({ payload = {} }) => {
    const url = `auth/realms/master/protocol/openid-connect/token`;
    const result = Server.get(url, payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return result;
  },

  /**
   * removes a user's push token from backend server
   */
  removeExpoPushToken: async () => {
    try {
      const url = "api/v1/users/expo/log-out";
      const result = await Server.get(url);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  },

  /**
   * Calls the revoke token API of keycloak open-id to invalidate a token.
   * @param {object} payload - Object containing token and client_id
   * @param {string} payload.token - The access token or refresh token
   * @param {string} payload.client_id - The client identifier for keycloak
   * @returns {import("axios").AxiosPromise<any>} - Axios response
   */
  revokeToken: async (payload) => {
    try {
      const url = `auth/realms/master/protocol/openid-connect/revoke`;
      return await Server.post(url, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      console.log("===ERROR API :: refresh token===", error);
    }
  },

  interospectToken: async (payload) => {
    try {
      const url = `auth/realms/master/protocol/openid-connect/token/introspect`;
      return await Server.post(url, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      console.log("===ERROR API :: introspect token===", error);
    }
  },

  getPlanSubscriptions: async () => {
    try {
      const url = `${usersBaseUrl}/plan-subscriptions`;
      const result = await Server.get(url);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  },

  getUserInfo: async () => {
    try {
      const url = `${usersBaseUrl}`;
      const result = await Server.get(url);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  },
};

export default userApi;
