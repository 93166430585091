import * as React from "react";
import { View, Text } from "react-native";
import useStyles from "./NotificationsStyles";

function Notifications() {
  const styles = useStyles()
  return (
    <View style = {styles.container}>
      {/* <Text>Notifications</Text> */}
    </View>
  );
}

export default Notifications;
