import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "@rneui/themed";
import { Platform, useWindowDimensions, View } from "react-native";
import { SCREENS_NAMES } from "constants/screens";
import { AppThemeContext } from "App";
import ChartActionsHeader from "Screens/ViewChart/components/ChartActionsHeader";
import ChartHeader from "Screens/ViewChart/components/ChartHeader";
import { ChartLoaderContext } from "context/chartLoader/ChartLoaderContext";
import { hasSeenOnBoarding } from "api/onBoarding";
import * as SplashScreen from "expo-splash-screen";
import { ON_BOARDING_SCREENS, SCREENS } from "Screens/index";
import { useQuery } from "react-query";
import ScreenContainer from "components/ScreenContainer";
import GlowingBackgroundGradient from "components/GlowingBackgroundGradient";
import { BlurView } from "expo-blur";
import { useNotifications } from "hooks/useNotifications";

const Stack = createNativeStackNavigator();

/**
 * Navigation component that manages screen transitions and configurations.
 */
const Navigation = memo(() => {
  const { isSplashScreenHidden } = useContext(ChartLoaderContext);
  const { updateTheme, theme } = useTheme();
  const { theme: appTheme } = useContext(AppThemeContext);
  const { width } = useWindowDimensions();

  useEffect(() => {
    updateTheme(appTheme);
  }, [appTheme]);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  useNotifications({ navigation });
  const [showOnBoarding, setShowOnBoarding] = useState(null);
  const { data: seenOnboardingData } = useQuery(
    "seenOnboarding",
    hasSeenOnBoarding,
    {
      refetchInterval: showOnBoarding ? 500 : -1,
    }
  );

  useEffect(() => {
    const checkOnBoarding = async () => {
      if (Platform.OS == "web") {
        setShowOnBoarding(false);
        return;
      }
      if (seenOnboardingData) {
        setShowOnBoarding(false);
      } else {
        setShowOnBoarding(true);
      }
    };
    checkOnBoarding();
  }, [seenOnboardingData]);

  useEffect(() => {
    const checkHidingSplashScreen = async () => {
      try {
        if (isSplashScreenHidden && showOnBoarding !== null) {
          await SplashScreen.hideAsync();
        }
      } catch (e) {
        console.log("eeee", e);
      }
    };
    checkHidingSplashScreen();
  }, [showOnBoarding, isSplashScreenHidden]);

  useEffect(() => {
    if (showOnBoarding === false && Platform.OS !== "web") {
      navigation.navigate(SCREENS_NAMES.HOME);
    }
  }, [showOnBoarding]);

  /**
   * Determines screen options based on the screen name.
   * @param {string} screenName - The name of the screen.
   * @param {Object} args - Additional arguments for the screen.
   * @returns {Object} Screen options.
   */
  const getScreenOptions = (screenName, args) => {
    switch (screenName) {
      case SCREENS_NAMES.ON_BOARDING:
        return {
          headerShown: false,
          contentStyle: {
            backgroundColor: theme.colors.background,
            paddingTop: insets.top,
          },
        };
      case SCREENS_NAMES.HOME:
        return {
          headerShown: false,
          contentStyle: {
            backgroundColor: theme.colors.background,
            paddingTop: insets.top,
          },
        };
      case SCREENS_NAMES.VIEW_CHART:
        return {
          headerTitle: (params) => <ChartHeader params={params} />,
          headerRight: () => (
            <ChartActionsHeader
              route={args.route}
              navigation={args.navigation}
            />
          ),
        };
      case SCREENS_NAMES.EMAIL_VERIFICATION:
        return {
          headerShown: false,
          contentStyle: {
            backgroundColor: theme.colors.background,
          },
        };
      case SCREENS_NAMES.NOT_FOUND:
        return {
          headerShown: false,
          headerBackVisible: false
        };
      default:
        return {
          headerShown: true,
          headerTitleStyle: {
            color: theme.colors.primary,
          },
        };
    }
  };

  const displayedScreens = useMemo(() => {
    return showOnBoarding ? ON_BOARDING_SCREENS : SCREENS;
  }, [showOnBoarding]);

  const pageMargin = 24;

  return (
    <View style={{ flex: 1, overflow: "hidden" }}>
      {!showOnBoarding && Platform.OS === "web" && (
        <GlowingBackgroundGradient />
      )}
      <BlurView
        style={{
          flex: 1,
          margin:
            Platform.OS === "web" && width >= theme.breakpoints.drawer
              ? pageMargin
              : 0,
          overflow: "hidden",
          borderRadius:
            Platform.OS === "web" && width >= theme.breakpoints.drawer ? 24 : 0,
        }}
        intensity={50}
        tint={theme.mode}
      >
        <ScreenContainer>
          <Stack.Navigator
            screenOptions={{
              contentStyle: {
                backgroundColor: theme.colors.background,
              },

              headerShadowVisible: false,
              headerStyle: {
                maxHeight: Platform.OS !== "web" ? 0 : undefined,
                ...(Platform.OS === "web"
                  ? { backgroundColor: "rgba(0, 0, 0, 0)" }
                  : {}),
              },
            }}
          >
            {displayedScreens.map((screen) => (
              <Stack.Screen
                key={screen.name}
                name={screen.name}
                component={screen.component}
                options={(args) => {
                  return {
                    title: screen.title,
                    ...getScreenOptions(screen.name, args),
                  };
                }}
              />
            ))}
          </Stack.Navigator>
        </ScreenContainer>
      </BlurView>
    </View>
  );
});

export default Navigation;
