import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllStrategies, getAllStyles, getStrategyDescriptions } from "api/strategy";
import { useUserContext } from "context/user/UserProvider";

//********************** TYPE **********************//

/**
 * Custom data type defining a Strategy.
 * @typedef {Object} Strategy
 * @property {number} id - The ID of the strategy.
 * @property {string} strategy_id - The ID of the strategy associated with the strategy.
 * @property {string} name - The name of the strategy.
 * @property {string} provider_id - The ID of the provider associated with the strategy.
 * @property {number} rating - The rating of the strategy.
 * @property {string[]} time_frames - The time frames for which the strategy is applicable.
 * @property {string[]} positions - The positions associated with the strategy.
 * @property {string[]} events - The events associated with the strategy.
 * @property {string} date - The date when the strategy was recorded.
 */

//********************** FUNCTION **********************//

/**
 * @description Custom hook to fetch strategies data from server. That is used for all section.
 * To implement fetching strategies, utilize the powerful combination of react-query and axios.
 * @returns {Object} An object containing the fetched strategies data and a helper function.
 * @property {Strategy[]} strategies - The fetched strategies data.
 * @property {Function} getStrategyNameById - A helper function to get the name of a strategy by its ID.
 * @throws {Error} Throws an error if there is an issue fetching the data.
 */

const useStrategies = () => {
  const { token } = useUserContext();
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useQuery([`getAllStrategies${JSON.stringify(token)}`], getAllStrategies);

  const {
    data: allStylesData,
    isLoading: allStylesIsLoading,
    isSuccess: allStylesIsSuccess,
  } = useQuery([`getAllStyles${JSON.stringify(token)}`], getAllStyles, {
    select: (response) => response?.data,
  });

  const {
    data: strategyDescriptions,
    isLoading: strategyDescriptionsIsLoading,
    isSuccess: strategyDescriptionsIsSuccess,
  } = useQuery([`getStrategyDescriptions`], getStrategyDescriptions, {
    select: (response) => response?.data,
  });

  let strategies = data?.data || [];
  let strategyDescriptionsData = strategyDescriptions?.data || [];

  /**
   * Retrieves the name of a strategy by its ID.
   * @param {number} strategy_id - The ID of the strategy.
   * @returns {string} The name of the strategy with the specified ID, or an empty string if no strategy is found.
   */
  const getStrategyNameById = useCallback(
    (strategy_id) => {
      let strategy = strategies.filter(
        (item) => item.strategy_id == strategy_id
      );
      return strategy[0]?.name || "";
    },
    [JSON.stringify(strategies)]
  );

  /**
   * Augments an array of strategies with their corresponding description attributes.
   * @param {Array} strategies - The array of strategies to augment.
   * @param {Array} strategyDescriptions - The array of strategy descriptions.
   * @returns {Array} A new array of strategies with augmented description attributes.
   */
  const getAugmentStrategiesWithDescriptions = (strategies, strategyDescriptions) => {
    return strategies.reduce((acc, strategy) => {
      const description = strategyDescriptions.find(description => description.attributes?.strategy_id === strategy.strategy_id);
      acc.push({
        ...strategy,
        attributes: description?.attributes || {},
      });
      return acc;
    }, []);
  };

  return {
    strategies: data?.data || [],
    getStrategyNameById,
    isLoading,
    isSuccess,
    allStyles: allStylesData,
    allStylesIsLoading,
    allStylesIsSuccess,
    strategyDescriptions: strategyDescriptions?.data || [],
    strategyDescriptionsIsLoading,
    strategyDescriptionsIsSuccess,
    getAugmentStrategiesWithDescriptions,
  };
};

export default useStrategies;
