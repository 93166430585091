import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { Button, Text } from "@rneui/themed";
import { LinearGradient } from "expo-linear-gradient";
import Icon from "components/UI/Icon";
import useStyles from "./styles";

/**
 * Component for rendering an LinearGradient expo Button.
 * @component
 * @param {Object} props - The component props.
 * @param {String} props.title - The title to be displayed.
 * @param {String[]} props.colors - The colors of button.
 * @param {number} props.start - The start to be displayed.
 * @param {number} props.end - The end to be displayed.
 * @param {Object} props.wrapperStyles - The wrapperStyles to be displayed.
 * @param {Object} props.linearGradientStyles - The linearGradientStyles to be displayed.
 * @param {String} props.description - The description to be displayed.
 * @returns {React.JSX} - The rendered component.
 */

const LinearGradientButton = ({
                                title,
                                colors,
                                start,
                                end,
                                wrapperStyles,
                                linearGradientStyles,
                                description = "",
                                showIcon,
                                onPress
                              }) => {
  const styles = useStyles();
  return (
    <View style={{ ...styles.container, ...wrapperStyles }}>
      <LinearGradient
        colors={colors}
        start={start}
        end={end}
        style={{ ...styles.gradient, ...linearGradientStyles }}
      >
        <Button
          buttonStyle={styles.buttonStyle}
          titleStyle={styles.buttonText}
          containerStyle={styles.buttonContainer}
          onPress={onPress}
        >
          {showIcon && <Icon
            name="crown-outline"
            type="material-community"
            iconStyle={styles.iconStyle}
            size={30}
            isClickable={false}
          />}
          <View>
            <Text style={styles.buttonText}>{title}</Text>
            {description && <Text style={styles.desText}>{description}</Text>}
          </View>
          {showIcon && <Icon
            name="crown-outline"
            type="material-community"
            iconStyle={styles.iconStyle}
            size={30}
            isClickable={false}
          />}
        </Button>
      </LinearGradient>
    </View>
  );
};

export default LinearGradientButton;

LinearGradientButton.propTypes = {
  title: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  start: PropTypes.object,
  end: PropTypes.object,
  wrapperStyles: PropTypes.object,
  linearGradientStyles: PropTypes.object,
  description: PropTypes.string,
  showIcon: PropTypes.bool,
  onPress: PropTypes.func,
};

LinearGradientButton.defaultProps = {
  title: "Go Premium",
  colors: ["#FF9800", "#F44336"],
  start: null,
  end: null,
  wrapperStyles: {},
  linearGradientStyles: {},
  description: "",
  showIcon: false,
};
