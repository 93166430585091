import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const PremiumIcon = ({ color, ...rest }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 500 500"
    {...rest}
  >
    <G transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
       fill={color} stroke="none">
      <Path d="M2465 4289 c-38 -46 -65 -104 -65 -140 0 -23 11 -44 40 -75 39 -43
40 -45 40 -116 -1 -204 -88 -389 -205 -434 -61 -23 -154 -15 -221 19 -100 51
-206 169 -274 305 -34 69 -36 83 -16 140 14 38 13 43 -7 74 -25 37 -117 110
-132 105 -18 -6 -29 -113 -17 -156 10 -32 23 -45 69 -73 52 -30 61 -41 85 -98
53 -129 54 -279 1 -354 -64 -90 -181 -112 -301 -56 -122 57 -292 201 -292 248
0 25 -35 56 -90 81 -66 30 -74 28 -51 -16 28 -55 66 -92 101 -99 95 -20 208
-198 256 -407 l18 -77 1094 2 1094 3 24 95 c48 189 154 354 246 382 42 12 77
48 109 109 l19 39 -50 -20 c-78 -31 -91 -42 -115 -96 -34 -75 -176 -193 -300
-250 -70 -32 -156 -34 -213 -5 -119 61 -152 208 -88 392 28 80 45 103 98 130
59 30 71 56 66 143 -2 41 -7 78 -11 82 -11 12 -115 -65 -138 -103 -19 -31 -21
-40 -11 -67 25 -69 11 -123 -64 -238 -57 -89 -151 -180 -220 -214 -67 -33
-146 -42 -211 -23 -121 33 -213 215 -217 425 l-1 81 43 46 c48 53 52 78 21
140 -26 49 -68 107 -79 107 -5 0 -20 -14 -35 -31z"/>
      <Path d="M1388 2948 c-34 -28 -428 -547 -428 -565 0 -10 326 -382 758 -863
594 -662 763 -846 784 -848 23 -3 109 90 784 843 418 466 760 855 762 865 2
12 -69 111 -194 272 -108 139 -209 265 -222 281 l-26 27 -1101 0 c-866 0
-1104 -3 -1117 -12z m181 -320 c83 -98 151 -180 151 -183 0 -3 -135 -5 -300
-5 -165 0 -300 2 -300 5 0 11 283 366 290 363 4 -2 75 -83 159 -180z m1187 0
l245 -183 -245 -3 c-134 -1 -358 -1 -498 0 l-252 3 244 182 c135 100 249 182
253 183 5 0 118 -82 253 -182z m986 10 c74 -95 138 -179 142 -185 6 -10 -57
-13 -298 -13 l-306 0 156 185 c86 102 160 185 165 185 4 0 68 -78 141 -172z
m-652 -300 c0 -17 -580 -1393 -587 -1392 -6 2 -557 1309 -579 1377 -5 16 27
17 580 17 322 0 586 -1 586 -2z"/>
    </G>
  </Svg>
)
export default PremiumIcon
