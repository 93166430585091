import Server from "./server";

const baseUrl = "api/v1/configurations";

//********************** TYPE **********************//

/**
 * Custom data type defining a Market.
 * @typedef {Object} Market
 * @property {number} id - The ID of the market.
 * @property {string} provider_id - The ID of the provider associated with the market.
 * @property {string} market - The name of the market.
 * @property {number} rank_id - The ranking of the market.
 */

/**
 * Represents a list of time frames.
 * @typedef {string[]} TimeFrames
 */

/**
 * Represents a list of events.
 * @typedef {string[]} Events
 */

/**
 * Custom data type defining a Configuration.
 * @type {Configuration}
 * @typedef {Array.<Market[], TimeFrames, Events>} Configuration
 */

//********************** API **********************//

/**
 * @description The configurationsApi object encapsulates functionality related to configurations.
 * @type {Object}
 */
const configurationsApi = {
  /**
   * Retrieves a Configuration from the server.
   * @returns {Promise<Configuration>} - The retrieved configuration.
   * @final
   */

  getConfigurationsApi: async () => {
    try {
      return await Server.get(baseUrl);
    } catch (error) {
      console.log("===ERROR API :: getConfigurationsApi===", error);
    }
  },
};

export default configurationsApi;
