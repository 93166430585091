import AsyncStorage from "@react-native-async-storage/async-storage";

export const mainOnboaringStorageKey = "introOnBoarding_v1";

export const hasSeenOnBoarding = async () => {
  const res = await AsyncStorage.getItem(mainOnboaringStorageKey);
  if (!res) return false;
  else return true;
};

export const setHasSeenOnboarding = async () => {
  return await AsyncStorage.setItem(mainOnboaringStorageKey, "true");
};
