import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const ProIcon = ({ color, ...rest }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 500 500"
    {...rest}
  >
    <G transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
       fill={color} stroke="none">
      <Path d="M1388 2948 c-34 -28 -428 -547 -428 -565 0 -10 326 -382 758 -863
594 -662 763 -846 784 -848 23 -3 109 90 784 843 418 466 760 855 762 865 2
12 -69 111 -194 272 -108 139 -209 265 -222 281 l-26 27 -1101 0 c-866 0
-1104 -3 -1117 -12z m181 -320 c83 -98 151 -180 151 -183 0 -3 -135 -5 -300
-5 -165 0 -300 2 -300 5 0 11 283 366 290 363 4 -2 75 -83 159 -180z m1187 0
l245 -183 -245 -3 c-134 -1 -358 -1 -498 0 l-252 3 244 182 c135 100 249 182
253 183 5 0 118 -82 253 -182z m986 10 c74 -95 138 -179 142 -185 6 -10 -57
-13 -298 -13 l-306 0 156 185 c86 102 160 185 165 185 4 0 68 -78 141 -172z
m-652 -300 c0 -17 -580 -1393 -587 -1392 -6 2 -557 1309 -579 1377 -5 16 27
17 580 17 322 0 586 -1 586 -2z"/>
    </G>
  </Svg>
)
export default ProIcon
