import { Platform } from "react-native";

export const elementsThemeColors = {
  background: Platform.OS === "web" ? "transparent" : "#E6ECF2", // very light gray
  primary: "#0099AD", // green
  secondary: "#EE6C4D", // light orange
  error: "#ab3239",
  success: "#6acf65",
  orange: "#DF803C",

  // TODO
  red: "#ab3239",
  green: "#318e43",

  // TODO Gray
  lightGray: "#9298A1", // gray0
  middleGray: "#86939e",
  darkGray: "#43484d",

  // Blue
  blue: "#2a9df4",
  darkBlue: "#187bcd",

  black: "#000000",
  whiteText: "#000000",
  long: "#25c4a9",
  short: "#c42532",
  body: "transparent",
};

export const elementsThemeColorsDark = {
  background: Platform.OS === "web" ? "transparent" : "#000000",
  primary: "#FFFFFF", // green
  secondary: "#EE6C4D", // light orange
  error: "#ab3239",
  success: "#6acf65",
  orange: "#DF803C",

  // TODO
  red: "#ab3239",
  green: "#318e43",

  // TODO Gray
  lightGray: "#9298A1", // gray0
  middleGray: "#86939e",
  darkGray: "#43484d",

  // Blue
  blue: "#2a9df4",
  darkBlue: "#187bcd",
  whiteText: "#FFFFFF",
  long: "#25c4a9",
  short: "#c42532",
  body: "transparent",
};

export const elementstheme = {
  // mode: "light",
  lightColors: elementsThemeColors,
  darkColors: elementsThemeColorsDark,
  components: {
    Tab: {
      variant: "default",
    },
    TabViewItem: {
      style: {
        flex: 1,
      },
    },
  },
  breakpoints: {
    mobile: 0,
    tablet: 640,
    drawer: 970,
    laptop: 1024,
    desktop: 1200,
  },
};
