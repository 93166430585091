import { View } from 'react-native'
import React from 'react'
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ForgotPasswordContainer from "containers/ForgotPassword";

const ForgotPassword = () => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
      }}
    >
      <ForgotPasswordContainer/>
    </View>
  );
};

export default ForgotPassword