import { makeStyles } from "@rneui/themed";

export const useStyles = makeStyles((theme) => ({
  skeletonCardWrapper: {
    height: 96,
    backgroundColor: "transparent",
    borderRadius: 12,
    padding: 12,
  },
  skeletonHeaderWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    height: 30,
  },
  skeletonMarketTitle: {
    borderRadius: 8,
    height: "100%",
  },
  skeletonBodyWrapper: {
    flex: 1,
    width: "100%",
    marginTop: 24,
  },
  skeletonBody: {
    width: "100%",
    borderRadius: 10,
    flex: 1,
  },
  skeletonFooterWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 15,
    marginTop: 6,
  },
}));
