import { View } from 'react-native'
import React from 'react'
import { useSafeAreaInsets } from "react-native-safe-area-context";
import EmailVerificationContainer from "containers/EmailVerification";

const EmailVerification = ({route}) => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
      }}
    >
      <EmailVerificationContainer route={route}/>
    </View>
  );
};

export default EmailVerification