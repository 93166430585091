import {useMutation, useQueryClient} from "react-query";
import Toast from "react-native-toast-message";
import alertsApi from "api/alerts";
import {useUserContext} from "context/user/UserProvider";
import {useState} from "react";

/**
 * Hook for handling active alert operations.
 * @returns {Object} - The active alert hook object.
 * @property {Function} onRemove - A helper function for remove active alert by its ID.
 */

const useActiveAlert = () => {
  const queryClient = useQueryClient();
  const {token} = useUserContext();
  const [loading, setLoading] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [alertToRemove, setAlertToRemove] = useState(null);

  /**
   * Mutation function for removing an active alert by ID.
   * @param {string} id - The ID of the alert to remove.
   */
  const {mutate: onRemove} = useMutation({
    mutationFn: async (id) => {
      setLoading(prevLoading => [...prevLoading, id]);
      await alertsApi.removeActiveAlertById({params: {id}});
    },
    onSuccess: async (data, id) => {
      await queryClient.invalidateQueries({
        queryKey: [`getAllActiveAlerts${token}`],
      });
      setLoading(prevLoading => prevLoading.filter(item => item !== id));
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully removed active alert.",
      });
    },
    onError: (error, id) => {
      setLoading(prevLoading => prevLoading.filter(item => item !== id));
      Toast.show({
        type: "error",
        position: "top",
        text1: "There is a problem.",
      });
    },
  });

  const showConfirmDialog = (alert) => {
    setAlertToRemove(alert);
    setIsDialogVisible(true);
  };

  const handleConfirm = () => {
    onRemove(alertToRemove.id);
    setIsDialogVisible(false);
  };

  const handleCancel = () => {
    setIsDialogVisible(false);
  };

  return {
    isDialogVisible,
    handleCancel,
    handleConfirm,
    loading,
    onRemove,
    showConfirmDialog,
    token,
  };
};

export default useActiveAlert;

