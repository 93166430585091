import React, { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const StrategiesContainer = lazy(() => import("../../containers/Strategies"));

const Strategies = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <StrategiesContainer />
    </Suspense>
  );
};

export default Strategies;
