import * as React from "react"
import Svg, {
  Path,
  Ellipse,
  LinearGradient,
  Stop,
  Circle,
  G,
} from "react-native-svg"

const NotFound = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="SvgjsSvg1001"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 550 550",
    }}
    viewBox="0 0 550 550"
    {...props}
  >
    <Path
      d="M286 492.1c-102.7-44-150.8 22.8-200.8-26.4-50.6-49.8-15-113.2-36-187.7C-33-12.9 203.2 116.8 244.6 88.3 410.9-25.9 539 48.2 515.2 148.2c-9.9 41.6-42.3 91.7-21.2 144.7 47 118.6-81 253.6-208 199.2z"
      style={{
        opacity: 0.19,
        fill: "#0a96a9",
        enableBackground: "new",
      }}
    />
    <Ellipse
      cx={275}
      cy={452.4}
      rx={182.9}
      ry={53}
      style={{
        fill: "#a3e0e2",
      }}
    />
    <Ellipse
      cx={275}
      cy={451}
      rx={172}
      ry={41.2}
      style={{
        fill: "#6eb2b7",
      }}
    />
    <Ellipse
      cx={275}
      cy={445.8}
      rx={155.2}
      ry={31}
      style={{
        fill: "#3a7b7c",
      }}
    />
    <Path
      d="M412.7 420.5v16.7c0 18.6-61.6 33.8-137.7 33.8s-137.7-15-137.7-33.8v-16.7c0 18.8 61.6 33.8 137.7 33.8s137.7-15.1 137.7-33.8z"
      fill="#0A96A9"
    />
    <Path
      d="M412.7 420.5c0 18.8-61.6 33.8-137.7 33.8-38.7 0-73.6-4-98.6-10.3-12.5-3.2-22.5-6.9-29.3-11.1-6.3-4-9.9-8.2-9.9-12.6s3.5-8.8 9.9-12.6c4.4-2.6 9.3-4.8 14.2-6.5l-4.9 16.8c-.4.7-.6 1.5-.7 2.3 0 4.6 7.7 8.8 20.6 12.2 21.5 5.8 57.7 9.5 98.6 9.5 58.7 0 107.4-7.8 117.4-17.9 1.1-1 1.7-2.3 1.9-3.8 0-.4 0-.7-.1-1.1 0-.1-.1-.2-.1-.4-.1-.2-.1-.4-.2-.6l-.1-.2-4.9-16.8c15.1 5.6 23.9 12.2 23.9 19.3z"
      fill="#FFFFFF"
    />
    <Path
      d="m339.6 232.8-2.2.5c-17.2 3.5-39.3 5.3-62.4 5.3s-45.1-1.9-62.2-5.3l-2.2-.5.9-2.8 15.1-51.7c0-7.8 21.7-14.1 48.7-14.1s48.7 6.3 48.7 14.1l15.1 51.7.5 2.8zM360.3 304.1c-4.1 1.2-9.1 2.5-15.3 3.6-19.4 3.7-44.3 5.8-70 5.8-25.7 0-50.6-2-70-5.8-6.2-1.2-11.1-2.3-15.3-3.6l14.6-50.1 1.9-6.3c18.6 4.1 43.1 6.3 68.9 6.3s50.3-2.2 68.9-6.3l1.9 6.3 14.4 50.1zM394.3 420.5c-.1 1.5-.7 2.8-1.9 3.8-10 10.1-58.8 17.9-117.4 17.9-41 0-77.2-3.8-98.6-9.5-13-3.5-20.6-7.7-20.6-12.2 0-.9.2-1.6.6-2.3l4.9-16.8 15.1-51.6 7.2-24.7c3.5 1 6.9 1.9 10.3 2.6 21.9 4.9 50.6 7.7 81.1 7.7 30.5 0 59.4-2.7 81.3-7.7 3.2-.7 6.8-1.6 10.1-2.6l22.2 76.3 4.9 16.8.1.2c.1.2.1.4.2.6 0 .1.1.2.1.4.3.4.4.7.4 1.1z"
      fill="#0A96A9"
    />
    <Path
      d="M366.5 325.1c-3.5 1-6.9 1.9-10.1 2.6-21.9 4.9-50.6 7.7-81.3 7.7s-59.4-2.7-81.1-7.7c-3.2-.7-6.8-1.6-10.3-2.6l6.1-21c4.1 1.2 9.1 2.5 15.3 3.6 19.4 3.7 44.3 5.8 70 5.8s50.6-2 70-5.8c6.2-1.2 11.1-2.3 15.3-3.6l6.1 21zM343.9 247.6c-18.6 4.1-43.1 6.3-68.9 6.3s-50.3-2.2-68.9-6.3l4.3-14.8 2.2.5c17.2 3.5 39.3 5.3 62.2 5.3 23 0 45.2-1.9 62.4-5.3l2.2-.5 4.5 14.8z"
      fill="#FFFFFF"
    />
    <Path
      d="M315.5 178.2c0 5.1-18.2 9.1-40.4 9.1s-40.4-4.1-40.4-9.1 18.2-9.1 40.4-9.1 40.4 4.2 40.4 9.1z"
      fill="#095A60"
    />
    <LinearGradient
      id="SVGID_1_"
      x1={1364.793}
      x2={1364.793}
      y1={3100.612}
      y2={4190.373}
      gradientTransform="matrix(.55 0 0 .55 -356.4 -1674.7)"
      gradientUnits="userSpaceOnUse"
    >
      <Stop
        offset={0}
        style={{
          stopColor: "#b1b9bc",
          stopOpacity: 0.1,
        }}
      />
      <Stop
        offset={1}
        style={{
          stopColor: "#b1b9bc",
          stopOpacity: 0.25,
        }}
      />
    </LinearGradient>
    <Path
      d="M394.3 420.5v-.2.2c0 .4 0 .6-.1 1 .1-.4.1-.7.1-1z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <Path
      d="M316.9 236.4c-3.6 0-7.2 1-10.1 3-1 .6-2 1.5-2.8 2.3-1.2 1.2-2.3 2.6-3.2 4.1L262 312.7l-13.8 24-21.9 37.8c-.4.6-.6 1.1-.9 1.7-.2.6-.5 1.1-.6 1.7-.1.4-.2.7-.4 1.1-1 4-.7 8 .7 11.9.9 2.2 2.2 4.3 4 6.1.2.2.4.5.6.6.5.4.9.7 1.4 1.2.5.4 1 .7 1.5 1.1 2.2 1.4 4.7 2.3 7.3 2.7h.1c.4 0 .7.1 1.1.1h1.5-17.9c-10.4 0-18.8-8.4-18.8-18.8 0-3.3.9-6.5 2.5-9.4l21.9-37.9 13.8-24 38.7-66.9c3.3-5.8 9.5-9.4 16.3-9.4l17.8.1z"
      fill="#0A96A9"
    />
    <Path
      d="m407.5 374.6-41.4-71.8-29.1-50.6-3.7-6.4c-3.3-5.8-9.5-9.5-16.3-9.4h-1.9l-.6.1c-.2 0-.5 0-.7.1-.2 0-.5.1-.6.1-.2 0-.5.1-.7.1s-.5.1-.6.1-.5.1-.6.2c-.2.1-.2.1-.4.1l-.6.2-.5.2-.6.2-.5.2-.5.2c-.5.2-1.1.6-1.6 1-.1.1-.2.1-.4.2 0 0-.1 0-.1.1-.4.2-.6.5-.9.7-.1.1-.4.2-.5.4l-.9.9c-.2.4-.6.6-.9 1-.2.4-.5.7-.7 1-.1.1-.2.4-.4.5-.2.4-.5.7-.6 1.1L262.7 312l-13.8 24-21.9 37.8c-.4.6-.6 1.1-.9 1.7-.1.2-.2.5-.4.7V376.5c-.1.2-.1.5-.2.7-.1.2-.1.5-.2.7s-.2.6-.2 1c0 .1-.1.4-.1.6-.1.6-.2 1.1-.2 1.7V385c0 .2 0 .6.1.9 0 .2.1.6.1.9s.1.6.2.9c.1.5.2 1 .4 1.5.1.2.1.5.2.7.1.4.4.7.5 1.2.1.2.2.5.4.9.2.5.6 1.1 1 1.6.1.2.4.5.5.7.1.2.4.5.5.7.4.5.7 1 1.2 1.4.2.2.4.5.6.6.4.4.9.9 1.4 1.2.5.4 1 .7 1.5 1.1.2.1.6.4.9.5.9.5 1.7.9 2.6 1.2.2.1.6.2.9.4.2.1.6.1 1 .2 1.5.4 3 .6 4.4.6h148.9c10.4 0 18.8-8.4 18.8-18.8-.8-2.8-1.7-6-3.4-8.8zm-8.6 13.7c-1.6 2.7-4.4 4.4-7.7 4.4H242.5c-4.8 0-8.8-4-8.8-8.8 0-1.5.4-3.1 1.2-4.4l24.8-42.9 13.8-24 35.8-62c2.5-4.2 7.8-5.7 12-3.2 1.4.7 2.5 1.9 3.2 3.2l12.3 21.5 29.1 50.6 32.7 56.7c1.9 2.8 1.9 6.2.3 8.9z"
      fill="#095A60"
    />
    <Path
      d="M235 388.3c1.5 2.7 4.4 4.4 7.7 4.4h148.7c4.8 0 8.8-4 8.8-8.8 0-1.5-.4-3.1-1.2-4.4l-74.3-128.7c-2.5-4.2-7.8-5.7-12-3.2-1.4.7-2.5 1.9-3.2 3.2L235 379.6c-1.6 2.6-1.6 6 0 8.7zm91.4-102.1-2 61.4h-14.7l-2-61.4h18.7z"
      fill="#FFFFFF"
    />
    <Path
      d="M307.1 366.4c-.1 2.5.9 4.9 2.7 6.7 2 1.9 4.6 2.7 7.2 2.6 2.6.1 5.2-.9 7-2.6 1.9-1.7 2.8-4.2 2.7-6.7.1-2.5-.9-4.9-2.7-6.7-1.9-1.9-4.4-2.7-7-2.6-3 0-5.3.9-7.2 2.6-1.8 1.9-2.8 4.3-2.7 6.7zM307.6 286.2l2.1 61.3h14.6l2.1-61.3z"
      fill="#E86B18"
      opacity="0.75"
    />
    <Circle cx={433.5} cy={310.5} r={16.7} fill="#0A96A9" />
    <G
      style={{
        enableBackground: "new",
      }}
    >
      <Path
        d="m437.3 398 5.3-5.3-5.3-5.3c-.7-.7-.7-1.7 0-2.4l2-2c.7-.7 1.7-.7 2.4 0l5.3 5.3 5.3-5.3c.7-.7 1.7-.7 2.4 0l2 2c.7.7.7 1.7 0 2.4l-5.3 5.3 5.3 5.3c.7.7.7 1.7 0 2.4l-2 2c-.7.7-1.7.7-2.4 0l-5.3-5.3-5.3 5.3c-.7.7-1.7.7-2.4 0l-2-2c-.6-.7-.6-1.7 0-2.4zM119.5 251.6l5.3-5.3-5.3-5.3c-.7-.7-.7-1.7 0-2.4l2-2c.7-.7 1.7-.7 2.4 0l5.3 5.3 5.3-5.3c.7-.7 1.7-.7 2.4 0l2 2c.7.7.7 1.7 0 2.4l-5.3 5.3 5.3 5.3c.7.7.7 1.7 0 2.4l-2 2c-.7.7-1.7.7-2.4 0l-5.3-5.3-5.3 5.3c-.7.7-1.7.7-2.4 0l-2-2c-.6-.7-.6-1.8 0-2.4zm263.5 6.9 9.7-9.7-9.7-9.7c-1.1-1.1-1.1-3.2 0-4.4l3.7-3.7c1.1-1.1 3.2-1.1 4.4 0l9.7 9.7 9.7-9.7c1.1-1.1 3.2-1.1 4.4 0l3.7 3.7c1.1 1.1 1.1 3.2 0 4.4l-9.7 9.7 9.7 9.7c1.1 1.1 1.1 3.2 0 4.4l-3.7 3.7c-1.1 1.1-3.2 1.1-4.4 0l-9.7-9.7-9.7 9.7c-1.1 1.1-3.2 1.1-4.4 0l-3.7-3.7c-1.1-1.2-1.1-3.3 0-4.4zm-88.8-118.2 4.2-4.2-4.2-4.2c-.5-.5-.5-1.4 0-1.9l1.6-1.6c.5-.5 1.4-.5 1.9 0l4.2 4.2 4.2-4.2c.5-.5 1.4-.5 1.9 0l1.6 1.6c.5.5.5 1.4 0 1.9l-4.2 4.2 4.2 4.2c.5.5.5 1.4 0 1.9l-1.6 1.6c-.5.5-1.4.5-1.9 0l-4.2-4.2-4.2 4.2c-.5.5-1.4.5-1.9 0l-1.6-1.6c-.5-.5-.5-1.3 0-1.9z"
        style={{
          fill: "#e86b18",
        }}
      />
    </G>
    <Circle cx={185.7} cy={169.3} r={6.1} fill="#0A96A9" />
    <Circle cx={112.7} cy={343.7} r={16.5} fill="#0A96A9" />
    <Circle cx={377.6} cy={175.4} r={23.1} fill="#0A96A9" />
  </Svg>
)
export default NotFound
