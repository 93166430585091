const POSITION = {
  NEUTRAL: "NEUTRAL",
  LONG: "LONG",
  SHORT: "SHORT",
};

const POSITION_COLOR = {
  NEUTRAL: "#c47f25",
  LONG: "#25c4a9",
  SHORT: "#c42532",
};

const POSITION_ICON = {
  NEUTRAL: {
    type: "feather",
    name: "alert-circle",
  },
  LONG: {
    type: "entypo",
    name: "triangle-up",
  },
  SHORT: {
    type: "entypo",
    name: "triangle-down",
  },
};
export { POSITION, POSITION_COLOR, POSITION_ICON };
