import AsyncStorage from "@react-native-async-storage/async-storage";
import Server from "./server";

const baseUrl = "api/v1/";
const stylesBaseUrl = "/pychart/web-api/chart/styles";

//********************** TYPE **********************//

/**
 * Custom data type defining a Strategy.
 * @typedef {Object} Strategy
 * @property {number} id - The ID of the strategy.
 * @property {string} strategy_id - The ID of the strategy associated with the strategy.
 * @property {string} name - The name of the strategy.
 * @property {string} provider_id - The ID of the provider associated with the strategy.
 * @property {number} rating - The rating of the strategy.
 * @property {string[]} time_frames - The time frames for which the strategy is applicable.
 * @property {string[]} positions - The positions associated with the strategy.
 * @property {string[]} events - The events associated with the strategy.
 * @property {string} date - The date when the strategy was recorded.
 */

//********************** API **********************//

/**
 * This function retrieves a list of strategy from the server.
 * @returns {Promise<Strategy[]>}
 */
export const getAllStrategies = async () => {
  try {
    const token = await AsyncStorage.getItem("token");
    if (!token) {
      return await Server.get(`${baseUrl}public/strategies`);
    }
    return await Server.get(`${baseUrl}strategies`);
  } catch (error) {
    console.log("===ERROR API :: getAllStrategies===", error);
  }
};

export const getAllStyles = async () => {
  try {
    const response = await Server.get(`${stylesBaseUrl}`);
    return response;
  } catch (error) {
    console.log("===ERROR API :: getAllStyles===", error);
  }
};

/**
 * This function retrieves a list of indicators from the server.
 * @returns {Promise<Strategy[]>}
 */
export const getAllIndicators = async () => {
  try {
    return await Server.get(`${baseUrl}indicators`);
  } catch (error) {
    console.log("===ERROR API :: getAllIndicators===", error);
  }
};

export const getStrategyDescriptions = async () => {
  try {
    return await Server.get(`${baseUrl}public/strategies-description`);
  } catch (error) {
    console.log("===ERROR API :: getStrategyDescriptions===", error);
  }
};