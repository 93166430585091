import { Text, useWindowDimensions, View } from "react-native";
import { Controller } from "react-hook-form";
import DropDownPicker from "react-native-dropdown-picker";
import React from "react";
import { Button, useTheme } from "@rneui/themed";
import { useStyles } from "./SearchAlertStyles";
import useSearchAlerts from "containers/Alerts/components/SearchAlerts/useSearchAlerts";
import Checkbox from "expo-checkbox";
import { STYLES } from "constants/STYLES";

const pickers = ["markets", "strategies", "timeFrames"];

const Label = (props) => {
  const { theme } = useTheme();
  const { text } = props;
  return (
    <Text
      style={{
        fontSize: 20,
        fontWeight: "500",
        marginBottom: 5,
        color: theme.colors.whiteText,
      }}
    >
      {text}
    </Text>
  );
};

const SearchAlerts = ({ pickersToDisplay = pickers, onSearch, onClearSearch, filters }) => {
  const { theme } = useTheme();
  const dimensions = useWindowDimensions();
  const styles = useStyles(dimensions.width);

  const {
    isMultiple,
    openMarket,
    openStrategy,
    openTimeFrame,
    marketItems,
    strategyItems,
    timeFrameItems,
    valueMarket,
    valueStrategy,
    valueTimeFrame,
    setValueMarket,
    setValueStrategy,
    setValueTimeFrame,
    setMarketItems,
    setStrategyItems,
    setTimeFrameItems,
    handleOpenMarkets,
    handleOpenStrategies,
    handleOpenTimeFrames,
    dropDownStyle,
    dropDownContainerStyle,
    itemAndLabelStyle,
    handleSubmit,
    control,
    handleChangeMultiple,
  } = useSearchAlerts({ filters });

  const dropDownProps = {
    autoScroll: true,
    listMode: "FLATLIST",
    theme: theme.mode.toUpperCase(),
    disableBorderRadius: false,
    dropDownContainerStyle: dropDownContainerStyle,
    labelStyle: itemAndLabelStyle,
    selectedItemLabelStyle: itemAndLabelStyle,
    style: dropDownStyle,
  };

  return (
    <View style={styles.selectContainer}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          minHeight: 30,
          marginVertical: 10,
        }}
      >
        <Checkbox
          value={isMultiple}
          onValueChange={handleChangeMultiple}
          color={
            theme.mode === "light" ? theme.colors.primary : theme.colors.grey2
          }
        />
        <Text
          style={{
            fontWeight: "bold",
            marginLeft: 6,
            color: theme.colors.primary,
          }}
        >
          Multiple Selection
        </Text>
      </View>

      {pickersToDisplay.includes("markets") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.marketDropDown,
            openMarket && { zIndex: 3 },
          ]}
        >
          <Label text="Market"/>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={marketItems}
                open={openMarket}
                setItems={setMarketItems}
                setOpen={handleOpenMarkets}
                setValue={setValueMarket}
                value={valueMarket}
                onChangeValue={onChange}
              />
            )}
            name={"MARKET"}
          />
        </View>
      )}
      {pickersToDisplay.includes("strategies") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.strategyDropDown,
            openStrategy && { zIndex: 3 },
          ]}
        >
          <Label text="Indicator"/>

          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={strategyItems}
                open={openStrategy}
                setItems={setStrategyItems}
                setOpen={handleOpenStrategies}
                setValue={setValueStrategy}
                value={valueStrategy}
                onChangeValue={onChange}
              />
            )}
            name={"STRATEGY"}
          />
        </View>
      )}
      {pickersToDisplay.includes("timeFrames") && (
        <View
          style={[
            styles.dropDownWrapper,
            styles.timeFrameDropDown,
            openTimeFrame && { zIndex: 3 },
          ]}
        >
          <Label text="Time Frame"/>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <DropDownPicker
                {...dropDownProps}
                multiple={isMultiple}
                items={timeFrameItems}
                open={openTimeFrame}
                setItems={setTimeFrameItems}
                setOpen={handleOpenTimeFrames}
                setValue={setValueTimeFrame}
                value={valueTimeFrame}
                onChangeValue={onChange}
                disabledItemLabelStyle={{
                  opacity: 0.5,
                }}
                dropDownDirection="TOP"
              />
            )}
            name={"TIME_FRAME"}
          />
        </View>
      )}
      <View style={styles.addAlertBtn}>
        <Button
          title="Search"
          containerStyle={styles.addAlertActionBtn}
          titleStyle={styles.addAlertActionBtnTitleStyle}
          onPress={() => handleSubmit(onSearch({
            isMultiple,
            market: valueMarket,
            strategyId: valueStrategy,
            timeFrame: valueTimeFrame
          }))}
        />
        <Button
          title="Clear"
          type="outline"
          buttonStyle={{
            border: `2px solid ${theme.colors.primary}`,
            borderColor: theme.colors.primary,
            borderRadius: STYLES.BORDER_RADIUS.BUTTON,
            marginBottom: 10,
          }}
          titleStyle={{ color: theme.colors.primary }}
          onPress={onClearSearch}
        />
      </View>

    </View>
  )
}

export default SearchAlerts;