import React, { useCallback } from "react";
import { ActivityIndicator, useWindowDimensions, View } from "react-native";
import { Button, Icon, Image, Text, useTheme } from "@rneui/themed";
import useEmailVerification from "./useEmailVerification";
import { useStyles } from "./styles";
import { SCREENS_NAMES } from "constants/screens";
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import Email from "components/UI/Email";
import { useMutation } from "react-query";
import { setHasSeenOnboarding } from "api/onBoarding";

const EmailVerification = ({ route }) => {
  const { theme } = useTheme();
  const {
    email = "",
    from = SCREENS_NAMES.REGISTER,
    isFromOnBoarding = false,
  } = route?.params;
  const { navigate } = useNavigation();
  const { isLoading, onClickResendEmail } = useEmailVerification();
  const { width } = useWindowDimensions();
  const styles = useStyles({ width });

  const onBoardingMutation = useMutation({
    mutationFn: setHasSeenOnboarding,
  });

  let title = "Check your inbox, please!";
  if (from === SCREENS_NAMES.LOGIN) {
    title = "Your email is not verified!";
  }

  const handleRedirectToLogin = useCallback(async () => {
    if (isFromOnBoarding) {
      navigate(SCREENS_NAMES.LOGIN, { isFromOnBoarding });
    } else {
      navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.ACCOUNT });
    }
  }, [isFromOnBoarding, navigate]);

  const handleRedirectToHome = useCallback(async () => {
    if (isFromOnBoarding) {
      await onBoardingMutation.mutateAsync();
      navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.MARKETS });
    } else {
      navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.MARKETS });
    }
  }, [isFromOnBoarding, onBoardingMutation, navigate]);

  let description = "";
  if (from === SCREENS_NAMES.REGISTER) {
    description = (
      <Text>
        An email with instructions to verify your email address has been sent to
        <Text style={styles.bold}> {email} </Text>.
      </Text>
    );
  } else if (from === SCREENS_NAMES.LOGIN) {
    description = (
      <Text>
        Click on 'Send Again' to send you a new email if your last link is
        expired
        <Text style={styles.bold}> ({email}) </Text>.
      </Text>
    );
  } else if (from === SCREENS_NAMES.FORGOT_PASSWORD) {
    description = (
      <Text>
        An email with instructions to reset your password has been sent to
        <Text style={styles.bold}> {email} </Text>.
      </Text>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.container}>
        <View>
          <View style={styles.imageContainer}>
            <Email width={200} height={200} />
          </View>
          <View>
            <Text h3 h3Style={styles.title}>
              {title}
            </Text>
          </View>
          <View>
            <Text style={styles.description}>{description}</Text>

            {from !== SCREENS_NAMES.FORGOT_PASSWORD && (
              <>
                <View style={styles.warningTextWrapper}>
                  <Icon
                    type="ant-design"
                    name="warning"
                    size={20}
                    color={theme.colors.warning}
                  />
                  <Text style={styles.warningText}>
                    You need to verify your email address to activate your
                    account.
                  </Text>
                </View>

                <Text style={styles.description}>
                  Didn't receive the email yet?
                  <Button
                    type="text"
                    onPress={onClickResendEmail}
                    buttonStyle={styles.buttonStyle}
                    title={"Send Again"}
                    titleStyle={styles.titleStyle}
                    containerStyle={styles.buttonContainer}
                    disabled={isLoading}
                    icon={
                      isLoading && (
                        <ActivityIndicator
                          size={10}
                          color={theme.colors.grey4}
                        />
                      )
                    }
                    size="small"
                  />
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={styles.actionsContainer}>
          <Button
            buttonStyle={styles.loginButtonStyle}
            titleStyle={styles.loginTitleStyle}
            onPress={handleRedirectToLogin}
            title={"Sign In"}
          />
          <Button
            type="outline"
            buttonStyle={styles.buttonOutlineStyle}
            titleStyle={styles.buttonTitleOutlineStyle}
            onPress={handleRedirectToHome}
            title={"Home"}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default EmailVerification;

EmailVerification.propTypes = {};

EmailVerification.defaultProps = {};
