import { makeStyles } from "@rneui/themed";

const useStyles = makeStyles((theme, alert = {}) => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
  },
}));

export default useStyles;
