import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const BasicIcon = ({ color, ...rest }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 500 500"
    {...rest}
  >
    <G transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
       fill={color} stroke="none">
      <Path d="M1372 2916 c-17 -19 -82 -104 -146 -188 -63 -84 -149 -195 -190 -247
-42 -51 -76 -97 -76 -102 0 -8 41 -57 155 -184 40 -44 153 -170 251 -280 99
-110 254 -283 345 -385 91 -102 253 -282 359 -400 106 -118 242 -270 303 -337
80 -90 116 -123 133 -123 17 0 102 89 366 383 188 210 529 590 758 845 228
255 416 472 418 482 2 11 -46 81 -125 181 -71 90 -167 214 -214 274 l-85 110
-1111 3 -1111 2 -30 -34z m2260 -134 c272 -346 300 -386 296 -412 -4 -26
-1406 -1600 -1425 -1600 -8 0 -17 9 -21 20 -4 11 -324 369 -712 795 -388 427
-706 780 -708 785 -2 5 68 98 154 207 87 109 162 207 168 218 6 12 22 35 36
52 l25 32 1055 0 1055 1 77 -98z"/>
    </G>
  </Svg>
)
export default BasicIcon
