import * as yup from "yup";

const useSchema = () => {
  return {
    EMAIL: ({ field = "email" } = {}) =>
      yup
        .string()
        .email(`Invalid Email Address.`)
        .required(`${field} is a required field`),

    REQUIRED: ({ field } = {}) =>
      yup.string().required(`${field} is a required field`),
    CODE: ({ field = "code" } = {}) =>
      yup.string().required(`${field} is a required field`),
    HASH: ({ field = "hash" } = {}) =>
      yup.string().required(`${field} is a required field`),

    LONG_TEXT: ({ field = "text" } = {}) => yup.string(),

    PASSWORD: ({ field = "password" } = {}) =>
      yup.string().required(`${field} is a required field`).min(6),
    CONFIRMATION: ({ field = "confirmation" } = {}) =>
      yup
        .string()
        .required(`${field} is a required field`)
        .oneOf([yup.ref("password"), null], "Passwords do not match."),
  };
};

export default useSchema;
