import React, { useEffect, useState } from 'react';
import * as WebBrowser from 'expo-web-browser';
import { useRoute, useNavigation } from '@react-navigation/native';
import { ScrollView, View } from "react-native";
import { Button, Text } from "@rneui/themed";
import * as Clipboard from "expo-clipboard";
import { SCREENS_NAMES } from "constants/screens";
import { useStyles } from "./NotFoundStyles";
import Constants from "expo-constants";
import Toast from "react-native-toast-message";

const NotFound = () => {
  const route = useRoute();
  const styles = useStyles();
  const navigation = useNavigation();
  const [url, setUrl] = useState('');
  const [countdown, setCountdown] = useState(5);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    const generatedUrl = makeUrl();
    setUrl(generatedUrl);

    if (!isNotFound) {
      // Start countdown timer
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval);
            setTimeout(handleRedirect, 1000); // Redirect after showing the message for a moment
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [route]);

  const makeUrl = () => {
    let url = Constants.expoConfig.extra.env.BASE_URL;
    const { name, params, path } = route;
    const appendParams = (...params) => params.filter(param => param != null).join('/');

    switch (name) {
      case SCREENS_NAMES.VIEW_CHART:
        url += `view-chart/${appendParams(params?.marketId, params?.strategyId, params?.timeFrame, params?.signal_time)}`;
        break;
      case SCREENS_NAMES.PAYMENT:
        url += `payment/${params?.item ? btoa(JSON.stringify(params.item)) : ''}`;
        break;
      case SCREENS_NAMES.VIEW_STRATEGY:
        url += `view-strategy/${params?.strategyId || ''}`;
        break;
      case SCREENS_NAMES.NOT_FOUND:
        url += path;
        setIsNotFound(true);
        break;
      default:
        url += name;
        break;
    }
    return url;
  };

  const handleRedirect = async () => {
    await WebBrowser.openBrowserAsync(url);
  };

  const copyToClipBoard = () => {
    Clipboard.setStringAsync(url);
    Toast.show({
      text1: "Successfully copied to clipboard.",
      type: "success",
    });
  };

  if (isNotFound) {
    return (
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.title}>We didn't find the URL you requested:</Text>
        <Text style={styles.url} onPress={copyToClipBoard}>{route?.name}</Text>
        <Button
          buttonStyle={styles.buttonStyle}
          titleStyle={styles.buttonTitleStyle}
          onPress={() => navigation.navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.MARKETS })}
          title="Back to Home"
        />
      </ScrollView>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {countdown > 0
        ? <Text style={styles.title}>You will be redirected to the following URL in {countdown} seconds:</Text>
        : (
          <View>
            <Text style={styles.title}>You are being redirected.</Text>
            <Text style={styles.message}>If not, click the "Open on Web" button.</Text>
          </View>
        )
      }
      <Text style={styles.url} onPress={copyToClipBoard}>{url}</Text>
      <Button
        buttonStyle={styles.buttonStyle}
        titleStyle={styles.buttonTitleStyle}
        onPress={handleRedirect}
        title="Open on Web"
      />
      <Button
        type="outline"
        buttonStyle={styles.buttonOutlineStyle}
        titleStyle={styles.buttonTitleOutlineStyle}
        onPress={() => navigation.navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.MARKETS })}
        title="Back to Home"
      />
    </ScrollView>
  );
};

export default NotFound;
