import {makeStyles} from "@rneui/themed";

export const useAlertSharedStyles = makeStyles((theme) => ({
  fullScreen: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 36,
  },
  centerView: {
    width: "100%",
    height: "85%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
  },
  warningTextContainer: {
    flexDirection: "column",
    alignItems: "center",
    margin: 15,
  },
  warningText:{
    fontWeight: "300",
    color: theme.colors.grey2,
    marginBottom: 5,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.colors.primary,
    borderRadius: 8,
    marginTop: 20,
    padding: 10,
  },
  buttonText: {
    color: theme.colors.white,
    fontWeight: "700",
    marginHorizontal: 10,
  }
}));
