import React, { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const AccountContainer = lazy(() => import("containers/Account"));

const Account = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <AccountContainer />
    </Suspense>
  );
};

export default Account;
