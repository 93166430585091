import { makeStyles } from "@rneui/themed";
import { CARD_STYLES, FLEX_POSITIONS, STYLES } from "constants/STYLES";
import { Platform, StyleSheet } from "react-native";

const useStyles = makeStyles((theme, { type, isSmallScreen }) => ({
  container: {
    marginVertical: STYLES.MARGIN.MD,
    paddingVertical: STYLES.PADDING.MD,
    paddingHorizontal: STYLES.PADDING.MD,
    backgroundColor: theme.colors.white,
  },
  cardWrapper: {
    height: "100%",
  },
  card: {
    ...CARD_STYLES,
    borderWidth: theme.mode === "dark" ? StyleSheet.hairlineWidth : 0,
    borderColor: theme.colors.whiteText,
    ...(Platform.OS === "web" && {
      margin: 0,
      padding: ["24px 12px", "!important"],
      width: "33%",
      ...(isSmallScreen && {
        width: "100%",
        alignItems: "space-between",
      }),
    }),
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...(isSmallScreen && {
      justifyContent: "space-between",
    }),
  },
  titleWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    ...(isSmallScreen && { alignItems: "center" }),
  },
  itemIcon: {
    ...(isSmallScreen && { marginBottom: 10 }),
  },
  dropdownWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  dropdownIcon: {
    backgroundColor: theme.colors.grey5,
    borderRadius: 50,
    marginLeft: 10
  },
  titleText: {
    fontSize: 22,
    color: theme.colors.black,
    ...(isSmallScreen && { fontSize: 20 }),
  },
  flexRowCenter: {
    ...FLEX_POSITIONS.ROW_CENTER,
  },
  viewMode: {
    ...(isSmallScreen && { display: "none" }),
  },
  subHeader: {
    minWidth: 100,
    marginVertical: STYLES.MARGIN.SX,
    // marginBottom: 24,
    justifyContent: "center",
    ...(!isSmallScreen && { minHeight: 50 }),
  },
  subHeaderText: {
    paddingRight: 8,
    fontSize: 24,
    fontWeight: "bolder",
    color: theme.colors.black,
    ...(isSmallScreen && { fontSize: 20 }),
  },
  subHeaderSubText: {
    fontSize: 16,
    fontWeight: "light",
    color: theme.colors.grey1,
    ...(isSmallScreen && { fontSize: 14 }),
  },

  iconCurrencyStyle: {
    marginLeft: -5,
    color: theme.colors.grey1,
  },
  discriptionText: {
    fontSize: 14,
    color:
      theme.mode === "dark"
        ? theme.colors.whiteText
        : STYLES.COLORS.TEXT.MIDDLE_GRAY,
    marginTop: 2,
    marginLeft: 3,
  },

  listContainerStyle: {
    minHeight: 80,
    ...(isSmallScreen && {
      alignItems: "flex-start"
    }),
  },

  listContentContainerStyle: {
    ...(isSmallScreen && {
      justifyContent: "flex-start",
      paddingLeft: 15
    }),
  },

  listItemText: {
    fontSize: 12,
    color:
      theme.mode === "dark"
        ? theme.colors.whiteText
        : STYLES.COLORS.TEXT.DARK_GRAY,
  },

  iconListStyle: {
    marginRight: STYLES.MARGIN.SX,
    color: STYLES.COLORS.ICON.MIDDLE_GRAY_2,
  },

  skeleton: {
    display: "flex",
    margin: "auto",
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
  },
  buttonContainerStyle: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
  },
  buttonStyle: {
    width: 200,
    display: "flex",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.colors.grey5,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    ...(isSmallScreen && {
      width: 250,
    })
  },
  buttonTitleStyle: {
    color: theme.colors.white,
    fontSize: 14,
  },
  activeTextWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 12,
    ...(isSmallScreen && {
      justifyContent: "flex-start",
      paddingLeft: 15,
    })
  },
  activeText: {
    color: type === "Premium" ? theme.colors.warning : theme.colors.primary,
    marginLeft: 6,
    fontWeight: "bold",
  },
  warningText: {
    color: theme.colors.grey1,
    marginLeft: 6,
  },
  timeText: {
    color: theme.colors.grey2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    ...(isSmallScreen && {
      justifyContent: "flex-start",
      paddingLeft: 15,
    })
  },
}));

export default useStyles;
