import Server from "./server";
import Constants from "expo-constants";

const baseUrl = "media/market-img";

export const getMarketImageUrl = (market) => {
    return `${Constants.expoConfig.extra.env.BASE_URL}${baseUrl}/${market.market}/providers/1`;
}

export const getMarketImage = async ({market}) => {
  try {
    return await Server.get(`${baseUrl}/${market.market}/providers/1`);
  } catch (e) {
    console.log("===error while fetching marketImage", e)
  }
}