import React from "react";
import { createDrawerNavigator, DrawerItem, } from "@react-navigation/drawer";
import { Image, Platform, useWindowDimensions, Text as TextNative, View, } from "react-native";
import { Icon, Text, useTheme } from "@rneui/themed";
import { HOME_DRAWER_SCREENS } from "..";
import { SCREENS_NAMES } from "constants/screens";
import { ROOT } from "constants/ROOT";
import { CommonActions, DrawerActions, useLinkBuilder, } from "@react-navigation/native";
import ScreenContainer from "components/ScreenContainer";
import { useQueryClient } from "react-query";
import { useUserContext } from "context/user/UserProvider";

const Drawer = createDrawerNavigator();

// Custom Drawer Content Component
const CustomDrawerContent = (props) => {
  const { navigation, state, descriptors } = props;
  const buildLink = useLinkBuilder();
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const { isSignedIn } = useUserContext();
  const queryClient = useQueryClient();
  const userInfoQuery = queryClient.getQueryData("userInfo");
  const userInfoData = (userInfoQuery || {}).data;


  const topRoutes = state.routes.slice(0, 5);
  const bottomRoutes = state.routes.slice(5);

  const focusedRoute = state.routes[state.index];
  const focusedDescriptor = descriptors[focusedRoute.key];
  const focusedOptions = focusedDescriptor.options;

  const {
    drawerActiveTintColor,
    drawerInactiveTintColor,
    drawerActiveBackgroundColor,
    drawerInactiveBackgroundColor,
  } = focusedOptions;

  const RenderDrawerItem = ({ route, i }) => {
    const focused = i === state.index;

    const onPress = () => {
      const event = navigation.emit({
        type: "drawerItemPress",
        target: route.key,
        canPreventDefault: true,
      });

      if (!event.defaultPrevented) {
        navigation.dispatch({
          ...(focused
            ? DrawerActions.closeDrawer()
            : CommonActions.navigate({ name: route.name, merge: true })),
          target: state.key,
        });
      }
    };

    const {
      title,
      drawerLabel,
      drawerIcon,
      drawerLabelStyle,
      drawerItemStyle,
      drawerAllowFontScaling,
    } = descriptors[route.key].options;

    return (
      <DrawerItem
        key={route.key}
        label={
          drawerLabel !== undefined
            ? drawerLabel
            : title !== undefined
              ? title
              : route.name
        }
        icon={drawerIcon}
        focused={focused}
        activeTintColor={drawerActiveTintColor}
        inactiveTintColor={drawerInactiveTintColor}
        activeBackgroundColor={drawerActiveBackgroundColor}
        inactiveBackgroundColor={drawerInactiveBackgroundColor}
        allowFontScaling={drawerAllowFontScaling}
        labelStyle={drawerLabelStyle}
        style={drawerItemStyle}
        to={buildLink(route.name, route.params)}
        onPress={onPress}
      />
    );
  };

  return (
    // <DrawerContentScrollView
    //   {...props}
    //   contentContainerStyle={{ height: "100%", flex: 1 }}
    // >
    <View
      style={{
        flex: 1,
        backgroundColor:
          width >= theme.breakpoints.white
            ? "transparent"
            : theme.colors.background,
      }}
    >
      <View
        style={{
          flex: 1,
          padding: 12,
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
        }}
      >
        {/* logo */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={require("assets/logo512.png")}
            style={{ width: 56, height: 56, marginVertical: 1.5 }}
          />
          <View>
            <Text
              style={{
                color: theme.colors.primary,
                fontSize: 20,
                fontWeight: "700",
              }}
            >
              {ROOT.NAME}
            </Text>
            {isSignedIn && (
              <TextNative
                style={{
                  color: theme.colors.primary,
                  fontSize: 13,
                  fontWeight: "500",
                }}
                numberOfLines={2}
              >
                {userInfoData?.email}
              </TextNative>
            )}
          </View>
        </View>
        {/* Drawer items */}
        <View
          style={{
            flex: 1,
            marginTop: 20,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <View>
            {topRoutes.map((route, index) => (
              <RenderDrawerItem route={route} i={index}/>
            ))}
          </View>
          <View>
            {bottomRoutes.map((route, index) => (
              <RenderDrawerItem route={route} i={index + topRoutes.length}/>
            ))}
          </View>
        </View>
      </View>
    </View>
    // </DrawerContentScrollView>
  );
};

const Home = ({ route, navigation }) => {
  const dimensions = useWindowDimensions();
  const { theme } = useTheme();

  const tabBarIcon = (tabRoute, { focused, color, size }) => {
    let icon = null;
    let icon_size = focused ? 24 : 20;
    switch (tabRoute.name) {
      case SCREENS_NAMES.MARKETS:
        icon = (
          <Icon
            name="bar-chart-2"
            type="feather"
            color={color}
            size={icon_size}
          />
        );
        break;
      case SCREENS_NAMES.ALERTS:
        icon = (
          <Icon
            name="notification"
            type="ant-design"
            color={color}
            size={icon_size}
          />
        );
        break;
      case SCREENS_NAMES.CREATE_ALERT:
        icon = (
          <Icon
            name="bell-plus-outline"
            type="material-community"
            color={color}
            size={icon_size}
          />
        );
        break;
      case SCREENS_NAMES.STRATEGIES:
        icon = (
          <Icon
            name="arrow-decision-outline"
            type="material-community"
            color={color}
            size={icon_size}
          />
        );
        break;
      case SCREENS_NAMES.PRICING:
        icon = (
          <Icon
            name="dollar-sign"
            type="feather"
            color={color}
            size={icon_size}
          />
        );
        break;
      case SCREENS_NAMES.PREFERENCES:
        icon = (
          <Icon name="tool" type="feather" color={color} size={icon_size}/>
        );
        break;
      case SCREENS_NAMES.ACCOUNT:
        icon = (
          <Icon name="user" type="feather" color={color} size={icon_size}/>
        );
        break;
    }

    return (
      <View style={{ minWidth: 30, marginLeft: 15, marginRight: 15 }}>
        {icon}
      </View>
    );
  };

  const pageMargin = 24;

  return (
    <View
      style={{
        flex: 1,
        marginHorizontal: 0,
      }}
    >
      <ScreenContainer>
        <Drawer.Navigator
          screenOptions={{
            drawerType:
              dimensions.width > theme.breakpoints.drawer ? "permanent" : "front",
            headerShown:
              dimensions.width <= theme.breakpoints.drawer,
            headerStyle: {
              borderBottomWidth: 0,
              backgroundColor: "transparent",
            },
            headerTintColor: theme.colors.black,
            drawerStyle: {
              backgroundColor:
                dimensions.width >= theme.breakpoints.drawer
                  ? "transparent"
                  : theme.colors.white,
              borderRightWidth: 0,
              ...(dimensions.width < theme.breakpoints.drawer
                ? {}
                : {
                  marginRight: 0,
                }),
            },
            sceneContainerStyle: {
              backgroundColor:
                Platform.OS === "web" ? "transparent" : theme.colors.background,
              marginLeft: 0,
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
            },
          }}
          drawerContent={(props) => <CustomDrawerContent {...props} />}
          id={"drawerNavigator"}
        >
          {HOME_DRAWER_SCREENS.map((screen) => (
            <Drawer.Screen
              key={screen.name}
              name={screen.name}
              component={screen.component}
              options={{
                title: screen.title,
                drawerIcon: ({ focused, color, size }) =>
                  tabBarIcon(screen, { focused, color, size }),
              }}
            />
          ))}
        </Drawer.Navigator>
      </ScreenContainer>
    </View>
  );
};

export default Home;
