import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";
import TouchableCard from "components/TouchableCard";
import { Image, Text, useTheme } from "@rneui/themed";
import { getMarketImageUrl } from "api/media";
import { Platform, View, useWindowDimensions } from "react-native";
import { useQueryClient } from "react-query";
import HalfDoughnut from "components/UI/HalfDoughnut";

/**
 * Component for rendering an market.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {import("src/api/configurations").Market} props.market - The market object to be displayed.
 * @returns {React.JSX} - The rendered component.
 */

function Market({
  market,
  onPress,
  showIndicator = true,
  backgroundColor,
  wrapperStyle = {},
  isFullWidth = false,
}) {
  const { width } = useWindowDimensions();
  const isRowDisplay = useMemo(
    () => width >= 768 && isFullWidth,
    [width, isFullWidth]
  );
  const queryClient = useQueryClient();
  const realTimeData = queryClient.getQueryData("getDataSnapshots");
  const realTimeValue = useMemo(() => {
    if (!Array.isArray(realTimeData)) return null;
    return realTimeData?.find((e) => e.s === market.market);
  }, [realTimeData]);
  const navigation = useNavigation();

  const { theme } = useTheme();

  const onPressMarket = useCallback(() => {
    if (typeof onPress === "function") {
      onPress(market.id);
    } else {
      navigation.navigate(SCREENS_NAMES.VIEW_CHART, {
        marketId: market.id,
      });
    }
  }, [onPress, navigation.navigate]);

  const marketName = useMemo(() => {
    const [coin, quote] = market.market.split("-");
    return (
      <>
        <Text
          style={{
            color: theme.colors.whiteText,
            fontSize: 14,
          }}
          adjustsFontSizeToFit
        >
          {coin}
        </Text>
        <Text
          style={{
            color: theme.colors.grey2,
            fontSize: 12,
          }}
          adjustsFontSizeToFit
        >
          /{quote}
        </Text>
      </>
    );
  }, [market.market, theme.colors.whiteText]);
  const [randomNum, setRandomNum] = useState(0);
  useEffect(() => {
    const interval = setInterval(
      () => setRandomNum((randomNum) => randomNum + 1),
      5000
    );
    return () => clearInterval(interval);
  }, []);
  const price = useMemo(() => {
    if (realTimeValue) return realTimeValue.p;
    return null;
  }, [JSON.stringify(realTimeValue?.p)]);

  const volume = useMemo(() => {
    if (!realTimeValue) return null;
    return ((realTimeValue.v * realTimeValue.p) / 10 ** 6).toFixed(2);
  }, [realTimeValue?.v, realTimeValue?.p]);

  const percentage = useMemo(() => {
    if (!realTimeValue) return null;
    return [(realTimeValue.c * 100).toFixed(2), realTimeValue.c > 0];
  }, [realTimeValue?.c]);

  const isIncrease = useMemo(() => percentage?.[1], [percentage?.[1]]);

  const technicalPercentage = useMemo(() => {
    if (!realTimeValue) return null;
    return realTimeValue.sig;
  }, [realTimeValue?.sig]);

  const technicalWidth = useMemo(() => {
    if (!technicalPercentage && technicalPercentage !== 0) return 0;
    else return Math.ceil(technicalPercentage / 2) + 50;
  }, [technicalPercentage]);

  const getTechnicalLabel = (technicalWidth) => {
    let label = "";
    if (technicalWidth >= 0 && technicalWidth < 20) {
      label = "Strong Downtrend";
    } else if (technicalWidth >= 20 && technicalWidth < 50) {
      label = "Downtrend";
    } else if (technicalWidth == 50) {
      label = "Sideways";
    } else if (technicalWidth > 50 && technicalWidth < 80) {
      label = "Uptrend";
    } else if (technicalWidth >= 80 && technicalWidth <= 100) {
      label = "Strong Uptrend";
    }
    return label;
  };

  const isFavorite = useMemo(() => Math.random() > 0.5, []);

  const backgroundColorMarket = useMemo(() => "transparent", []);

  const colWidths = useMemo(() => {
    return {
      marketNameWidth: showIndicator ? "35%" : "50%",
      indicatorWidth: showIndicator ? "40%" : "0%",
      priceWidth: showIndicator ? "25%" : "50%",
    };
  }, [showIndicator]);

  const touchableCardStyle = useMemo(
    () => ({
      root: {
        width: "100%",
        flex: 1,
        flexDirection: "row",
        padding: 0,
        margin: "auto",
        marginBottom: 12,
        backgroundColor: backgroundColorMarket,
        borderRadius: 12,
        minHeight: 60,
        ...wrapperStyle,
      },
      wrapper: {
        padding: 0,
        margin: 0,
        width: "100%",
        maxWidth: "100%",
        flex: 1,
        backgroundColor: backgroundColorMarket,
      },
      container: {
        width: "100%",
        maxWidth: "100%",
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margin: 0,
        backgroundColor: backgroundColorMarket,
      },
      card: {
        paddingVertical: 12,
        paddingHorizontal: 12,
        margin: 0,
        flex: 1,
        width: "100%",
        maxWidth: "100%",
        backgroundColor: backgroundColorMarket,
        borderBottomWidth: 0,
      },
    }),
    [JSON.stringify(wrapperStyle)]
  );

  const marketNameAndImage = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          minWidth: colWidths.marketNameWidth,
          maxWidth: colWidths.marketNameWidth,
        }}
      >
        <View>
          <Image
            source={{ uri: getMarketImageUrl(market), cache: true }}
            style={{ width: 30, height: 30, borderRadius: 50 }}
          />
        </View>
        <View
          style={{
            flexDirection: isRowDisplay ? "row" : "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginLeft: 6,
            flexGrow: isRowDisplay ? 1 : 0,
          }}
        >
          <Text
            style={{
              color: theme.colors.whiteText,
              marginBottom: 2,
            }}
          >
            {marketName}
          </Text>
          <Text style={{ color: theme.colors.grey2, fontSize: 11 }}>
            {volume}M
          </Text>
        </View>
      </View>
    );
  }, [marketName, volume, isRowDisplay, theme.colors]);

  const marketpriceAndPercentage = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: isRowDisplay ? "row" : "column",
          justifyContent: isRowDisplay ? "space-between" : "center",
          alignItems: isRowDisplay ? "center" : "flex-end",
          minWidth: colWidths.priceWidth,
          maxWidth: colWidths.priceWidth,
        }}
      >
        <Text
          style={{
            color: theme.colors.whiteText,
            // marginLeft: 12,
            fontSize: 14,
            marginBottom: 2,
          }}
          adjustsFontSizeToFit
        >
          {price}
        </Text>
        <Text
          style={{
            color: isIncrease ? theme.colors.long : theme.colors.short,
            // marginLeft: 12,
            fontSize: 11,
          }}
          adjustsFontSizeToFit
        >
          {!percentage?.[1] ? null : percentage?.[1] ? "+" : "-"}
          {percentage?.[0]}%
        </Text>
      </View>
    );
  }, [
    isRowDisplay,
    theme.colors,
    price,
    isIncrease,
    percentage?.[1],
    percentage?.[0],
  ]);

  const indicatorMemo = useMemo(() => {
    return (
      showIndicator && (
        <View
          style={{
            flex: 2,
            minWidth: colWidths.indicatorWidth,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: colWidths.indicatorWidth,
            paddingHorizontal: 24,
          }}
        >
          <HalfDoughnut size={70} strokeWidth={6} value={technicalWidth} />
          <Text
            style={{
              fontSize: Platform.OS === "web" ? 10 : 8,
            }}
          >
            {getTechnicalLabel(technicalWidth)}
          </Text>
        </View>
      )
    );
  }, [showIndicator, technicalWidth]);

  return (
    <TouchableCard
      style={touchableCardStyle}
      id={market.id}
      onPress={onPressMarket}
      title={market.market}
    >
      {marketNameAndImage}
      {indicatorMemo}
      {marketpriceAndPercentage}
    </TouchableCard>
  );
}

export default Market;

Market.propTypes = {
  market: PropTypes.object,
};

Market.defaultProps = {
  market: {},
};
