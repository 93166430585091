import { makeStyles } from "@rneui/themed";
import { CARD_STYLES, STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme, { width }) => ({
  container: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    justifyContent: "space-between",
    height: "100%",
    padding: STYLES.PADDING.MD,
  },
  imageContainer: {
    width: "100%",
    alignItems: "center",
    marginTop: 24,
  },
  image: {
    width: 150,
    height: 150,
    justifyContent: "center"
  },
  title: {
    color: theme.colors.primary,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 24,
  },
  warningTextWrapper: {
    ...CARD_STYLES,
    borderColor: theme.colors.warning,
    padding: STYLES.PADDING.SX,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 12,
    // backgroundColor: `${theme.colors.warning}32`,
  },
  warningText: {
    color: theme.colors.grey1,
    fontWeight: "500",
    marginLeft: 6,
  },
  description: {
    color: theme.colors.grey1,
    marginTop: 15,
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  buttonStyle: {
    paddingHorizontal: 5,
    marginHorizontal: 5,
  },
  titleStyle: {
    color: theme.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  actionsContainer: {
    justifyContent: "center",
    ...(width > theme.breakpoints.tablet ? { flexDirection: "row", gap: 12 } : { flexDirection: "column" }),
  },
  loginButtonStyle: {
    backgroundColor: theme.mode === "dark" ? theme.colors.primary: theme.colors.primary,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
    alignItems: "center",
    marginVertical: 5,
    marginHorizontal: "auto",
    ...(width > theme.breakpoints.tablet ? { width: 250 } : { width: "100%" }),
  },
  loginTitleStyle: {
    alignItems: "center",
    color: theme.colors.white,
  },
  buttonOutlineStyle: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
    alignItems: "center",
    marginVertical: 5,
    marginHorizontal: "auto",
    ...(width > theme.breakpoints.tablet ? { width: 250 } : { width: "100%" }),
  },
  buttonTitleOutlineStyle: {
    alignItems: "center",
  },
  buttonContainer: {
    alignItems: "center",
  },
}));
