import {
  InteractionManager,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from "react-native";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Input, makeStyles, Text, useTheme } from "@rneui/themed";
import { FlatList } from "react-native-gesture-handler";
import Market from "containers/Markets/components/Market";
import CustomIcon from "components/UI/CustomIcon";
import { STYLES } from "constants/STYLES";
import ModalRenderer from "components/ModalRenderer";
import NoMessage from "components/UI/NoMessage";

const MarketSearchBottomSheet = ({
  showMarketModal = false,
  setShowMarketModal,
  markets,
  onSelectMarket,
  useViewMode = false,
  marketItemProps = {},
}) => {
  const styles = useStyles();
  const { theme } = useTheme();
  const ref = useRef();
  const searchInputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const { width } = useWindowDimensions();
  const isLargeScreen = useMemo(
    () => Platform.OS === "web" && width >= theme.breakpoints.tablet,
    [width]
  );

  useEffect(() => {
    useViewMode && openModal();
  }, [useViewMode]);

  useEffect(() => {
    showMarketModal && openModal();
  }, [showMarketModal]);

  const openModal = () => {
    ref.current?.handleOpenModalPress();
  };

  const closeModal = () => {
    ref.current?.handleCloseModalPress();
  };

  const displayedMarkets = useMemo(() => {
    return markets?.filter((market) => {
      const shortName = market?.market ? market.market.toLowerCase() : "";
      const fullName = market?.full_name ? market.full_name.toLowerCase() : "";
      return (
        shortName.includes(searchQuery.toLowerCase()) ||
        fullName.includes(searchQuery.toLowerCase())
      );
    });
  }, [JSON.stringify(markets), searchQuery]);

  const onPressMarket = useMemo(() => {
    return typeof onSelectMarket === "function"
      ? (marketId) => {
          closeModal();
          InteractionManager.runAfterInteractions(() => {
            onSelectMarket(marketId);
          });
        }
      : () => {
          closeModal();
          undefined;
        };
  }, [onSelectMarket]);

  const onChangeSearchInput = useCallback(
    (query) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const onCancelPress = useCallback(() => {
    setSearchQuery("");
    searchInputRef.current?.blur();
  }, [setSearchQuery]);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <Market
          market={item}
          key={item.id}
          onPress={onPressMarket}
          {...marketItemProps}
        />
      );
    },
    [onPressMarket, marketItemProps]
  );

  const content = (
    <View
      style={[
        {
          width: "100%",
          flex: 1,
          ...(isLargeScreen && { borderRadius: STYLES.BORDER_RADIUS.CARD }),
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          width: "100%",
        }}
      >
        <View
          style={{
            width: "100%",
            padding: 8,
          }}
        >
          <Input
            ref={searchInputRef}
            placeholder="search markets"
            containerStyle={{
              width: "100%",
              borderBottomWidth: 0,
              maxHeight: 50,
              paddingHorizontal: 0,
            }}
            placeholderTextColor={theme.colors.grey2}
            inputContainerStyle={{
              borderBottomWidth: 0,
              backgroundColor:
                theme.mode === "dark" ? theme.colors.grey4 : theme.colors.grey4,
              borderRadius: 12,
              paddingHorizontal: 12,
            }}
            style={{
              borderBottomWidth: 0,
              color: theme.colors.whiteText,
              outline: "none",
            }}
            cursorColor={theme.colors.whiteText}
            onChangeText={onChangeSearchInput}
            value={searchQuery}
            rightIcon={
              <CustomIcon
                type={"entypo"}
                name={"cross"}
                size={18}
                color={theme.colors.grey2}
                onPress={onCancelPress}
              />
            }
            disableFullscreenUI={true}
          />
        </View>
      </View>
      {displayedMarkets?.length > 0 ? (
        <FlatList
          contentContainerStyle={{
            padding: 12,
          }}
          data={displayedMarkets}
          renderItem={renderItem}
          keyExtractor={({ id }) => id.toString()}
        />
      ) : (
        <View style={styles.noResult}>
          <NoMessage width={150} height={150} />
          <Text style={styles.warningText}>No result found.</Text>
        </View>
      )}
    </View>
  );

  return (
    <ModalRenderer
      ref={ref}
      showModal={showMarketModal}
      setShowModal={setShowMarketModal}
      title={isLargeScreen && !useViewMode && "Markets"}
      content={content}
      useViewMode={useViewMode}
    />
  );
};

export default memo(MarketSearchBottomSheet);

const useStyles = makeStyles((theme) => ({
  noResult: {
    flex: 1,
    justifyContent: "flex-start",
    marginTop: 36,
    alignItems: "center",
  },
  warningText: {
    fontWeight: "300",
    color: theme.colors.grey2,
    marginTop: 15,
  },
}));
