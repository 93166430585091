import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  url: {
    fontSize: 16,
    marginBottom: 30,
    color: 'blue',
    textDecorationLine: 'underline',
  },
  buttonStyle: {
    backgroundColor: theme.colors.primary,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
    alignItems: "center",
    marginBottom: 10,
    width: 200,
  },
  buttonTitleStyle: {
    color: theme.colors.white,
  },
  buttonOutlineStyle: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
    alignItems: "center",
    marginBottom: 10,
    width: 200,
  },
}));
