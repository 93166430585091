import Server from "./server";
import { ROOT } from "constants/ROOT";

const baseUrl = "/api/v1/users/plan-subscriptions";
const paymentUrl = "/api/v1/users/";

//********************** TYPE **********************//

/**
 * Custom data type defining a Payment.
 * @typedef {Object} Payment
 */

//********************** API **********************//

/**
 *
 * @returns {Promise<>} - The payment info
 * @final
 */

export const postPlan = async ({ payload }) => {
  try {
    const url = `${baseUrl}`;
    console.info(`Preparing API request to ${url} with payload:`, payload);
    return await Server.post(url, payload);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

//********************** API **********************//

/**
 *
 * @returns {Promise<Payment>} -
 * @final
 */

export const postClonePlan = async ({ payload }) => {
  try {
    const url = `${baseUrl}/clone`;
    console.info(`Preparing API request to ${url} with payload:`, payload);
    return await Server.post(url, payload);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

//********************** API **********************//

/**
 *
 * @returns {Promise<Payment>} -
 * @final
 */

export const getAllPayments = async () => {
  try {
    const url = `${paymentUrl}/payments`;
    return await Server.get(url);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};
