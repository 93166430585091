import { useQuery } from "react-query";
import configurationsApi from "api/configurations";
import { getSnapshotsHistory } from "api/snapshots";

//********************** TYPE **********************//

/**
 * Custom data type defining a Market.
 * @typedef {Object} Market
 * @property {number} id - The ID of the market.
 * @property {string} provider_id - The ID of the provider associated with the market.
 * @property {string} market - The name of the market.
 * @property {number} rank_id - The ranking of the market.
 */

/**
 * Represents a list of time frames.
 * @typedef {string[]} TimeFrames
 */

/**
 * Represents a list of events.
 * @typedef {string[]} Events
 */

/**
 * Custom data type defining a Configuration.
 * @type {Configuration}
 * @typedef {Array.<Market[], TimeFrames, Events>} Configuration
 */

//********************** FUNCTION **********************//

/**
 * @description Custom hook to fetch configurations data using react-query. That is used for all sections.
 * To implement fetching configurations, utilize the powerful combination of react-query and axios.
 * @returns {Object} An object containing the fetched markets, time frames and bot data.
 * @property {Market[]} Makerts - The fetched markets data.
 * @property {string[]} TimeFrames - The fetched time frames data.
 * @property {string[]} Events - The fetched events data.
 * @property {Function} getMarketNameById - A helper function to get the name of a market by its ID.
 * @throws {Error} - Throws an error if there is an issue fetching the data.
 */
const useConfigurations = () => {
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useQuery("getConfigurationsApi", configurationsApi.getConfigurationsApi);

  const {
    data: snapshotData = [],
    isLoading: isLoadingSnapshots,
    isSucces: isSuccessSnapshots,
  } = useQuery("getDataSnapshots", getSnapshotsHistory, {
    select: (res) => res.data,
    refetchInterval: 60000,
    refetchIntervalInBackground: 60000,
  });

  // console.log("====getConfigurationsApi", data);

  const configurations = data?.data || [];

  const getMarkets = () => configurations[0];

  const getTimeFrames = () => configurations[1];

  const getMarketNameById = (market_id) => {
    let market = getMarkets().filter((item) => item.id == market_id);
    return market[0]?.name || "";
  };

  return {
    markets: getMarkets(),
    time_frame: getTimeFrames(),
    bot: configurations[2],
    isLoading,
    isSuccess,
    snapshotData,
    isLoadingSnapshots,
    isSuccessSnapshots,
    getMarketNameById,
  };
};

export default useConfigurations;
