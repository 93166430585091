import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { Controller } from "react-hook-form";
import { Button, Input, Text, useTheme } from "@rneui/themed";
import useForgotPassword from "./useForgotPassword";
import { useStyles } from "./styles";

/**
 * ForgotPassword component for users to recover their password.
 * This component allows users to enter their email address and initiate
 * the password recovery process by sending a recovery email.
 *
 * @component
 * @returns {React.JSX} The rendered ForgotPassword component.
 */
const ForgotPassword = () => {
  const styles = useStyles();
  const { theme } = useTheme();
  const {
    control,
    errors,
    onSubmit,
    handleSubmit,
    isLoading
  } = useForgotPassword();

  const [emailIsFocused, setEmailIsfocused] = useState(false);

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View>
          <View>
            <Text h3 h3Style={styles.title}>
              Forgot Password
            </Text>
          </View>
          <View>
            <Text style={styles.description}>
              Please enter the email address associated with your account.
            </Text>

            <Text style={styles.description}>
              We will send you an email with instructions on how to reset your password. If you have any trouble
              receiving the email, please check your spam or junk folder.
            </Text>

            <Text style={styles.description}>
              If you've entered the correct email and still haven't received the recovery email, please contact our
              support team.
            </Text>

            <Text style={styles.description}>
              Thank you for using our services.
            </Text>
          </View>
        </View>
        <View style={styles.inputsWrapper}>
          <View style={styles.formItem}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChangeText={onChange}
                  value={value}
                  label="Email Address"
                  placeholder="example@gmail.com"
                  inputStyle={[styles.inputStyle, { outline: "none" }]}
                  labelStyle={[
                    {
                      color: emailIsFocused
                        ? theme.colors.primary
                        : theme.colors.whiteText,
                    },
                    styles.inputLabelStyle,
                  ]}
                  containerStyle={styles.inputContainerStyle}
                  inputContainerStyle={[
                    {
                      borderColor: emailIsFocused
                        ? theme.colors.primary
                        : "transparent",
                    },
                    styles.inputInputContainerStyle,
                  ]}
                  onFocus={() => setEmailIsfocused(true)}
                  onBlur={() => {
                    onBlur();
                    setEmailIsfocused(false);
                  }}
                />
              )}
              name="username"
            />
            {errors["username"] && (
              <Text style={styles.errorText}>
                {errors["username"]?.message}
              </Text>
            )}
          </View>
        </View>

        <View>
          <Button
            buttonStyle={styles.loginButtonStyle}
            titleStyle={styles.loginTitleStyle}
            style={{ marginTop: 10 }}
            onPress={handleSubmit(onSubmit)}
            disabled={isLoading}
            icon={
              isLoading && (
                <ActivityIndicator size={"small"} color={theme.colors.white}/>
              )
            }
            title={"Send"}
          />
        </View>
      </View>
    </View>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};
