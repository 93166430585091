import { makeStyles } from "@rneui/themed";
import { StyleSheet } from "react-native";

const useStyles = makeStyles((theme, alert = {}) => ({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 0,
    marginBottom: 12,
  },
  row: {
    flexDirection: "row",
    // alignItems: "flex-start",
    alignItems: "center",
    justifyContent: "space-between",
  },

  text: {
    color: theme.colors.primary,
  },
  subline: {
    fontSize: 12,
  },
  badge: {
    borderRadius: 100,
    width: 10,
    height: 10,
    // marginVertical: 5,
  },
  header: {
    // textAlign: "center",
  },
  card: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,

    elevation: 0,
    borderWidth: 0,

    borderRadius: 12,
    margin: 0,
    backgroundColor: "transparent",
  },

  listCard: {
    backgroundColor: theme.colors.white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.greyOutline,
    margin: 8,
  },

  mr: {
    marginRight: 5,
    color: theme.colors.grey2,
  },
  iconStyle: {
    alignItems: "center",
  },
  textRow: {
    marginVertical: 5,
  },
  price: {
    color: theme.colors.grey1,
  },
  time: {
    color: theme.colors.grey1,
    fontSize: 14,
  },
  title: {
    color: theme.colors.primary,
  },
  whiteText: {
    color: theme.colors.whiteText,
  },
  footer: {
    marginTop: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
