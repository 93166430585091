import { createContext, useContext } from "react";

export const AlertsContext = createContext()

export const useAlertsContext = () => {
  const alertsContext = useContext(AlertsContext);
  if (!alertsContext) {
    throw new Error("useAlertContext must be used within a AlertsContext.Provider");
  }

  return alertsContext;
};