import * as React from "react";
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

const NeedleIcon = (props) => {
  return (
    <Svg width="35" height="35" viewBox="0 0 112.5 112.499997" {...props}>
      <Defs>
        <ClipPath id="c08d0211ff">
          <Path
            d="M 1 48 L 107 48 L 107 65 L 1 65 Z M 1 48 "
            clipRule="nonzero"
          />
        </ClipPath>
        <ClipPath id="7e38ef56a3">
          <Path
            d="M 55.996094 0.824219 L 111.433594 56.261719 L 55.996094 111.699219 L 0.558594 56.261719 Z M 55.996094 0.824219 "
            clipRule="nonzero"
          />
        </ClipPath>
        <ClipPath id="fbc097fdb6">
          <Path
            d="M 55.996094 0.824219 L 111.433594 56.261719 L 55.996094 111.699219 L 0.558594 56.261719 Z M 55.996094 0.824219 "
            clipRule="nonzero"
          />
        </ClipPath>
        <ClipPath id="bb433007dc">
          <Path
            d="M 55.996094 0.824219 L 111.433594 56.261719 L 55.996094 111.699219 L 0.558594 56.261719 Z M 55.996094 0.824219 "
            clipRule="nonzero"
          />
        </ClipPath>
      </Defs>
      <G clipPath="url(#c08d0211ff)">
        <G clipPath="url(#7e38ef56a3)">
          <G clipPath="url(#fbc097fdb6)">
            <G clipPath="url(#bb433007dc)">
              <Path
                fill="currentColor"
                d="M 106.933594 56.261719 C 106.933594 51.742188 102.050781 48.078125 96.023438 48.078125 C 95.65625 48.078125 95.289062 48.09375 94.929688 48.121094 L 94.929688 48.117188 L 2.171875 55.058594 L 2.171875 55.074219 C 1.894531 55.09375 1.625 55.195312 1.410156 55.410156 C 0.941406 55.878906 0.941406 56.644531 1.410156 57.113281 C 1.625 57.324219 1.894531 57.429688 2.171875 57.449219 L 2.171875 57.460938 L 94.929688 64.402344 C 95.289062 64.429688 95.65625 64.441406 96.023438 64.445312 C 102.050781 64.445312 106.933594 60.78125 106.933594 56.261719 Z M 94.320312 60.183594 C 90.140625 60.183594 86.753906 58.425781 86.753906 56.261719 C 86.753906 54.097656 90.140625 52.339844 94.320312 52.339844 C 98.5 52.339844 101.886719 54.097656 101.886719 56.261719 C 101.886719 58.425781 98.5 60.183594 94.320312 60.183594 Z M 94.320312 60.183594 "
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
export default React.memo(NeedleIcon, (prevProps, nextProps) => {
  return (
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.color === nextProps.color
  );
});
