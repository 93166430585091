import React, { forwardRef, useCallback, useImperativeHandle, useRef, } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { useTheme } from "@rneui/themed";
import DialogRenderer from "components/ModalRenderer/components/DialogRenderer";
import BottomSheetRenderer from "components/ModalRenderer/components/BottomSheetRenderer";

/**
 * A modal renderer component that conditionally renders a DialogRenderer or a BottomSheetRenderer
 * based on the screen size. It is designed to be used with a forwardRef to allow parent components
 * to programmatically open and close the modal.
 *
 * @component
 *
 * @param {boolean} showModal - A boolean indicating whether the modal is visible.
 * @param {Function} setShowModal - A function to update the visibility of the modal.
 * @param {string | React.Element} title - The title of the modal.
 * @param {React.Element} content - The content of the modal.
 * @param {boolean} useAnimatedSnapPoints - Indicates whether to use animated snap points for the bottom sheet.
 * @param {boolean} useViewMode - A boolean flag to determine if the modal should be displayed as a view component.
 * @returns {React.Element} The rendered modal component.
 **/
const ModalRenderer = forwardRef(
  (
    {
      showModal,
      setShowModal,
      title,
      content,
      useAnimatedSnapPoints = false,
      useViewMode = false,
    },
    ref
  ) => {
    const { theme } = useTheme();
    const { width } = useWindowDimensions();
    const bottomSheetModalRef = useRef(null);
    const isLargeScreen =
      Platform.OS === "web" && width >= theme.breakpoints.tablet;

    const handleOpenModalPress = useCallback(() => {
      setShowModal(true);
      bottomSheetModalRef.current?.handleOpenBottomSheet();
    }, []);

    const handleCloseModalPress = () => {
      setShowModal(false);
      bottomSheetModalRef.current?.handleCloseBottomSheet();
    };

    useImperativeHandle(ref, () => ({
      handleOpenModalPress,
      handleCloseModalPress,
    }));

    return isLargeScreen && !useViewMode ? (
      <DialogRenderer
        {...{ title, content, handleCloseModalPress, showModal }}
      />
    ) : (
      <BottomSheetRenderer
        ref={bottomSheetModalRef}
        {...{ title, content, handleCloseModalPress, useAnimatedSnapPoints, useViewMode }}
      />
    );
  }
);

export default ModalRenderer;
