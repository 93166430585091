import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { View, Text, TouchableOpacity } from "react-native";
import { Card } from "@rneui/themed";
import useStyles from "./styles";

/**
 * Component for rendering an TouchableOpacity Card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.id - The props.id is key seprator.
 * @param {Function} props.onPress - The props.onPress for navigate to another screen.
 * @param {Node} props.children - The props.children for show content of item.
 * @returns {React.JSX} - The rendered component.
 */

function TouchableCard({ id, onPress, style = {}, title, children }) {
  const styles = useStyles();

  const rootStyle = useMemo(() => {
    return [styles.root, style.root];
  }, [JSON.stringify[styles.root], JSON.stringify(style.root)]);

  const cardStyle = useMemo(() => {
    return [styles.wrapperStyle, style.wrapper];
  }, [JSON.stringify[styles.wrapperStyle], JSON.stringify(style.wrapper)]);

  const cardContainerStyle = useMemo(() => {
    return [styles.card, style.card];
  }, [JSON.stringify[styles.card], JSON.stringify(style.card)]);

  const viewStyle = useMemo(() => {
    return [styles.container, style.container];
  }, [JSON.stringify[styles.container], JSON.stringify(style.container)]);

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <TouchableOpacity onPress={onPress} style={rootStyle}>
      <Card
        wrapperStyle={cardStyle}
        key={id}
        containerStyle={cardContainerStyle}
      >
        <View style={viewStyle}>{childrenMemo}</View>
      </Card>
    </TouchableOpacity>
  );
}

export default TouchableCard;

TouchableCard.propTypes = {
  id: PropTypes.number,
  onPress: PropTypes.func,
  children: PropTypes.node,
};

TouchableCard.defaultProps = {
  id: null,
  onPress: undefined,
  children: null,
};
