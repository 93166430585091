import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme, { width, bottom }) => ({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    margin: STYLES.MARGIN.SX,
  },
  card: {
    width: "100%",
    flex: 1,
    flexDirection: width > theme.breakpoints.laptop ? "row" : "column",
    justifyContent: "space-between",
    alignItems: width > theme.breakpoints.laptop ? "flex-start" : "baseline",
    marginBottom: bottom,
    padding: width >= theme.breakpoints.laptop ? 24 : 0,
  },
  content: {
    width: width <= theme.breakpoints.laptop ? "100%" : "45%",
    marginBottom: 12,
    backgroundColor:
      theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    borderRadius: STYLES.BORDER_RADIUS.LG,
    padding: STYLES.PADDING.MD,
  },
  form: {
    width: width <= theme.breakpoints.laptop ? "100%" : "45%",
    paddingVertical: STYLES.PADDING.SM,
    marginBottom: 20,
    backgroundColor:
      theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    borderRadius: STYLES.BORDER_RADIUS.LG,
    padding: STYLES.PADDING.MD,
  },
  text: {
    color:
      theme.mode === "dark"
        ? theme.colors.whiteText
        : STYLES.COLORS.TEXT.DARK_GRAY,
    textAlign: "justify",
  },
  addAlertTitle: {
    color: theme.colors.black,
    marginBottom: 12,
    width: "100%",
  },
}));
