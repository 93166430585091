export const SCREENS_NAMES = {
  ON_BOARDING: "on_boarding",
  HOME: "home",
  ALERTS: "alerts",
  MARKETS: "markets",
  STRATEGIES: "strategies",
  SETTINGS: "settings",
  VIEW_STRATEGY: "view-strategy",
  VIEW_CHART: "view-chart",
  FULL_SCREEN_VIEW_CHART: "full-screen-view-chart",
  LOGIN: "login",
  REGISTER: "register",
  FORGOT_PASSWORD: "forgot-password",
  ACCOUNT: "account",
  NOTIFICATIONS: "notifications",
  PRICING: "pricing",
  PAYMENT: "payment",
  PAYMENTS_HISTORY: "payments-history",
  PREFERENCES: "preferences",
  CREATE_ALERT: "create-alert",
  DELETE_ACCOUNT: "delete-account",
  EMAIL_VERIFICATION: "email-verification",
  NOT_FOUND: 'not-found',
};
