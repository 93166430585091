import PropTypes from "prop-types";
import {
  ActivityIndicator,
  Alert,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import React, { useCallback } from "react";
import { Card, Dialog, Image, useTheme } from "@rneui/themed";
import useStyles from "./ActiveAlertStyles";
import useActiveAlert from "./useActiveAlert";
import { Icon } from "@rneui/base";
import { getMarketImageUrl } from "api/media";
import Checkbox from "expo-checkbox";

/**
 * Component for rendering an active alert.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Alert} props.alert - The alert object to be displayed.
 * @param {Function} props.getStrategyNameById - Retrieves the name of a strategy by its ID.
 * @returns {React.JSX} - The rendered component.
 */

function ActiveAlert({
  alert,
  getStrategyNameById,
  onChangeActiveAlertSelect,
  isSelected,
  isEditing,
}) {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const styles = useStyles({ alert });
  const {
    isDialogVisible,
    handleCancel,
    handleConfirm,
    loading,
    token,
    showConfirmDialog,
  } = useActiveAlert();

  const handleCheckboxChange = useCallback(() => {
    onChangeActiveAlertSelect(alert.id);
  }, [onChangeActiveAlertSelect, alert.id]);

  return (
    <Card
      wrapperStyle={{ borderWidth: 0 }}
      key={alert.id}
      containerStyle={styles.card}
    >
      <View style={styles.container}>
        <View style={{ flexDirection: "row" }}>
          {isEditing && (
            <Checkbox
              style={{ marginRight: 10 }}
              onValueChange={handleCheckboxChange}
              value={isSelected}
              color={
                theme.mode === "light"
                  ? theme.colors.primary
                  : theme.colors.grey2
              }
            />
          )}
          <View>
            <Image
              source={{
                uri: getMarketImageUrl({ market: alert.market }),
                cache: true,
              }}
              style={{
                width: 20,
                height: 20,
                borderRadius: 50,
                marginRight: 6,
              }}
            />
          </View>
          <View>
            <Text style={styles.text}>{alert.market} </Text>
            <Text style={[styles.text, { color: theme.colors.grey1 }]}>
              {getStrategyNameById(alert.strategy_id)}
            </Text>
          </View>
          <Text
            h4
            style={[styles.text, { color: theme.colors.grey1, marginLeft: 6 }]}
          >
            {alert.time_frame}
          </Text>
        </View>

        <Dialog
          isVisible={isDialogVisible}
          onBackdropPress={handleCancel}
          overlayStyle={{
            ...(width < theme.breakpoints.tablet
              ? { width: "80%" }
              : { width: "50%" }),
          }}
        >
          <Dialog.Title
            title="Confirm Alert Removal"
            titleStyle={{ color: theme.colors.black }}
          />
          <Text style={{ color: theme.colors.grey1 }}>
            Are you sure you want to remove this alert? This action cannot be
            undone.
          </Text>
          <Dialog.Actions>
            <Dialog.Button
              type="outline"
              title="Yes, Remove"
              buttonStyle={{
                border: `2px solid ${theme.colors.error}`,
                borderColor: theme.colors.error,
              }}
              titleStyle={{ color: theme.colors.error }}
              onPress={handleConfirm}
            />
            <Dialog.Button
              title="Cancel"
              titleStyle={{ color: theme.colors.black }}
              onPress={handleCancel}
            />
          </Dialog.Actions>
        </Dialog>
      </View>
    </Card>
  );
}

export default ActiveAlert;

/**
 * Prop types for the ActiveAlert component.
 */
ActiveAlert.propTypes = {
  /**
   * The alert object to be displayed.
   */
  alert: PropTypes.object,
  /**
   * Retrieves the name of a strategy by its ID.
   */
  getStrategyNameById: PropTypes.func,
};

/**
 * Default props for the ActiveAlert component.
 */
ActiveAlert.defaultProps = {
  alert: {},
  getStrategyNameById: undefined,
};
