import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

const useStyles = makeStyles((theme) => ({
  gradient: {
    borderRadius: STYLES.BORDER_RADIUS.LG,
  },
  buttonStyle: {
    backgroundColor: "transparent",
    paddingVertical: STYLES.PADDING.SX,
    width: "100%",
  },
  buttonText: {
    fontSize: 16, //title
    fontWeight: "bolder",
    textAlign: "center",
    color: "#FFF",
    minWidth: 200,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  desText: {
    fontSize: 10, // text
    textAlign: "center",
    color: theme.colors.white,
  },
  iconStyle: {
    color: theme.colors.warning,
  },
}));

export default useStyles;
