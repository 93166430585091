import React, { memo } from "react";
import { Animated, StyleSheet, View } from "react-native";
import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";
import { useTheme } from "@rneui/themed";
import NeedleIcon from "components/UI/NeedleIcon";

/**
 * A React component that renders a half doughnut chart with an arrow icon pointing to a value.
 * The arrow icon represents the percentage value on the doughnut.
 *
 * @component
 * @example
 * ```
 * <HalfDoughnut value={50} size={200} strokeWidth={10} />
 *
 * @param {Object} props - The props object.
 * @param {number} props.value - The percentage value to point to on the doughnut. Must be between  0 and  100.
 * @param {number} [props.size=100] - The size of the doughnut in pixels.
 * @param {number} [props.strokeWidth=5] - The width of the doughnut's stroke in pixels.
 *
 * @returns {React.Element} The rendered half doughnut chart with an arrow icon.
 */
const HalfDoughnut = ({ value, size = 100, strokeWidth = 6 }) => {
  const { theme } = useTheme();
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const angle = (value * 180) / 100 - 90; // Convert value to angle in degrees

  // const [rotation] = useState(new Animated.Value(0));
  //
  // useEffect(() => {
  //   const animation = Animated.loop(
  //     Animated.timing(rotation, {
  //       toValue: 5,
  //       duration: 5000,
  //       useNativeDriver: true
  //     })
  //   );
  //
  //   animation.start();
  //
  //   return () => {
  //     animation.stop();
  //   };
  // }, []);
  //
  // const interpolatedRotation = rotation.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: ['0deg', '360deg']
  // });

  const styles = StyleSheet.create({
    container: {
      position: "relative",
      width: size,
      height: size / 2,
    },
    doughnut: {
      position: "absolute",
      top: 0,
      left: 0,
    },
    needle: {
      position: "absolute",
      width: "100%",
      height: "auto",
    },
  });

  return (
    <View style={styles.container}>
      <Svg width={size} height={size / 2} style={styles.doughnut}>
        <Defs>
          <LinearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <Stop offset="0%" stopColor={theme.colors.short} />
            <Stop offset="100%" stopColor={theme.colors.long} />
          </LinearGradient>
        </Defs>
        {/* Gradient part */}
        <Path
          d={`M ${size / 2} ${
            strokeWidth / 2
          } a ${radius} ${radius}   0   0   1   0 ${2 * radius}`}
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          fill="none"
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </Svg>
      <Animated.View
        style={[
          styles.needle,
          {
            transform: [
              { translateX: 0 }, // Move left
              { translateY: size / 4 }, // Move up
              { rotate: `${angle + 90}deg` }, // Apply rotation
              { translateX: size / 7 }, // Move right
              { translateY: 0 }, // Move down
            ],
          },
        ]}
      >
        <NeedleIcon
          width={size / 2.5}
          height={size / 2.5}
          color={theme.colors.black}
        />
      </Animated.View>
    </View>
  );
};

export default memo(HalfDoughnut, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.size === nextProps.size &&
    prevProps.strokeWidth === nextProps.strokeWidth
  );
});
