import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { InteractionManager, Platform, useWindowDimensions, View, } from "react-native";
import { Button, Icon, Text, useTheme } from "@rneui/themed";
import CreateAlertModal from "components/CreateAlertModal";
import AlertsView from "containers/Alerts/components/AlertsView";
import { useFetchActiveAlerts } from "containers/Alerts/hooks/useFetchActiveAlerts";
import { STYLES } from "constants/STYLES";
import ModalRenderer from "components/ModalRenderer";

const AlertsModal = (
  {
    markets,
    marketId,
    strategies,
    getStrategyNameById,
    setSignalTime,
    handleChange,
    showHeader = true,
    showAlertModal,
    setShowAlertModal,
    useViewMode = false
  }
) => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const ref = useRef();
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
  const contentWidth = !isLargeScreen && STYLES.BOTTOM_SHEET.MAX_WIDTH

  const isLargeScreen =
    Platform.OS === "web" && width >= theme.breakpoints.tablet;
  const {
    data = {},
    isLoading,
    isSuccess,
    refetch,
    remove,
    isRefetching,
  } = useFetchActiveAlerts();

  const createAlertOnPress = () => {
    setShowCreateAlertModal(true);
  };

  useEffect(() => {
    showAlertModal && openModal();
  }, [showAlertModal]);

  const openModal = () => {
    ref.current?.handleOpenModalPress();
  };

  const closeModal = () => {
    ref.current?.handleCloseModalPress();
  };

  const marketName = useMemo(() => {
    if (!markets) return;
    return markets.find((market) => market.id === marketId)?.market;
  }, [markets, marketId]);

  const activeAlerts = useMemo(() => data?.data || [], [data]);
  const filteredActiveAlerts = useMemo(
    () =>
      activeAlerts.filter((activeAlert) => activeAlert.market === marketName),
    [activeAlerts, marketId]
  );

  const navigateToChartView = useCallback(
    (alert) => {
      closeModal();
      const strategyId = strategies.find(
        (strategy) => strategy.strategy_id === alert.strategy_id
      )?.id;
      InteractionManager.runAfterInteractions(() => {
        handleChange("TIME_FRAME", { value: alert.time_frame });
        handleChange("STRATEGY", { value: strategyId });
        setTimeout(() => setSignalTime(alert.time), 100);
      });
    },
    [setSignalTime, handleChange]
  );

  const contextValue = useMemo(() => {
    return {
      activeAlerts: filteredActiveAlerts,
      markets,
      marketName,
      strategies,
      getStrategyNameById,
      navigateToChartView,
      listView: true,
      isLoading,
      isSuccess,
      refetch,
      remove,
      isRefetching,
      hasCreateAlert: true
    };
  }, [
    // navigation,
    filteredActiveAlerts,
    markets,
    strategies,
    getStrategyNameById,
    navigateToChartView,
  ]);

  const content = (
    <View
      style={[
        {
          width: isLargeScreen ? "100%" : STYLES.BOTTOM_SHEET.MAX_WIDTH,
          flex: 1,
          ...(isLargeScreen && { borderRadius: STYLES.BORDER_RADIUS.CARD }),
        },
      ]}
    >
      {showHeader && (
        <View
          style={{
            height: 60,
            paddingVertical: 12,
            paddingHorizontal: 24,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit
            style={{
              maxWidth: "50%",
              color: theme.colors.whiteText,
              fontSize: 20
            }}
          >
            {marketName}
          </Text>
          <Button
            icon={
              <Icon
                type="material-community"
                name="bell-plus-outline"
                color={theme.colors.white}
                size={20}
              />
            }
            title={"Create Alert"}
            titleStyle={{
              fontSize: 14,
              fontWeight: "semibold",
              marginLeft: 5,
              color: theme.colors.white,
            }}
            onPress={createAlertOnPress}
            titleProps={{
              adjustsFontSizeToFit: true,
            }}
          ></Button>
        </View>
      )}
      <AlertsView contentWidth={contentWidth} contextValue={contextValue}/>
      <CreateAlertModal market={marketId} showCreateAlertModal={showCreateAlertModal} setShowCreateAlertModal={setShowCreateAlertModal}/>
    </View>
  )

  return (
    <ModalRenderer
      ref={ref}
      showModal={showAlertModal}
      setShowModal={setShowAlertModal}
      content={content}
      useViewMode={useViewMode}
    />
  );
}

const compare = (key, prevProps, nextProps) =>
  JSON.stringify(prevProps[key]) === JSON.stringify(nextProps[key]);

export default memo(AlertsModal, (prevProps, nextProps) => {
  compare("marketId", prevProps, nextProps) &&
  compare("markets", prevProps, nextProps) &&
  compare("strategies", prevProps, nextProps) &&
  compare("strategyId", prevProps, nextProps) &&
  prevProps.getStrategyNameById === nextProps.getStrategyNameById &&
  prevProps.setSignalTime === nextProps.setSignalTime &&
  prevProps.handleChange === nextProps.handleChange;
});
