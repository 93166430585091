import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

const useStyles = makeStyles((theme, width) => ({
  wrapper: {
    padding: STYLES.PADDING.MD,
    maxWidth: 800,
    margin: "auto"
  },
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: STYLES.PADDING.LG,
  },
  title: {
    color: theme.colors.primary,
    fontWeight: "bold",
    fontSize: 24,
  },
  description: {
    fontSize: 16,
    color: theme.colors.grey2,
    marginBottom: 5,
  },
  iconStyle: {
    marginRight: 12,
  },
  actionCard: {
    marginVertical: STYLES.MARGIN.SM,
    ...(width < theme.breakpoints.tablet ? { flexDirection: "column" } : { flexDirection: "row" }),
  },
  buttonText: {
    fontSize: 16, // TITLE
    color: theme.colors.white,
  },
  buttonContainer: {
    ...(width < theme.breakpoints.tablet ? { width: "100%" } : { width: "50%" }),
  },
  button: {
    ...(width < theme.breakpoints.tablet ? { marginRight: 0 } : { marginRight: STYLES.MARGIN.SX }),
    marginVertical: STYLES.MARGIN.SX,
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
  },
}));

export default useStyles;
