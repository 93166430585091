import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { Text, ListItem } from "@rneui/themed";
import Icon from "components/UI/Icon";
import useStyles from "./styles";

const DescriptionsList = ({ descriptionsList, iconName, listContainerStyle, listContentContainerStyle }) => {
  const styles = useStyles();

  return (
    <ListItem containerStyle={[styles.listContainerStyle, listContainerStyle]}>
      <View>
        {descriptionsList.map((description, i) => (
          <View style={[styles.listContentContainerStyle, listContentContainerStyle]} key={i}>
            <Icon
              name={iconName}
              type="material-community"
              iconStyle={{ ...styles.iconListStyle }}
              size={16}
              isClickable={false}
            />
            <Text style={styles.listItemText}>{description}</Text>
          </View>
        ))}
      </View>
    </ListItem>
  );
};

export default DescriptionsList;

DescriptionsList.propTypes = {
  descriptionsList: PropTypes.arrayOf(PropTypes.string),
  iconName: PropTypes.string,
};

DescriptionsList.defaultProps = {
  descriptionsList: [],
  iconName: "check-circle",
};
