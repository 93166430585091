import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { View, useWindowDimensions } from "react-native";
import { Text, Switch } from "@rneui/themed";
import Icon from "components/UI/Icon";
import { useTheme } from "hooks/useTheme";
import useStyles from "./styles";
import { AppThemeContext } from "App";

/**
 * Component for rendering an Setting Item.
 * This screen show item of screens when user click on navigate to its screen.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.setting - The setting to be displayed.
 * @returns {React.JSX} - The rendered component.
 */

function SwitchItem({ setting }) {
  const { width } = useWindowDimensions();
  const styles = useStyles(width);
  const { mode, setMode } = useContext(AppThemeContext);
  const toggleTheme = () => {
    setMode(!!mode && mode === "light" ? "dark" : "light");
  };

  return (
    <View style={styles.container1}>
      <View style={styles.container2}>
        <Icon
          name={setting.ICON}
          type="material-community"
          iconStyle={styles.iconStyle}
          size={22}
          isClickable={false}
        />
        <View style={styles.row}>
          <Text style={styles.title} numberOfLines={1}>
            {setting.TITLE}
          </Text>
          <Text style={styles.subTitle} numberOfLines={2}>
            {setting.TEXT}
          </Text>
        </View>
      </View>
      <Switch
        value={mode === "dark"}
        onValueChange={toggleTheme}
        style={styles.iconBtnStyle}
      />
    </View>
  );
}

export default SwitchItem;

SwitchItem.propTypes = {
  setting: PropTypes.object.isRequired,
};

SwitchItem.defaultProps = {
  setting: {},
};
