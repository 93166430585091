import React from "react";
import PropTypes from "prop-types";
import { Clipboard, Platform, useWindowDimensions, View } from "react-native";
import { Card, Text, useTheme } from "@rneui/themed";
import useStyles from "./styles";
import Icon from "components/UI/Icon";
import moment from "moment";
import { STYLES } from "constants/STYLES";

function Payment({ payment }) {
  const { width } = useWindowDimensions();
  const styles = useStyles(width);

  const { theme } = useTheme();

  const lowerStatus = payment.status.toLowerCase(); // "confirmed"
  const capitalizedStatus = lowerStatus.charAt(0).toUpperCase() + lowerStatus.slice(1); // "Confirmed"

  const copyToClipBoard = () => {
    const text = payment.transaction_hash
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(text);
    } else {
      Clipboard.setString(text);
    }
  }

  return (
    <Card
      wrapperStyle={styles.wrapper}
      containerStyle={styles.card}
      id={payment.transaction_hash}
      title={payment.amount}
    >

      <View style={styles.container}>
        {/*Amount*/}
        <View style={styles.row}>
          <View style={styles.col}>
            <Text>
              <Icon
                name="currency-usd"
                type="material-community"
                iconStyle={{ color: theme.colors.whiteText }}
                size={18}
                isClickable={false}
              />
              {payment.amount}
            </Text>
          </View>

          {/*time*/}
          <View style={[styles.col, styles.colEnd]}>
            <Text>
              {moment(payment.pay_time).format('YYYY-MM-DD hh:mm')}
            </Text>
          </View>
        </View>

        {/*Description*/}
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              Desc:
            </Text>
            <Text style={[styles.value,{width: 100}]} numberOfLines={5}>
              {payment.description}
            </Text>
          </View>

          {/*Tx Hash*/}
          <View style={[styles.col, styles.colEnd]}>
            <Text style={styles.label}>
              Tx Hash:
            </Text>
            <Text style={styles.value}>
              {
                payment.transaction_hash?.length >= 10
                  ? payment.transaction_hash?.slice(0, 4) +
                  '...' +
                  payment.transaction_hash?.slice(-4)
                  : payment.transaction_hash
              }
              <Icon
                name="content-copy"
                type="material-community"
                iconStyle={{ color: theme.colors.whiteText, marginLeft: 5 }}
                size={15}
                isClickable={true}
                onPress={copyToClipBoard}
              />
            </Text>
          </View>
        </View>

        {/*status*/}
        <View style={[styles.row, styles.status]}>
          <View style={styles.col}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: payment.status === "CONFIRMED"
                  ? `${theme.colors.success}33`
                  : payment.status === "PENDING"
                    ? `${theme.colors.warning}33`
                    : `${theme.colors.error}33`,
                borderRadius: STYLES.BORDER_RADIUS.OVAL,
                color: payment.status === "CONFIRMED"
                  ? theme.colors.success
                  : payment.status === "PENDING"
                    ? theme.colors.warning
                    : theme.colors.error,
                flexDirection: "row",
                fontWeight: "700",
                paddingHorizontal: 10,
                paddingVertical: 5,
                width: 120,
              }}>
              <Icon
                name={payment.status === "CONFIRMED"
                  ? "progress-check"
                  : payment.status === "PENDING"
                    ? "progress-clock"
                    : "progress-close"
                }
                color={payment.status === "CONFIRMED"
                  ? theme.colors.success
                  : payment.status === "PENDING"
                    ? theme.colors.warning
                    : theme.colors.error}
                type="material-community"
                iconStyle={{ marginRight: 5 }}
                size={15}
                isClickable={false}
              />
              {capitalizedStatus}
            </Text>
          </View>
        </View>
      </View>
    </Card>
  );
}

export default Payment;

Payment.propTypes = {
  payment: PropTypes.object,
};

Payment.defaultProps = {
  payment: {},
};
