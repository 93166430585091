import React from "react";
import RegisterContainer from "containers/Register";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { View } from "react-native";

const Register = () => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
      }}
    >
      <RegisterContainer />
    </View>
  );
};

export default Register;
