import React, { useEffect, useState } from "react";
import NetInfo from "@react-native-community/netinfo";
import NetInfoStatus from "components/NetInfoStatus";

const useInternetCheck = () => {
  const [hasInternet, setHasInternet] = useState(true);

  useEffect(() => {
    const netInfoSubscription = NetInfo.addEventListener((state) => {
      setHasInternet(state.isConnected);
    });
    return () => {
      netInfoSubscription();
    };
  }, []);

  return hasInternet;
};

const withInternetCheck = (WrappedComponent) => {
  return (props) => {
    const hasInternet = useInternetCheck();
    return hasInternet ? <WrappedComponent {...props} /> : <NetInfoStatus />;
  };
};

export default withInternetCheck;
