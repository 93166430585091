import server from "./server";

const basePlansUrl = "/api/v1/plans";
const baseSetBasicPlanUrl = "api/v1/users/plan-subscriptions/active";

export const getAllPlans = async () => {
  return await server.get(basePlansUrl);
};

export const setBasicPlan = async () => {
  try {
    const response = await server.get(baseSetBasicPlanUrl);
    return response;
  } catch (e) {
    console.log("=====error setBasicPlan", e);
  }
};
