import { useTheme } from "@rneui/themed";
import AlertNotificationToast from "components/AlertNotificationToast";
import { BaseToast } from "react-native-toast-message";

export const toastConfigs = {
  alertNotification: AlertNotificationToast,
  error: (props) => {
    const { theme } = useTheme();
    return (
      <BaseToast
        {...props}
        style={{ borderLeftColor: theme.colors.error }}
        contentContainerStyle={{
          backgroundColor:
            theme.mode === "light" ? theme.colors.white : theme.colors.grey4,
        }}
        text1Style={{ color: theme.colors.whiteText }}
        text2Style={{ color: theme.colors.grey2 }}
      />
    );
  },
};
