import { useTheme } from "@rneui/themed";
import * as React from "react";
import { useWindowDimensions } from "react-native";
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  Path,
  RadialGradient,
} from "react-native-svg";
import { View } from "react-native";
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const GlowingBackgroundGradient = (props) => {
  const { width, height } = useWindowDimensions();
  const { theme } = useTheme();
  return (
    <View style={{ position: "absolute", flex: 1, width, height }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        {...props}
      >
        <Defs>
          {theme.mode === "dark" ? (
            <LinearGradient
              id="a"
              x1="50%"
              x2="50%"
              y1="0%"
              y2="100%"
              gradientTransform="rotate(150 .5 .5)"
            >
              <Stop offset="0%" stopColor="#3A97A3" />
              <Stop offset="100%" stopColor="#282828" />
            </LinearGradient>
          ) : (
            <RadialGradient id="a">
              <Stop offset="0%" stopColor="#D5E4E6" />
              <Stop offset="100%" stopColor="#B3DBE0" />
            </RadialGradient>
          )}
        </Defs>
        <Path
          fill="url(#a)"
          d={`M0 0h${width}v${height}H0z`}
          filter="url(#b)"
        />
      </Svg>
    </View>
  );
};
export default GlowingBackgroundGradient;
