import { makeStyles } from "@rneui/themed";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 36,
  },
  wrapper: {
    backgroundColor: theme.colors.white,
    padding: 12,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  titleWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bodyWrapper: {
    marginVertical: 6,
  },
  footerWrapepr: {
    marginTop: 12,
  },
  actionsWrapper: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  actionBtn: {
    marginHorizontal: 5,
  },
  dismissBtnTitleStyle: {
    color: theme.colors.error,
  },
  viewBtnStyle: {
    borderRadius: 8,
  },
  viewBtnTitleStyle: {
    color: theme.colors.white,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  eventText: {
    color: theme.colors.grey2,
  },
}));
