import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: STYLES.BOTTOM_SHEET.MAX_WIDTH,
    zIndex: 4,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dropDownWrapper: {
    marginBottom: 24,
  },
  marketDropDown: {
    zIndex: 3,
  },
  strategyDropDown: {
    zIndex: 2,
  },
  timeFrameDropDown: {
    zIndex: 1,
  },
  addAlertBtn: {
    width: "100%",
  },
  addAlertActionBtn: {
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    marginBottom: 10,
  },
  addAlertActionBtnTitleStyle: {
    marginLeft: 5,
    color: theme.colors.white,
  },
  errorText: {
    paddingHorizontal: 6,
    color: theme.colors.error,
  },
}));
