import React, { useState } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Controller } from "react-hook-form";
import { Button, Icon, Input, Text, useTheme } from "@rneui/themed";
import useRegister from "./useRegister";
import { useStyles } from "./styles";
import { TouchableOpacity } from "react-native-gesture-handler";

/**
 * Component
 * @component
 * @param {Object} props - The component props.
 * @returns {React.JSX} - The rendered component.
 */

const Register = () => {
  const styles = useStyles();
  const { theme } = useTheme();
  const { control, errors, onSubmit, handleSubmit, isLoading } = useRegister();

  const [emailIsFocused, setEmailIsfocused] = useState(false);
  const [passwordIsFocused, setPasswordIsfocused] = useState(false);
  const [confirmIsFocused, setConfirmIsFocused] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [passwordConfirmIsVisible, setPasswordConfirmIsVisible] =
    useState(false);

  return (
    <ScrollView>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View>
            <View>
              <Text h3 h3Style={styles.title}>
                Register
              </Text>
            </View>
            <View>
              <Text style={styles.description}>
                You only need an email address, to create a valid account.
              </Text>
            </View>
          </View>
          <View style={styles.inputsWrapper}>
            <View style={styles.formItem}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    renderErrorMessage={false}
                    onChangeText={onChange}
                    value={value}
                    label="Email Address"
                    placeholder="example@gmail.com"
                    inputStyle={[styles.inputStyle, { outline: "none" }]}
                    labelStyle={[
                      {
                        color: emailIsFocused
                          ? theme.colors.primary
                          : theme.colors.whiteText,
                      },
                      styles.inputLabelStyle,
                    ]}
                    containerStyle={styles.inputContainerStyle}
                    inputContainerStyle={[
                      {
                        borderColor: emailIsFocused
                          ? theme.colors.primary
                          : theme.colors.grey3,
                      },
                      styles.inputInputContainerStyle,
                    ]}
                    onFocus={() => setEmailIsfocused(true)}
                    onBlur={() => {
                      onBlur();
                      setEmailIsfocused(false);
                    }}
                  />
                )}
                name="username"
              />
              <Text style={styles.errorText}>
                {errors["username"] && errors["username"]?.message}
              </Text>
            </View>

            <View style={styles.formItem}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    renderErrorMessage={false}
                    onChangeText={onChange}
                    value={value}
                    label="Password"
                    placeholder="*****"
                    inputStyle={[styles.inputStyle, { outline: "none" }]}
                    labelStyle={[
                      {
                        color: passwordIsFocused
                          ? theme.colors.primary
                          : theme.colors.whiteText,
                      },
                      styles.inputLabelStyle,
                    ]}
                    containerStyle={styles.inputContainerStyle}
                    inputContainerStyle={[
                      {
                        borderColor: passwordIsFocused
                          ? theme.colors.primary
                          : theme.colors.grey3,
                      },
                      styles.inputInputContainerStyle,
                    ]}
                    onFocus={() => setPasswordIsfocused(true)}
                    onBlur={() => {
                      onBlur();
                      setPasswordIsfocused(false);
                    }}
                    secureTextEntry={!passwordIsVisible}
                    rightIcon={
                      <TouchableOpacity
                        onPress={() => setPasswordIsVisible(!passwordIsVisible)}
                      >
                        <Icon
                          name={
                            passwordIsVisible
                              ? "eye-off-outline"
                              : "eye-outline"
                          }
                          type="material-community"
                        />
                      </TouchableOpacity>
                    }
                    rightIconContainerStyle={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      marginRight: 0,
                    }}
                  />
                )}
                renderErrorMessage={false}
                name="password"
              />
              <Text style={styles.errorText}>
                {errors["password"] && errors["password"]?.message}
              </Text>
            </View>

            <View style={styles.formItem}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    renderErrorMessage={false}
                    onChangeText={onChange}
                    value={value}
                    label="Confirm Password"
                    placeholder="*****"
                    inputStyle={[styles.inputStyle, { outline: "none" }]}
                    labelStyle={[
                      {
                        color: confirmIsFocused
                          ? theme.colors.primary
                          : theme.colors.whiteText,
                      },
                      styles.inputLabelStyle,
                    ]}
                    containerStyle={styles.inputContainerStyle}
                    inputContainerStyle={[
                      {
                        borderColor: confirmIsFocused
                          ? theme.colors.primary
                          : theme.colors.grey3,
                      },
                      styles.inputInputContainerStyle,
                    ]}
                    onFocus={() => setConfirmIsFocused(true)}
                    onBlur={() => {
                      onBlur();
                      setConfirmIsFocused(false);
                    }}
                    secureTextEntry={!passwordConfirmIsVisible}
                    rightIcon={
                      <TouchableOpacity
                        onPress={() =>
                          setPasswordConfirmIsVisible(!passwordConfirmIsVisible)
                        }
                      >
                        <Icon
                          name={
                            passwordConfirmIsVisible
                              ? "eye-off-outline"
                              : "eye-outline"
                          }
                          type="material-community"
                        />
                      </TouchableOpacity>
                    }
                    rightIconContainerStyle={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      marginRight: 0,
                    }}
                  />
                )}
                renderErrorMessage={false}
                name="confirmation"
              />
              <Text style={styles.errorText}>
                {errors["confirmation"] && errors["confirmation"]?.message}
              </Text>
            </View>
          </View>

          <View>
            <Button
              buttonStyle={styles.loginButtonStyle}
              titleStyle={styles.loginTitleStyle}
              style={{ marginTop: 10 }}
              onPress={handleSubmit(onSubmit)}
              disabled={isLoading}
              icon={
                isLoading && (
                  <ActivityIndicator
                    size={"small"}
                    color={theme.colors.white}
                  />
                )
              }
              title={"Sign Up"}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default Register;

Register.propTypes = {};

Register.defaultProps = {};
