import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "@rneui/themed";
import {
  createMultipleSubscriptions,
  createSubscription,
} from "api/subscriptions";
import { StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import { useQueryClient } from "react-query";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { useUserContext } from "context/user/UserProvider";
import useSchema from "hooks/useSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFetchActiveAlerts } from "containers/Alerts/hooks/useFetchActiveAlerts";

const useAddAlert = ({
  market,
  strategy,
  timeFrame,
  onSelect,
  onCancel,
  pickersToDisplay,
}) => {
  const { theme } = useTheme();
  const { plans } = useContext(DataConfigsContext);
  const queryClient = useQueryClient();
  const userInfoQuery = queryClient.getQueryData("userInfo");
  const userInfoData = (userInfoQuery || {}).data;

  // Determining the user's plan
  const userPlan = useMemo(() => {
    if (
      !userInfoData?.plan ||
      userInfoData.plan.plan !== "ACTIVE" ||
      !Array.isArray(plans) ||
      !userInfoData.plan.plan_term
    ) {
      return null;
    }
    return plans.find((pl) => pl.id === userInfoData.plan.plan_id);
  }, [userInfoData?.plan, userInfoData?.plan?.plan_term, plans]);

  const { data = {}, isLoading: isLoadingActiveAlerts } = useFetchActiveAlerts();
  const activeAlerts = useMemo(() => data?.data || [], [data]);
  const activeAlertsLength = activeAlerts.length;

  const { token } = useUserContext();
  const {
    markets,
    time_frame,
    marketsIsLoading,
    strategies,
    strategiesIsLoading,
  } = useContext(DataConfigsContext);

  const getMarketItems = useCallback((marketValues) => {
    return marketValues?.map((market) => ({
      label: market.market,
      value: market.id,
    }));
  }, []);
  const getStrategyItems = useCallback((strategyValues) => {
    return strategyValues.map((strategy) => ({
      label: strategy.name,
      value: strategy.strategy_id,
    }));
  }, []);
  const getTimeFrameItems = useCallback(
    (timeFrameValues) => {
      return timeFrameValues?.map((time) => ({
        label: time,
        value: time,
        ...{
          disabled: userPlan ? !userPlan?.time_frames?.includes(time) : false,
        },
      }));
    },
    [userPlan]
  );

  const [isMultiple, setIsMultiple] = useState(false);

  const [addAlertLoading, setAddAlertLoading] = useState(false);
  const [openMarket, setOpenMarket] = useState(false);
  const [valueMarket, setValueMarket] = useState(
    marketsIsLoading ? null : market ? market : markets?.[0]?.id
  );
  const [marketItems, setMarketItems] = useState(getMarketItems(markets));
  const [openStrategy, setOpenStrategy] = useState(false);
  const [valueStrategy, setValueStrategy] = useState(
    strategiesIsLoading
      ? null
      : strategy
        ? strategy
        : strategies?.[0]?.strategy_id
  );
  const [strategyItems, setStrategyItems] = useState(
    getStrategyItems(strategies)
  );

  const [openTimeFrame, setOpenTimeFrame] = useState(false);
  const [valueTimeFrame, setValueTimeFrame] = useState(
    marketsIsLoading ? null : timeFrame ? timeFrame : time_frame?.[0]
  );
  const [timeFrameItems, setTimeFrameItems] = useState(
    getTimeFrameItems(time_frame)
  );
  const [showBuySubscription, setShowBuySubscription] = useState(false);

  const handleShowPlanModal = () => {
    setShowBuySubscription(true);
  };

  const schema = useSchema();

  const { validationSchema, validationSchemaMultiple } = useMemo(() => {
    const schemas = {};
    const schemasMultiple = {};

    pickersToDisplay.forEach((picker) => {
      let key = "";
      let field = "";
      switch (picker) {
        case "markets":
          key = "MARKET";
          field = "Market";
          break;

        case "strategies":
          key = "STRATEGY";
          field = "Strategy";
          break;

        case "timeFrames":
          key = "TIME_FRAME";
          field = "Time Frame";
          break;
      }

      schemas[key] = schema.REQUIRED({ field });
      schemasMultiple[key] = yup
        .array()
        .of(yup.string())
        .min(1, `Please select at least one ${field}`);
    });

    return {
      validationSchema: schemas,
      validationSchemaMultiple: schemasMultiple,
    };
  }, [pickersToDisplay]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object(isMultiple ? validationSchemaMultiple : validationSchema)
    ),
  });

  const handleSelectItem = (type, item) => {
    onSelect(type, item);
  };

  const multipleAlerts = useMemo(() => {
    let subscriptions = [];
    if (!Array.isArray(valueMarket) || !Array.isArray(valueStrategy) || !Array.isArray(valueTimeFrame))
      return [];
    valueMarket?.forEach((marketId) => {
      valueStrategy?.forEach((strategyId) => {
        valueTimeFrame?.forEach((timeFrame) => {
          const marketName = markets.find(
            (market) => market.id === marketId
          )?.market;
          const strategy_id = strategies.find(
            (strategy) => strategy.strategy_id == strategyId
          )?.strategy_id;
          subscriptions.push({
            symbol: marketName.toString(),
            strategy_id: strategy_id.toString(),
            time_frame: timeFrame.toString(),
          });
        });
      });
    });
    return subscriptions;
  }, [valueMarket, valueStrategy, valueTimeFrame])

  const AddAlert = async () => {
    try {
      // Check if user is logged in
      if (!userInfoData) {
        throw new Error("You must be logged in to create an alert.");
      }

      // Check user's subscription plan
      if (!userPlan) {
        handleShowPlanModal();
        setShowBuySubscription(true);
        throw new Error("You do not have an active subscription plan.");
      }

      // Check user's active alerts quota
      if (
        userPlan &&
        userPlan.signal_quota &&
        userPlan.signal_quota !== -1 &&
        userPlan.signal_quota === activeAlertsLength
      ) {
        throw new Error("Maximum active alerts reached for your subscription.");
      }

      setAddAlertLoading(true);

      if (!isMultiple) {
        const marketId = valueMarket;
        const marketName = markets.find(
          (market) => market.id === marketId
        )?.market;
        const strategyId = valueStrategy;
        const strategy_id = strategies.find(
          (strategy) => strategy.strategy_id == strategyId
        )?.strategy_id;
        const params = {
          market: marketName,
          strategyId: strategy_id,
          timeFrame: valueTimeFrame,
        };

        if (!marketName || !strategyId) {
          throw new Error("Market or Strategy not found");
        }

        const existingAlert = findAlert(params);
        if (existingAlert) {
          throw new Error("Duplicate Alert");
        }

        const data = await createSubscription(params);
        refreshAllActiveAlerts();
        resetForm();
        onCancel();

        Toast.show({
          text1: "Successfully added new alert.",
          type: "success",
        });
      } else {
        let subscriptions = multipleAlerts;

        const response = await createMultipleSubscriptions({
          requests: subscriptions,
        });
        refreshAllActiveAlerts();
        resetForm();
        onCancel();

        Toast.show({
          text1: "Successfully added new alerts.",
          type: "success",
        });
      }
    } catch (error) {
      Toast.show({
        text1: "Error",
        text2: error.message,
        type: "error",
      });
    } finally {
      setAddAlertLoading(false);
    }
  };

  const findAlert = ({ market, strategyId, timeFrame }) => {
    const { data } = queryClient.getQueryData(`getAllActiveAlerts${token}`);
    return data?.find(
      (item) =>
        item.market === market &&
        item.strategy_id === strategyId &&
        item.time_frame === timeFrame
    );
  };

  const refreshAllActiveAlerts = () => {
    queryClient.refetchQueries({
      queryKey: [`getAllActiveAlerts${token}`],
    });
  };

  const handleOpenMarkets = (open) => {
    setOpenMarket(open);
    setOpenStrategy(false);
    setOpenTimeFrame(false);
  };
  const handleOpenStrategies = (open) => {
    setOpenStrategy(open);
    setOpenMarket(false);
    setOpenTimeFrame(false);
  };
  const handleOpenTimeFrames = (open) => {
    setOpenTimeFrame(open);
    setOpenMarket(false);
    setOpenStrategy(false);
  };

  const dropDownStyle = useMemo(() => {
    return {
      borderWidth: StyleSheet.hairlineWidth,
      marginBottom: 0,
      borderColor: theme.colors.greyOutline,
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    };
  }, []);

  const dropDownContainerStyle = useMemo(() => {
    return {
      backgroundColor:
        theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
      borderWidth: 0,
      borderTopWidth: 0,
      marginBottom: 0,
      marginTop: 12,
      borderRadius: 12,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,

      elevation: 4,
    };
  }, [theme]);

  const itemAndLabelStyle = useMemo(() => {
    return {
      fontWeight: "semibold",
      color: theme.colors.whiteText,
    };
  }, [theme]);

  useEffect(() => {
    setValueMarket(market);
  }, [market]);

  useEffect(() => {
    !!strategy && setValueStrategy(strategy);
  }, [strategy]);

  useEffect(() => {
    setMarketItems(getMarketItems(markets));
    if (valueMarket === null && !!markets?.[0]?.id)
      setValueMarket(markets[0].id);
  }, [JSON.stringify(markets)]);

  useEffect(() => {
    setStrategyItems(getStrategyItems(strategies));
    if (valueStrategy === null && !!strategies?.[0]?.strategy_id)
      setValueStrategy(strategies[0].strategy_id);
  }, [JSON.stringify(strategies)]);

  useEffect(() => {
    setTimeFrameItems(getTimeFrameItems(time_frame));
    if (valueMarket === null && Array.isArray(time_frame))
      setValueTimeFrame(time_frame[0]);
  }, [JSON.stringify(time_frame), userPlan]);

  const handleChangeMultiple = useCallback(
    (isMultiple) => {
      setOpenStrategy(false);
      setOpenMarket(false);
      setOpenTimeFrame(false);
      if (!isMultiple) {
        setIsMultiple(isMultiple);
      }
      const defaultMarketValue = marketsIsLoading
        ? null
        : market
          ? market
          : markets?.[0]?.id;
      const defaultStrategyValue = strategiesIsLoading
        ? null
        : strategy
          ? strategy
          : strategies?.[0]?.strategy_id;
      const defaultTimeFrameValue = marketsIsLoading
        ? null
        : timeFrame
          ? timeFrame
          : time_frame?.[0];
      if (isMultiple) {
        setValueMarket([defaultMarketValue]);
        setValueStrategy([defaultStrategyValue]);
        setValueTimeFrame([defaultTimeFrameValue]);
      } else {
        setValueMarket(defaultMarketValue);
        setValueStrategy(defaultStrategyValue);
        setValueTimeFrame(defaultTimeFrameValue);
      }
      if (isMultiple) {
        setIsMultiple(true);
      }
    },
    [
      setOpenStrategy,
      setOpenMarket,
      setOpenTimeFrame,
      markets,
      strategies,
      time_frame,
      setIsMultiple,
      setValueMarket,
      setValueStrategy,
      setValueTimeFrame,
    ]
  );

  const resetForm = () => {
    setOpenStrategy(false);
    setOpenMarket(false);
    setOpenTimeFrame(false);
    const defaultMarketValue = marketsIsLoading
      ? null
      : market
        ? market
        : markets?.[0]?.id;
    const defaultStrategyValue = strategiesIsLoading
      ? null
      : strategy
        ? strategy
        : strategies?.[0]?.strategy_id;
    const defaultTimeFrameValue = marketsIsLoading
      ? null
      : timeFrame
        ? timeFrame
        : time_frame?.[0];
    if (isMultiple) {
      setValueMarket([defaultMarketValue]);
      setValueStrategy([defaultStrategyValue]);
      setValueTimeFrame([defaultTimeFrameValue]);
    } else {
      setValueMarket(defaultMarketValue);
      setValueStrategy(defaultStrategyValue);
      setValueTimeFrame(defaultTimeFrameValue);
    }
  };

  return {
    markets,
    time_frame,
    isLoading: marketsIsLoading || strategiesIsLoading,
    strategies,
    isMultiple,
    setIsMultiple,
    openMarket,
    openStrategy,
    openTimeFrame,
    marketItems,
    strategyItems,
    timeFrameItems,
    valueMarket,
    valueStrategy,
    valueTimeFrame,
    addAlertLoading,
    setValueMarket,
    setValueStrategy,
    setValueTimeFrame,
    setMarketItems,
    setStrategyItems,
    setTimeFrameItems,
    handleSelectItem,
    AddAlert,
    handleOpenMarkets,
    handleOpenStrategies,
    handleOpenTimeFrames,
    dropDownStyle,
    dropDownContainerStyle,
    itemAndLabelStyle,
    handleSubmit,
    control,
    errors,
    showBuySubscription,
    setShowBuySubscription,
    handleChangeMultiple,
    activeAlertsLength,
    isLoadingActiveAlerts,
    userPlan,
    multipleAlerts,
  };
};

export default useAddAlert;
