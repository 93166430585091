import {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery } from "react-query";
import {
  maybeCompleteAuthSession,
  openAuthSessionAsync,
} from "expo-web-browser";
import {
  useAuthRequest,
  makeRedirectUri,
  useAutoDiscovery,
  revokeAsync,
  exchangeCodeAsync,
  TokenTypeHint,
  refreshAsync,
  TokenResponse,
} from "expo-auth-session";
import userApi from "api/user";
import * as SecureStore from "expo-secure-store";
import { ActivityIndicator, Platform } from "react-native";
import { useUserContext } from "src/context/user/UserProvider";
import { Overlay, Text } from "@rneui/themed";
import { ChartLoaderContext } from "context/chartLoader/ChartLoaderContext";
import Constants from "expo-constants";
import Toast from "react-native-toast-message";
maybeCompleteAuthSession();

//********************** CONSTANT **********************//

const FINALYST_SECURE_AUTH_STATE_KEY = "FinalystSecureAuthStateKey";

const config = {
  responseType: "code",
  clientId: Constants.expoConfig.extra.env.KEYCLOAK_CLIENT_ID,
  scopes: ["openid", "email", "profile"],
  redirectUri: makeRedirectUri({
    useProxy: false,
    path: "login",
  }),
};

//********************** TYPE **********************//

/**
 * The context object for the user.
 * @typedef {Object} KeyclockOpenIdContext
 * @property {string} token - The current token.
 */

//********************** FUNCTION **********************//

/**
 * The user context.
 * @type {React.Context<KeyclockOpenIdContext>}
 */

// Create a KeyclockOpenIdContext for sharing user-related data
const KeyclockOpenIdContext = createContext();

/**
 * The keyclock openid provider component.
 * KeyclockOpenIdProvider component to provide keyclock openid data to its children
 * @param {Object} props - The props for the component.
 * @param {React.ReactNode} props.children - The child elements to render.
 * @returns {React.JSX} The rendered theme provider component.
 * @final
 */

const KeyclockOpenIdProvider = ({ children }) => {
  const { isSplashScreenHidden } = useContext(ChartLoaderContext);
  const discovery = useAutoDiscovery(
    `${Constants.expoConfig.extra.env.BASE_URL}auth/realms/master`
  );
  const [params, setParams] = useState(null);
  const [user, setUser] = useState({});
  const [loadingFromStorage, setLoadingFromStorage] = useState(true);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState();

  const [request, response, promptAsync] = useAuthRequest(config, discovery);
  const {
    checkToken,
    token,
    refreshToken: refreshTokenAsync,
    signOut,
  } = useUserContext();

  useEffect(() => {
    if (response?.type == "success") {
      const params = response.params;
      setParams(params);
      const storageValue = JSON.stringify(params);
      if (Platform.OS !== "web") {
        SecureStore.setItemAsync(FINALYST_SECURE_AUTH_STATE_KEY, storageValue);
      }
    }
  }, [request, response, discovery]);

  useEffect(() => {
    if (!!token && !!request) {
      handleUserInfo();
    }
  }, [token, request]);

  const getParamsFromStorage = useCallback(async () => {
    setShowLoadingOverlay(true);
    const paramsFromStorage = await SecureStore.getItemAsync(
      FINALYST_SECURE_AUTH_STATE_KEY
    );
    if (!!paramsFromStorage) {
      setParams(JSON.parse(paramsFromStorage));
    }
    setLoadingFromStorage(false);
    setShowLoadingOverlay(false);
  }, [
    setShowLoadingOverlay,
    FINALYST_SECURE_AUTH_STATE_KEY,
    setParams,
    setLoadingFromStorage,
  ]);

  useEffect(() => {
    if (!params && loadingFromStorage) {
      getParamsFromStorage();
    }
  }, []);

  useEffect(() => {
    if (!!params && !loadingFromStorage && !!request?.codeVerifier) {
      handleUserInfo(params);
    }
  }, [params, loadingFromStorage, request?.codeVerifier]);

  const handleLogin = useCallback(async () => {
    return promptAsync();
  }, [promptAsync]);

  const handleUserInfo = useCallback(async () => {
    setShowLoadingOverlay({ message: "Please wait..." });
    try {
      if (!request?.codeVerifier) return;
      const token = await AsyncStorage.getItem("token");
      const tokenInfo = await AsyncStorage.getItem("accessTokenInfo");

      if (!!token && !!tokenInfo) {
        const tokenInfoParsed = JSON.parse(tokenInfo);
        let tokenRessponse = new TokenResponse(
          TokenResponse.fromQueryParams(tokenInfoParsed)
        );

        let isTokenValid = null;

        try {
          const userInfo = await userApi.getUserInfo();
          if (!!userInfo) {
            isTokenValid = true;
          } else isTokenValid = false;
        } catch (e) {
          isTokenValid = false;
        }

        if (
          (tokenRessponse.shouldRefresh() &&
            !TokenResponse.isTokenFresh(tokenRessponse, 0)) ||
          !isTokenValid
        ) {
          setShowLoadingOverlay({ message: "Please wait..." });
          try {
            const payload = {
              grant_type: "refresh_token",
              refresh_token: tokenRessponse.refreshToken,
              client_id: Constants.expoConfig.extra.env.KEYCLOAK_CLIENT_ID,
            };
            await refreshTokenAsync(payload);
            await checkToken();
          } catch (e) {
            await signOut();
          }
        }
      }
    } catch (error) {
      console.log("===ERROR HOOK :: 123 getProfile===", error);
    } finally {
      setShowLoadingOverlay(false);
    }
  }, [
    setShowLoadingOverlay,
    refreshTokenAsync,
    checkToken,
    request?.codeVerifier,
  ]);

  /**
   * calls api to remove this device from notification waitlist
   */
  const removeExpoPushToken = useCallback(async () => {
    const removeExpoAPI = userApi.removeExpoPushToken;
    try {
      await removeExpoAPI();
    } catch (e) {
      throw new Error(e);
    }
  }, []);

  const signUp = useCallback(async () => {
    try {
      if (!discovery?.registrationEndpoint) return;
      try {
        await removeExpoPushToken();
      } catch (e) {}
      // const token = await AsyncStorage.getItem("accessTokenInfo");
      // const tokenInfo = await AsyncStorage.getItem("accessTokenInfo");
      // const idToken = JSON.parse(tokenInfo).idToken;
      // const uri = Constants.expoConfig.extra.;
      const redirectUriEncoded = encodeURIComponent(config.redirectUri);
      const finalUri = `${Constants.expoConfig.extra.env.BASE_URL}auth/realms/master/login-actions/registration?redirect_uri=${redirectUriEncoded}`;
      const data = await openAuthSessionAsync(finalUri);
      // setParams(null);
      // await AsyncStorage.removeItem("expoPushToken");
      // await AsyncStorage.removeItem("token");
      // await AsyncStorage.removeItem("accessTokenInfo");
      // await SecureStore.deleteItemAsync(FINALYST_SECURE_AUTH_STATE_KEY);
      await checkToken();
    } catch (e) {
      console.log("===logout error", e);
    }
  }, [
    discovery?.registrationEndpoint,
    removeExpoPushToken,
    config?.redirectUri,
    checkToken,
    openAuthSessionAsync,
  ]);

  const logout = useCallback(async () => {
    setShowLoadingOverlay({ message: "Logging out..." });
    try {
      if (!discovery?.endSessionEndpoint) return;
      try {
        await removeExpoPushToken();
      } catch (e) {}
      const tokenInfo = await AsyncStorage.getItem("accessTokenInfo");
      const idToken = JSON.parse(tokenInfo).idToken;
      const uri = discovery.endSessionEndpoint;
      const redirectUriEncoded = encodeURIComponent(config.redirectUri);
      const finalUri =
        uri +
        `?post_logout_redirect_uri=${redirectUriEncoded}&id_token_hint=${idToken}`;
      await openAuthSessionAsync(finalUri);
      setParams(null);
      await AsyncStorage.removeItem("expoPushToken");
      await AsyncStorage.removeItem("token");
      await AsyncStorage.removeItem("accessTokenInfo");
      await SecureStore.deleteItemAsync(FINALYST_SECURE_AUTH_STATE_KEY);
      await checkToken();
    } catch (e) {
      console.log("===logout error", e);
    } finally {
      setShowLoadingOverlay(false);
    }
  }, [
    discovery?.endSessionEndpoint,
    removeExpoPushToken,
    config.redirectUri,
    openAuthSessionAsync,
    setParams,
    checkToken,
    setShowLoadingOverlay,
  ]);

  const overlayRender = useMemo(() => {
    return (
      <Overlay isVisible={showLoadingOverlay !== false && isSplashScreenHidden}>
        <ActivityIndicator size={"small"} />
        <Text>{showLoadingOverlay?.message}</Text>
      </Overlay>
    );
  }, [showLoadingOverlay, isSplashScreenHidden]);

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <KeyclockOpenIdContext.Provider
      value={{
        params,
        user,
        handleLoginWithKeyclock: handleLogin,
        logout,
        signUp,
      }}
    >
      {overlayRender}
      {childrenMemo}
    </KeyclockOpenIdContext.Provider>
  );
};

/**
 * Custom hook to access the keyclock openid context and handle error.
 * @returns {KeyclockOpenIdContext} The keyclock openid context.
 * @throws {Error} Throws an error if used outside a KeyclockOpenIdProvider.
 * @final
 */
const useKeyclockOpenIdContext = () => {
  const keyclockOpenIdContext = useContext(KeyclockOpenIdContext);

  if (!keyclockOpenIdContext) {
    throw new Error(
      "useKeyclockOpenIdContext must be used within a KeyclockOpenIdProvider"
    );
  }

  return keyclockOpenIdContext;
};

export {
  KeyclockOpenIdProvider,
  useKeyclockOpenIdContext,
  KeyclockOpenIdContext,
};
