import Server from "./server";
import { ROOT } from "constants/ROOT";

const baseUrl = "api/v1/";

export const registerUser = async ({ payload }) => {
  try {
    return await Server.post(`${baseUrl}public/users/register`, payload);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.REGISTER_ERROR);
  }
};

export const deleteUser = async () => {
  try {
    return await Server.remove(`${baseUrl}users/delete-account`);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

export const resendEmail = async ({ payload }) => {
  try {
    return await Server.post(`${baseUrl}public/re-send-email?email=${payload?.email}`, payload);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

export const forgotPassword = async ({ payload }) => {
  try {
    return await Server.patch(`${baseUrl}public/forgot-password`, payload);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};
