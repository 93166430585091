import Server from "./server";
import { ROOT } from "constants/ROOT";

export const favoriteBaseUrl = "/api/v1/strategies/favorite-market";

export const getFavorites = async () => {
  try {
    const response = await Server.get(favoriteBaseUrl);
    return response?.data || [];
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

export const addToFavorites = async ({ marketId }) => {
  const currentFavorites = await getFavorites();
  if (currentFavorites.find((fav) => fav.market_id === marketId)) return;
  try {
    return await Server.post(favoriteBaseUrl, { market_id: marketId });
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

export const removeFromFavorites = async ({ marketId }) => {
  const currentFavorites = await getFavorites();
  if (!currentFavorites.find((fav) => fav.market_id === marketId)) return;
  try {
    return await Server.remove(favoriteBaseUrl, { market_id: marketId });
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};
