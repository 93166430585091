import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery, useQueryClient } from "react-query";
import userApi from "api/user";
import { ADMIN_USER } from "constants/ADMIN_USER";
import { initialState, reducer } from "./userReducer";
import {
  TokenResponse,
  getCurrentTimeInSeconds,
} from "expo-auth-session/build/TokenRequest";
import { setBasicPlan } from "api/plans";
import Constants from "expo-constants";

//********************** TYPE **********************//

/**
 * The context object for the user.
 * @typedef {Object} UserContext
 * @property {string} token - The current token.
 */

//********************** FUNCTION **********************//

/**
 * The user context.
 * @type {React.Context<UserContext>}
 */

// Create a UserContext for sharing user-related data
const UserContext = createContext();

/**
 * The user provider component.
 * UserProvider component to provide user data to its children
 * @param {Object} props - The props for the component.
 * @param {React.ReactNode} props.children - The child elements to render.
 * @returns {React.JSX} The rendered theme provider component.
 * @final
 */

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();

  const { data: userInfoData, refetch: refetchUserInfo } = useQuery(
    "userInfo",
    userApi.getUserInfo,
    {
      refetchInterval: 30000,
      enabled: !!state.token, // Only run the query if the token exists
      select: (response) => response?.data,
    }
  );

  // each dependency are important.
  useEffect(() => {
    getUserPlanSubscriptions();
    if (!state.token) checkToken();

    if (state.token) {
      refetchUserInfo();
      getProfile();
    }
  }, [state.token, state.refreshToken, refetchUserInfo]);

  /**
   * Fetch current User Info
   */
  const getProfile = async () => {
    try {
      if (state.token) {
        // Call the API to get user profile information
        const res = await userApi.getUserInfo();

        // Update the profile state with the received data
        dispatch({ type: "SET_PROFILE", payload: res?.data });
      }
    } catch (error) {
      console.log("===ERROR HOOK :: getProfile===", error);
    }
  };

  /**
   * Fetch Token from keyclock
   */
  const signIn = async (payload = ADMIN_USER) => {
    try {
      const { data } = await userApi.getToken({ payload });
      const { access_token } = data;

      dispatch({ type: "SET_TOKEN", payload: access_token });
      // Store the access token in AsyncStorage
      await AsyncStorage.setItem("token", access_token);
      await AsyncStorage.setItem(
        "accessTokenInfo",
        JSON.stringify({
          ...data,
          issued_at: data?.issued_at || getCurrentTimeInSeconds(),
        })
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const refreshToken = async (payload) => {
    try {
      const { data } = await userApi.refreshToken({ payload });
      const { access_token } = data;

      dispatch({ type: "SET_TOKEN", payload: access_token });
      // Store the access token in AsyncStorage
      await AsyncStorage.setItem("token", access_token);
      await AsyncStorage.setItem(
        "accessTokenInfo",
        JSON.stringify({
          ...data,
          issued_at: data?.issued_at || getCurrentTimeInSeconds(),
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const checkForPlansAndSetBasicIfNonIsActive = () => {
    setBasicPlan();
  };

  /**
   * Check Token is exist or not in storage:
   *  If Token exists, navigate to the authenticated screen.
   *  If Token doesn't exist, navigate to the authentication screen.
   *
   * @returns {void}
   */
  const checkToken = async () => {
    let token,
      refreshToken = null;

    try {
      token = await AsyncStorage.getItem("token");
      refreshToken = await AsyncStorage.getItem("refresh_token");

      dispatch({ type: "SET_TOKEN", payload: token });
      dispatch({ type: "SET_REFRESH_TOKEN", payload: refreshToken });
      if (token) {
        checkForPlansAndSetBasicIfNonIsActive();
      }
    } catch (error) {
      console.log("===ERROR HOOK STORAGE :: checkToken===", error);
    }
  };

  useEffect(() => {
    if (state.isSignedIn) {
      checkForPlansAndSetBasicIfNonIsActive();
    }
  }, [state.isSignedIn]);

  /**
   * calls api to remove this device from notification waitlist
   */
  const removeExpoPushToken = useCallback(async () => {
    const removeExpoAPI = userApi.removeExpoPushToken;
    try {
      await removeExpoAPI();
    } catch (e) {
      throw new Error(e);
    }
  }, []);

  const signOut = async () => {
    try {
      await removeExpoPushToken();

      const token = await AsyncStorage.getItem("token");
      const tokenInfo = await AsyncStorage.getItem("accessTokenInfo");
      if (!!token && !!tokenInfo) {
        const tokenInfoParsed = JSON.parse(tokenInfo);
        let tokenRessponse = new TokenResponse(
          TokenResponse.fromQueryParams(tokenInfoParsed)
        );
        const payloadRerfreshToken = {
          token: tokenRessponse.refreshToken,
          client_id: Constants.expoConfig.extra.env.KEYCLOAK_CLIENT_ID,
        };
        await userApi.revokeToken(payloadRerfreshToken);

        const payloadToken = {
          token: state.token,
          client_id: Constants.expoConfig.extra.env.KEYCLOAK_CLIENT_ID,
        };
        await userApi.revokeToken(payloadToken);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      await AsyncStorage.removeItem("expoPushToken");
      await AsyncStorage.removeItem("token");
      await AsyncStorage.removeItem("refresh_token");

      queryClient.setQueryData("userInfo", null);

      dispatch({ type: "SET_TOKEN", payload: null });
      dispatch({ type: "SET_REFRESH_TOKEN", payload: null });
    }
  };

  const getUserPlanSubscriptions = async () => {
    try {
      const res = await userApi.getPlanSubscriptions();
      dispatch({ type: "SET_SUBSCRIPTIONS", payload: res?.data });
    } catch (error) {
      dispatch({ type: "SET_SUBSCRIPTIONS", payload: [] });
    }
  };

  // Fetch the token using useQuery hook from react-query
  // const { data, status } = useQuery("data", getToken);

  return (
    <UserContext.Provider
      value={{
        ...state,
        getProfile,
        checkToken,
        signIn,
        signOut,
        refreshToken,
        getUserPlanSubscriptions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/**
 * Custom hook to access the user context and handle error.
 * @returns {UserContext} The user context.
 * @throws {Error} Throws an error if used outside a UserProvider.
 * @final
 */
const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return userContext;
};

export { UserProvider, useUserContext, UserContext };

// useEffect(() => {
//   if (data?.access_token) {
//     // Update token and refresh token when data changes
//     setToken(data?.access_token);
//     setRefreshToken(data?.refresh_token);

//     // Store the token in AsyncStorage
//     setStorage(data?.access_token);
//   }
// }, [data]);
