import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getAllPayments } from "api/payment";
import { useUserContext } from "context/user/UserProvider";

const PRICE_TYPES = ["monthly", "annually"];

const useMainPricing = () => {
  const { plans } = useContext(DataConfigsContext);
  const { token } = useUserContext();

  const [selectedIndex, setSelectedIndex] = useState(1);

  const onPress = (selectedIndex) => setSelectedIndex(selectedIndex);

  const getCards = (index = 1) => {
    return plans.map((plan) => {
      const {
        id,
        key,
        name,
        time_frames,
        signal_quota,
        clone_count,
        monthly_price,
        yearly_price,
        active,
        trial,
      } = plan;
      return {
        plan_id: id,
        type: name,
        title: name,
        term: index === 0 ? "MONTHLY" : "YEARLY",
        icon: name === "basic" ? "" : "currency-usd",
        price: index === 0 ? monthly_price : yearly_price,
        currency: index === 0 ? "/ mo" : "/ year",
        clone_count,
        // discount: ,
        days: trial ? 7 : 0,
        dayInfo: "days for free",
        button:
          name === "Pro"
            ? { title: "pay" }
            : name === "Premium"
              ? { title: "Pay/Claim" }
              : {},
        info: [
          `Set ${signal_quota === -1 ? "unlimited" : signal_quota} alert notifications`,
          `Access to ${
            time_frames.length > 1 ? time_frames.join(", ") : time_frames[0]
          } time frame`,
        ],
      };
    });
  };

  const { data = {}, isLoading, refetch } = useQuery(`getAllPayments${token}`, getAllPayments);

  useEffect(() => {
    if (!token) {
      refetch(); // Refetch payments if the token is not present
    }
  }, [token, refetch]);

  const payments = data?.data || [];
  const isLastPaymentPending = useMemo(() => {
    return payments[payments.length - 1]?.status === 'PENDING';
  }, [payments])

  return {
    types: PRICE_TYPES,
    selectedIndex,
    pricingCardList:
      selectedIndex === 1 ? pricingCardAnnuallyList : pricingCardMonthlyList,
    payments,
    isLastPaymentPending,
    isPaymentsFetch: !isLoading,
    onPress,
    getCards,
  };
};

export default useMainPricing;

const pricingCardMonthlyList = [
  {
    type: "basic",
    title: "Basic",
    icon: "",
    price: "",
    currency: "",
    discount: "",
    days: 7,
    dayInfo: "days for free",
    button: {},
    info: [
      "Set 4 alert notifications",
      "Access to 1h time frame",
      "Access to all coins",
      "Access to all strategies",
    ],
  },
  {
    type: "pro",
    title: "Pro",
    icon: "currency-usd",
    price: "12.97",
    currency: "/ mo",
    discount: "",
    days: 0,
    dayInfo: "",
    button: { title: "Pay" },
    info: [
      "Set 30 alert notifications",
      "Access to all time frames",
      "Access to all coins",
      "Access to all strategies",
    ],
  },
  {
    type: "premium",
    title: "Premium",
    icon: "currency-usd",
    price: "23.35",
    currency: "/ mo",
    discount: "",
    days: 0,
    dayInfo: "",
    button: { title: "Pay/Claim" },
    info: [
      "Set unlimited alert notifications",
      "Access to all time frames",
      "Access to all coins",
      "Access to all strategies",
      "Two Premium accounts",
    ],
  },
];

const pricingCardAnnuallyList = [
  {
    type: "basic",
    title: "Basic",
    icon: "",
    price: "124.55",
    currency: "/ year",
    discount: "You save 31.09 / year",
    days: 7,
    dayInfo: "days for free",
    button: {},
    info: [
      "Set 4 alert notifications",
      "Access to 1h time frame",
      "Access to all coins",
      "Access to all strategies",
    ],
    annually: {
      price: "124.55 / year",
      discount: "You save 31.09 / year",
    },
  },
  {
    type: "pro",
    title: "Pro",
    icon: "currency-usd",
    price: "124.55",
    currency: "/ year",
    discount: "You save 31.09 / year",
    days: 0,
    dayInfo: "",
    button: { title: "Pay" },
    info: [
      "Set 30 alert notifications",
      "Access to all time frames",
      "Access to all coins",
      "Access to all strategies",
    ],
    annually: {
      price: "124.55 / year",
      discount: "You save 31.09 / year",
    },
  },
  {
    type: "premium",
    title: "Premium",
    icon: "currency-usd",
    price: "124.55",
    currency: "/ year",
    discount: "You save 31.09 / year",
    days: 0,
    dayInfo: "",
    button: { title: "Pay/Claim" },
    info: [
      "Set unlimited alert notifications",
      "Access to all time frames",
      "Access to all coins",
      "Access to all strategies",
      "Two Premium accounts",
    ],
    annually: {
      price: "124.55 / year",
      discount: "You save 31.09 / year",
    },
  },
];
