import React, { useContext, useMemo, useState } from "react";
import { Platform, useWindowDimensions, View } from "react-native";
import CustomIcon from "components/UI/CustomIcon";
import ChartSettingsBottomSheet from "components/ChartSettingsBottomSheet";
import { MarketViewContext } from "context/MarketViewContext";
import { DataConfigsContext } from "context/dataconfigs/DataConfigsContext";
import { addToFavorites, getFavorites, removeFromFavorites } from "api/favorites";
import { useTheme } from "@rneui/themed";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUserContext } from "context/user/UserProvider";

const ChartActionsHeader = ({ route, navigation }) => {
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const { token } = useUserContext();
  const {
    isFullScreen,
    setIsFullScreen,
    alertNotificationsVisibility,
    setAlertNotificationsVisibility,
    marketsVisibility,
    setMarketsVisibility,
    currentMarket,
    setShowAlertModal,
    currentTimeFrame,
    currentStrategy,
    handleChange,
  } = useContext(MarketViewContext);
  const queryClient = useQueryClient();
  const { data: favoritesData } = useQuery(["favorites"], getFavorites);

  const { markets, time_frame, strategies, getStrategyNameById } =
    useContext(DataConfigsContext);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const checkPlatformWebWidth =
    Platform.OS === "web" && width >= theme.breakpoints.laptop;
  const isFavorite = useMemo(
    () => favoritesData?.find(fav => fav.market_id === currentMarket),
    [favoritesData, currentMarket]
  );
  const action = isFavorite ? removeFromFavorites : addToFavorites;
  const mutation = useMutation(() => action({ marketId: currentMarket }), {
    onSuccess: () => {
      // Invalidate the query to fetch updated favorite markets
      queryClient.invalidateQueries(['favorites']);
    },
  });

  const toggleFavorite = async () => {
    mutation.mutate();
  };

  const toggleAlertNotifications = () => {
    if (Platform.OS !== "web" || width < theme.breakpoints.laptop)
      setShowAlertModal(true);
    else setAlertNotificationsVisibility(!alertNotificationsVisibility);
  };

  const toggleMarkets = () => {
    if (Platform.OS === "web" || width > theme.breakpoints.laptop)
      setMarketsVisibility(!marketsVisibility);
  };

  const handleFullScreenToggle = () => {
    if (Platform.OS === "web") {
      if (!isFullScreen) {
        document.body.requestFullscreen();
      } else if (isFullScreen) {
        document.exitFullscreen();
      }
    }
    setMarketsVisibility(isFullScreen);
    setAlertNotificationsVisibility(isFullScreen);
    setIsFullScreen(!isFullScreen);
  };

  const handleSettingsPress = () => {
    setShowSettingModal(true);
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {!isFullScreen ? (
        <>
          <CustomIcon
            type="feather"
            name="maximize"
            size={20}
            color={theme.colors.whiteText}
            onPress={handleFullScreenToggle}
          />
        </>
      ) : (
        <>
          <CustomIcon
            type="feather"
            name="settings"
            size={20}
            color={theme.colors.whiteText}
            onPress={handleSettingsPress}
          />
          <ChartSettingsBottomSheet
            showSettingModal={showSettingModal}
            setShowSettingModal={setShowSettingModal}
            currentStrategy={currentStrategy}
            strategies={strategies}
            currentMarket={currentMarket}
            markets={markets}
            handleChange={handleChange}
            getStrategyNameById={getStrategyNameById}
            time_frame={time_frame}
            currentTimeFrame={currentTimeFrame}
          />

          <CustomIcon
            type="feather"
            name="minimize"
            size={20}
            color={theme.colors.whiteText}
            onPress={handleFullScreenToggle}
          />
        </>
      )}
      {checkPlatformWebWidth && (
        <CustomIcon
          type="octicon"
          name="list-unordered"
          size={20}
          color={
            checkPlatformWebWidth && marketsVisibility
              ? theme.colors.primary
              : theme.colors.black
          }
          onPress={toggleMarkets}
        />
      )}

      <CustomIcon
        type="octicon"
        name={
          checkPlatformWebWidth && alertNotificationsVisibility
            ? "bell-fill"
            : "bell"
        }
        size={20}
        color={
          checkPlatformWebWidth && alertNotificationsVisibility
            ? theme.colors.primary
            : theme.colors.black
        }
        onPress={toggleAlertNotifications}
      />

      {token && (
        <CustomIcon
          type="octicon"
          name={isFavorite ? "star-fill" : "star"}
          size={20}
          color={isFavorite ? theme.colors.primary : theme.colors.black}
          onPress={toggleFavorite}
        />
      )}
    </View>
  );
};

export default ChartActionsHeader;
