import { makeStyles } from "@rneui/themed";
import { STYLES, INPUT_STYLES, CARD_STYLES } from "constants/STYLES";

const useStyles = makeStyles((theme, alert = {}) => ({
  root: {},
  wrapperStyle: {
    borderWidth: 0,
    height: 40,
    // margin: 6,
    backgroundColor:
      theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 0,
    flex: 1,
  },
  card: {
    ...CARD_STYLES,
    margin: 8,
    borderBottomWidth:
      theme.mode === "dark" ? 0 : CARD_STYLES.borderBottomWidth,
    backgroundColor:
      theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
  },
  iconStyle: {
    alignItems: "center",
  },
  cardTitle: {
    color: theme.colors.primary,
  },
}));

export default useStyles;
