import {
  ActivityIndicator,
  Dimensions,
  Platform,
  RefreshControl,
  Text,
  View,
} from "react-native";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import alertsApi from "api/alerts";
import LatestAlert from "../LatestAlert";
import { Icon, Skeleton, useTheme, Image } from "@rneui/themed";
import { useAlertsContext } from "context/alerts/AlertsContext";
import { useStyles } from "./styles";
import { useAlertSharedStyles } from "containers/Alerts/core/alertSharedStyles";
import CreateAlertModal from "components/CreateAlertModal";
import { FlatList } from "react-native-gesture-handler";
import NoAlert from "components/UI/NoAlert";
import NotFound from "components/UI/NotFound";

/**
 * Component for showing a list of LatestAlerts.
 * This component fetches and displays the latest alerts, including handling pagination and loading states.
 * It also provides functionality to refresh the list and navigate to the alert creation modal.
 *
 * @component
 * @returns {React.Element} - The rendered component.
 */
const LatestAlerts = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const alertSharedStyles = useAlertSharedStyles();
  const { height } = Dimensions.get("window");
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
  const [lastAlert, setLastAlert] = useState(null);

  const {
    activeAlerts = [],
    listView = false,
    getStrategyNameById,
    marketName,
    isLoading: activeAlertsLoading,
    hasCreateAlert = true,
    isFromScreen,
  } = useAlertsContext();

  const fetchLogs = useCallback(
    async (arg) => {
      try {
        const data = await alertsApi.getAllAlertsLogs({
          allAlertLogs: !marketName,
          subscriptions: activeAlerts,
          pageNumber: arg.pageParam ? arg.pageParam : 0,
          pageSize: arg.pageSize ? arg.pageSize : 30,
        });
        return {
          data: marketName
            ? data?.page.content.filter((res) => res.symbol === marketName)
            : data?.page.content,
          lastPage: !data?.has_next_page
            ? undefined
            : arg.pageParam
            ? arg.pageParam + 1
            : 1,
        };
      } catch (error) {
        throw new Error(error?.message);
      }
    },
    [marketName, JSON.stringify(activeAlerts)]
  );

  const [pageNumber, setPageNumber] = useState(0);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery({
    queryKey: [`getAllAlertsLogs/${JSON.stringify(activeAlerts)}`],
    queryFn: fetchLogs,
    getNextPageParam: (lastPage, pages) => lastPage.lastPage,
  });

  const debounceTimeoutRef = useRef(null);
  const isFetchingRef = useRef(false); // To track the ongoing API call

  const checkForNewAlerts = useCallback(async () => {
    if (isFetchingRef.current) {
      return; // If an API call is already in progress, exit early
    }
    isFetchingRef.current = true; // Set the flag before making the API call
    try {
      const latestAlert = await fetchLogs({ pageParam: 0, pageSize: 1 });
      const latestAlertData = latestAlert.data && latestAlert.data[0];

      if (
        latestAlertData &&
        (!lastAlert ||
          JSON.stringify(latestAlertData) !== JSON.stringify(lastAlert))
      ) {
        await refetch();
      }

      // Set a new timeout to call the function again
      debounceTimeoutRef.current = setTimeout(() => {
        checkForNewAlerts();
      }, 1000); // Immediate call after tasks are done
    } catch (error) {
      console.error("Error fetching alert:", error);
    } finally {
      isFetchingRef.current = false; // Clear the flag after the API call completes
    }
  }, [lastAlert, refetch, fetchLogs]);

  useEffect(() => {
    if (lastAlert) {
      checkForNewAlerts(); // Call initially when lastAlert is filled
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current); // Clear timeout on cleanup
    };
  }, [lastAlert, checkForNewAlerts]);

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeoutRef.current); // Clear timeout on component unmount
    };
  }, []);

  const isLoading = useMemo(() => status === "loading", [status]);
  const isSuccess = useMemo(() => status === "success", [status]);

  const list = useMemo(() => {
    setLastAlert(data && data?.pages[0].data && data.pages[0].data[0]);
    if (!Array.isArray(data?.pages)) return null;
    return data.pages.reduce(
      (all, page) => [...all, ...(Array.isArray(page.data) ? page.data : [])],
      []
    );
  }, [data?.pages, pageNumber]);

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refetch();
  }, []);

  useEffect(() => {
    if (!isFetching && refreshing) {
      setRefreshing(false);
    }
  }, [isFetching, refreshing]);

  const [displayedData, setDisplayedData] = useState([]);

  const isLogsLoaded = useMemo(() => {
    return !activeAlertsLoading && !isLoading;
  }, [activeAlertsLoading, isLoading]);

  const failedLoadingLogs = useMemo(() => {
    return isLogsLoaded && (!Array.isArray(list) || !isSuccess);
  }, [list, isLogsLoaded, isSuccess]);

  const isEmpty = useMemo(() => {
    return (
      isLogsLoaded &&
      !failedLoadingLogs &&
      list.length <= 0 &&
      activeAlerts.length === 0
    );
  }, [isLogsLoaded, failedLoadingLogs, list]);

  /**
   * Renders an individual alert item.
   *
   * @param {Object} item - The alert item.
   * @returns {JSX.Element} - The rendered alert item component.
   */
  const renderItem = useCallback(
    ({ item }) => (
      <LatestAlert listView={listView} alert={item} key={item.id} />
    ),
    [listView]
  );

  const renderSkeletonItem = ({ item }) => {
    return (
      <View style={styles.skeletonCardWrapper}>
        <View style={styles.skeletonHeaderWrapper}>
          <Skeleton
            style={styles.skeletonMarketTitle}
            width={"30%"}
            animation="wave"
          />
          <Skeleton
            style={[styles.skeletonMarketTitle, { height: "50%" }]}
            width={"20%"}
            animation="wave"
          />
        </View>
        <View style={styles.skeletonBodyWrapper}>
          <Skeleton
            style={styles.skeletonBody}
            width={"80%"}
            animation="wave"
          />
        </View>
        <View style={styles.skeletonFooterWrapper}>
          <Skeleton
            style={styles.skeletonMarketTitle}
            width={"80%"}
            animation="wave"
          />
          <Skeleton
            style={styles.skeletonMarketTitle}
            width={"10%"}
            animation="wave"
          />
        </View>
      </View>
    );
  };

  const renderData = useMemo(() => {
    if (!activeAlertsLoading && !isLoading && !Array.isArray(list)) return [];
    if (activeAlertsLoading || isLoading || !Array.isArray(list))
      return "placeholder";
    return list;
  }, [JSON.stringify(list)]);

  const onEndReached = () => {
    if (!isFetching && hasNextPage) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      fetchNextPage();
    }
  };

  const getItemLayout = useCallback(
    (data, index) => ({ length: 96, offset: 96 * index, index }),
    []
  );

  const renderedList = useMemo(() => {
    if (!isSuccess) return;
    const initialNum = Math.floor((height - 220) / 96);
    return (
      <FlatList
        data={displayedData}
        keyExtractor={(alert) => JSON.stringify(alert)}
        renderItem={renderItem}
        initialNumToRender={Math.floor(30 / initialNum)}
        removeClippedSubviews={true}
        maxToRenderPerBatch={Math.floor(30 / initialNum)}
        updateCellsBatchingPeriod={50}
        getItemLayout={getItemLayout}
        onEndReachedThreshold={10}
        onEndReached={onEndReached}
        ListFooterComponent={() =>
          isFetchingNextPage ? <ActivityIndicator /> : null
        }
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      />
    );
  }, [
    displayedData,
    height,
    renderItem,
    isSuccess,
    getItemLayout,
    refreshing,
    setRefreshing,
    onRefresh,
  ]);

  useEffect(() => {
    if (isLogsLoaded && !failedLoadingLogs && !isEmpty) {
      if (Array.isArray(renderData) && renderData.length > 0) {
        // const dataToAppend = getPaginatedData(0, limit);
        setDisplayedData(list);
      }
    }
  }, [renderData, isLoading, activeAlertsLoading]);

  useEffect(() => {
    if (pageNumber > 1) setDisplayedData(list);
  }, [pageNumber, list]);

  const renderFailedLoadingScreen = useMemo(() => {
    return (
      <View style={alertSharedStyles.fullScreen}>
        <View style={alertSharedStyles.centerView}>
          <NotFound width={150} height={150} />
          <View style={alertSharedStyles.warningTextContainer}>
            {!!error?.message ? (
              <Text style={alertSharedStyles.warningText}>
                {error?.message}
              </Text>
            ) : (
              <>
                <Text style={alertSharedStyles.warningText}>
                  There was an error while loading alerts.
                </Text>
                <Text style={alertSharedStyles.warningText}>
                  Please check your network connection.
                </Text>
              </>
            )}
          </View>
        </View>
      </View>
    );
  }, [alertSharedStyles]);

  const onPressAddAlert = () => {
    setShowCreateAlertModal(true);
  };

  const renderEmptyScreen = useMemo(() => {
    return (
      <View style={alertSharedStyles.fullScreen}>
        <View style={alertSharedStyles.centerView}>
          <NoAlert width={150} height={150} />
          <View style={alertSharedStyles.warningTextContainer}>
            <Text style={alertSharedStyles.warningText}>
              You've no active alerts.
            </Text>
            <Text style={alertSharedStyles.warningText}>
              Try creating new ones.
            </Text>
          </View>
          {!hasCreateAlert && (
            <View style={alertSharedStyles.button}>
              <Icon
                name="bell-plus-outline"
                type="material-community"
                color={theme.colors.white}
                size={20}
              />
              <Text
                style={alertSharedStyles.buttonText}
                onPress={onPressAddAlert}
              >
                Create Alert
              </Text>
              <CreateAlertModal
                showCreateAlertModal={showCreateAlertModal}
                setShowCreateAlertModal={setShowCreateAlertModal}
              />
            </View>
          )}
        </View>
      </View>
    );
  }, [alertSharedStyles]);
  return (
    <View
      style={{
        flex: 1,
        ...(isFromScreen && Platform.OS !== "web"
          ? {
              backgroundColor: theme.colors.white,
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
              paddingTop: 12,
            }
          : {}),
      }}
    >
      {!isLogsLoaded && !failedLoadingLogs && (
        <FlatList
          data={Array.from({ length: 10 }, (_, i) => i)}
          keyExtractor={(item) => item.toString()}
          renderItem={renderSkeletonItem}
          // contentContainerStyle={{ flex: 1 }}
        />
      )}
      {isLogsLoaded && !failedLoadingLogs && !isEmpty && renderedList}
      {isLogsLoaded && failedLoadingLogs && renderFailedLoadingScreen}
      {isEmpty && renderEmptyScreen}
    </View>
  );
};

export default memo(LatestAlerts, (prevProps, nextProps) => true);

/**
 * Prop types for the LatestAlerts component.
 */
LatestAlerts.propTypes = {};

/**
 * Default props for the LatestAlerts component.
 */
LatestAlerts.defaultProps = {};
