import useConfigurations from "hooks/useConfigurations";
import useStrategies from "hooks/useStrategies";
import { useEffect, useMemo, useState } from "react";
import { DataConfigsContext } from "./DataConfigsContext";
import { useQuery, useQueryClient } from "react-query";
import { getAllPlans } from "api/plans";
import Constants from "expo-constants";

export const DataConfigsProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const {
    markets,
    time_frame,
    isLoading: marketsIsLoading,
    isLoadingSnapshots,
  } = useConfigurations();
  const {
    strategies,
    getStrategyNameById,
    isLoading: strategiesIsLoading,
    isSuccess: strategiesIsSuccess,
    allStyles,
    allStylesIsLoading,
    allStylesIsSuccess,
    strategyDescriptions,
    strategyDescriptionsIsLoading,
    strategyDescriptionsIsSuccess,
    getAugmentStrategiesWithDescriptions,
  } = useStrategies();

  const [websocketInstance, setWebsocketInstance] = useState(null);

  const { data: plans } = useQuery("plans", getAllPlans, {
    select: (data) => data.data,
  });

  const isLoadingDone = useMemo(() => {
    return (
      !strategiesIsLoading &&
      !marketsIsLoading &&
      !isLoadingSnapshots &&
      !allStylesIsLoading
    );
  }, [
    strategiesIsLoading,
    marketsIsLoading,
    isLoadingSnapshots,
    allStylesIsLoading,
  ]);

  const updateSnapshots = (dataBatch, queryClient) => {
    if (Array.isArray(dataBatch)) {
      queryClient.setQueriesData("getDataSnapshots", (prevRealTimeData) => {
        let updatedRealTimeData = [...(prevRealTimeData || [])];
        dataBatch.forEach((parsedData) => {
          const symbol = parsedData.s;
          if (!symbol) return;
          const foundEntry = updatedRealTimeData.find((e) => e.s === symbol);
          if (foundEntry) {
            updatedRealTimeData = updatedRealTimeData.map((e) => {
              if (e.s === symbol) {
                return { ...e, ...parsedData };
              } else {
                return e;
              }
            });
          } else {
            updatedRealTimeData.push(parsedData);
          }
        });

        return updatedRealTimeData;
      });
    }
  };

  useEffect(() => {
    if (!websocketInstance) {
      const url = `${Constants.expoConfig.extra.env.CHART_WEBSOCKET_ENDPOINT}/tickers`;
      const dataWebsocket = new WebSocket(url);
      dataWebsocket.onmessage = (event) => {
        const data = event?.data;
        const parsedSnapshotsDataBatch = JSON.parse(data)?.snapshots;
        const parsedIndicatorsDataBatch = JSON.parse(data)?.indicators;
        updateSnapshots(parsedSnapshotsDataBatch, queryClient);
        updateSnapshots(parsedIndicatorsDataBatch, queryClient);
      };

      dataWebsocket.onopen = (event) => {
        setWebsocketInstance(dataWebsocket);
      };
      dataWebsocket.onclose = (event) => {
        setWebsocketInstance(null);
      };
    }
  }, [websocketInstance]);

  useEffect(() => {
    return () => {
      websocketInstance?.close?.();
    };
  }, []);

  const childrenMemo = useMemo(() => {
    return children;
  }, [children]);

  const contextValue = useMemo(() => {
    return {
      markets,
      time_frame,
      marketsIsLoading,
      strategies,
      getStrategyNameById,
      strategiesIsLoading,
      isLoadingDone,
      strategiesIsSuccess,
      plans,
      allStyles,
      allStylesIsLoading,
      allStylesIsSuccess,
      strategyDescriptions,
      strategyDescriptionsIsLoading,
      strategyDescriptionsIsSuccess,
      getAugmentStrategiesWithDescriptions,
    };
  }, [
    markets,
    time_frame,
    marketsIsLoading,
    strategies,
    getStrategyNameById,
    strategiesIsLoading,
    isLoadingDone,
    strategiesIsSuccess,
    plans,
    allStyles,
    allStylesIsLoading,
    allStylesIsSuccess,
    strategyDescriptions,
    strategyDescriptionsIsLoading,
    strategyDescriptionsIsSuccess,
  ]);

  return (
    <DataConfigsContext.Provider value={contextValue}>
      {childrenMemo}
    </DataConfigsContext.Provider>
  );
};
