import alertsApi from "api/alerts";
import { useUserContext } from "context/user/UserProvider";
import { useQuery } from "react-query";

export const useFetchActiveAlerts = () => {
  const { token } = useUserContext();
  /**
   * Fetches the active alerts data using the `useQuery` hook from react-query.
   */
  const {
    data = {},
    isLoading,
    isSuccess,
    refetch,
    remove,
    isRefetching,
  } = useQuery(`getAllActiveAlerts${token}`, alertsApi.getAllActiveAlerts);

  return {
    data,
    isLoading,
    isSuccess,
    refetch,
    remove,
    isRefetching,
  };
};
