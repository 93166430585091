import Server from "./server";
import { ROOT } from "constants/ROOT";

export const subscriptionsBasUrl = "/api/v1/subscriptions";

export const createSubscription = async (params) => {
  try {
    const urlParams = {
      market: params.market,
      strategyId: params.strategyId,
      timeFrame: params.timeFrame,
    };
    const queryString = Object.keys(urlParams)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(urlParams[key])
        );
      })
      .join("&");
    const url = `${subscriptionsBasUrl}?${queryString}`;
    console.info(`Preparing API request to ${url}`);
    return await Server.post(url);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};

export const createMultipleSubscriptions = async ({ requests }) => {
  try {
    const url = `${subscriptionsBasUrl}/list`;
    return await Server.post(url, requests);
  } catch (e) {
    throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
  }
};
