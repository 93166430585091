import { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";

const CreateAlert = lazy(() => import("./createAlert"));

const CreateAlertWeb = (props) => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <CreateAlert {...props} />
    </Suspense>
  );
};

export default CreateAlertWeb;
