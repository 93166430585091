import React from "react";
import { Icon } from "@rneui/themed";
import { Button } from "@rneui/base";

const CustomIcon = ({
  name,
  color,
  size = 20,
  type,
  btnTitle = null,
  btnType = "clear",
  onPress,
  buttonStyle = {},
  iconStyle = {},
}) => {
  return (
    <Button
      onPress={onPress}
      icon={
        <Icon
          name={name}
          size={size}
          color={color}
          type={type}
          style={iconStyle}
        />
      }
      title={btnTitle}
      type={btnType}
      titleStyle={{
        color: color,
      }}
      style={buttonStyle}
    />
  );
};
export default CustomIcon;
