import AsyncStorage from "@react-native-async-storage/async-storage";
import Server from "./server";
import moment from "moment";
import { ROOT } from "constants/ROOT";

const activeBaseUrl = "api/v1/subscriptions";
const logBaseUrl = "api/v1/strategies/alert-logs";
const allAlertLogsUrl = "api/v1/strategies/all-alert-logs";
const publicAlerLogsUrl = "api/v1/public/alert-logs";
const publicSubscriptionURL = "api/v1/public/subscription";
const publciALlAlertLogsUrl = "api/";

//********************** TYPE **********************//

/**
 * Custom data type defining a Alert.
 * @typedef {Object} Alert
 * @property {number} id - The unique identifier of the alert.
 * @property {string} action - The action associated with the alert.
 * @property {string} market - The market related to the alert.
 * @property {string} strategy_id - The identifier of the strategy associated with the alert.
 * @property {string} time_frame - The time frame of the alert.
 * @property {string} date - The date of the alert.
 * @property {string} event - The event associated with the alert.
 * @property {string} position - The position related to the alert.
 * @property {string} price - The price of the alert.
 * @property {string} provider_id - The identifier of the provider associated with the alert.
 * @property {string} time_signal - The time signal of the alert.
 * @property {string} exchange - The exchange related to the alert.
 * @property {object} candle - The candle object associated with the alert.
 */

//********************** API **********************//

/**
 * @description - The alertsApi object encapsulates functionality related to alerts.
 * @type {Object}
 */

const alertsApi = {
  /**
   * This function retrieves a list of active alerts from the server.
   * @returns {Promise<Alert[]>} - The list of active alerts
   * @final
   */
  getAllActiveAlerts: async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      if (!token) {
        const response = await Server.get(publicSubscriptionURL);
        return { ...response, data: [response.data] };
      } else {
        const activeData = await Server.get(activeBaseUrl);
        return activeData;
      }
    } catch (error) {
      console.log("===ERROR API :: getAllActiveAlerts===", error);
      throw new Error("COULD_NOT_LOAD_ACTIVE_ALERTS");
    }
  },

  /**
   * This function remove active alert by alertId {params: {id}}
   * @param {object} - params params The parameters of the URL.
   * @returns {Promise<string>} - The success message of the removal.
   * @final
   */
  removeActiveAlertById: async ({ params }) => {
    try {
      const url = `${activeBaseUrl}/${params.id}`;
      return Server.remove(url);
    } catch (e) {
      throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
    }
  },

  /**
   * This function removes a list active alerts by alertId
   * @param {object} - ids params The parameters of the URL.
   * @returns {Promise<string>} - The success message of the removal.
   * @final
   */
  removeActiveAlertsById: async ({ ids }) => {
    try {
      const url = `${activeBaseUrl}`;
      return Server.remove(url, ids);
    } catch (e) {
      throw new Error(e?.response?.data?.message || ROOT.DEFAULT_ERROR);
    }
  },

  /**
   * This function retrieves a list of alert logs from the server.
   * @returns {Promise<Alert[]>} - The list of logs alerts.
   * @final
   */

  getAllAlertsLogs: async ({
    allAlertLogs = false,
    subscriptions,
    pageNumber = 0,
    pageSize = 30,
  }) => {
    const token = await AsyncStorage.getItem("token");
    if (!token) {
      try {
        const url = `${publicAlerLogsUrl}/${pageSize}/${pageNumber}`;
        const response = await Server.get(url);
        if (Array.isArray(response.data?.page?.content)) {
          const result = response.data.page.content.reduce(
            (acc, item, index) => [
              ...acc,
              {
                ...item,
                time: moment(parseInt(item.time) * 1000),
                index: pageNumber * pageSize + index,
              },
            ],
            []
          );
          return {
            ...response.data,
            page: { ...response.data.page, content: result },
          };
        }
        return response.data;
      } catch (e) {
        console.log("====error-AllAlertLogs no token", e);
        throw new Error(e?.response?.data?.message);
      }
    }
    if (!subscriptions || subscriptions.length === 0) return null;
    try {
      const url = `${
        allAlertLogs ? allAlertLogsUrl : logBaseUrl
      }/${pageSize}/${pageNumber}`;
      const response = allAlertLogs
        ? await Server.get(url)
        : await Server.post(
            url,
            subscriptions.map((s) => ({
              market_name: s.market,
              strategy_id: s.strategy_id,
              time_frame: s.time_frame,
            }))
          );
      if (Array.isArray(response.data?.page?.content)) {
        const result = response.data?.page?.content.reduce(
          (acc, item, index) => [
            ...acc,
            {
              ...item,
              time: moment(parseInt(item.time) * 1000),
              index: pageNumber * pageSize + index,
            },
          ],
          []
        );
        return {
          ...response.data,
          page: { ...response.data.page, content: result },
        };
      }
      return response.data;
    } catch (e) {
      console.log("====error-AllAlertLogs", e);
      throw new Error(e?.response?.data?.message);
    }
  },
};

export default alertsApi;
