import { Image, StyleSheet, useWindowDimensions, View } from "react-native";
import React, { useMemo } from "react";
import Onboarding from "react-native-onboarding-swiper";
import { Button, Text, useTheme } from "@rneui/themed";
import { useNavigation } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";
import { setHasSeenOnboarding } from "api/onBoarding";
import { useMutation } from "react-query";
import { STYLES } from "constants/STYLES";

const OnBoardPage1Title = ({ type }) => {
  const { height } = useWindowDimensions();
  const { theme } = useTheme();
  if (type === "title") {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          height: 0.2 * height,
        }}
      >
        <Text
          adjustsFontSizeToFit
          style={{ color: theme.colors.black, fontSize: 30, fontWeight: "700" }}
        >
          Automated
        </Text>
        <Text
          adjustsFontSizeToFit
          style={{
            color: theme.colors.primary,
            fontSize: 30,
            fontWeight: "700",
          }}
        >
          Technical Analysis
        </Text>
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: "80%",
          alignItems: "center",
          height: 0.1 * height,
        }}
      >
        <Text
          style={{ color: theme.colors.grey2, textAlign: "center" }}
          numberOfLines={2}
          adjustsFontSizeToFit
        >
          Access live charts enhanced with automatic pattern recognition.
        </Text>
      </View>
    );
  }
};

const OnBoardPage2Title = ({ type }) => {
  const { height } = useWindowDimensions();
  const { theme } = useTheme();
  if (type === "title") {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          height: 0.2 * height,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Text
            adjustsFontSizeToFit
            style={{
              color: theme.colors.black,
              fontSize: 30,
              fontWeight: "700",
            }}
          >
            Instant
          </Text>
          <Text
            adjustsFontSizeToFit
            style={{
              color: theme.colors.primary,
              fontSize: 30,
              fontWeight: "700",
            }}
          >
            {" "}
            Notifications
          </Text>
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: "80%",
          alignItems: "center",
          height: 0.1 * height,
        }}
      >
        <Text
          style={{ color: theme.colors.grey2, textAlign: "center" }}
          numberOfLines={3}
          adjustsFontSizeToFit
        >
          Subscribe to our indicators for immediate alerts upon pivotal price
          actions.
        </Text>
      </View>
    );
  }
};

const OnBoardPage3Title = ({ type }) => {
  const navigation = useNavigation();
  const { height } = useWindowDimensions();
  const { theme } = useTheme();
  const onBoardingMutation = useMutation({
    mutationFn: setHasSeenOnboarding,
  });
  if (type === "title") {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          height: 0.2 * height,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Text
            adjustsFontSizeToFit
            style={{
              color: theme.colors.black,
              fontSize: 30,
              fontWeight: "700",
            }}
          >
            Start with an{" "}
          </Text>
          <Text
            adjustsFontSizeToFit
            style={{
              color: theme.colors.primary,
              fontSize: 30,
              fontWeight: "700",
            }}
          >
            Email
          </Text>
        </View>
        <Button
          type="solid"
          color={theme.colors.primary}
          titleStyle={{ color: theme.colors.white }}
          title={"Login / Register"}
          buttonStyle={{ borderRadius: STYLES.BORDER_RADIUS.BUTTON }}
          containerStyle={{ marginVertical: 12 }}
          onPress={() =>
            navigation.navigate(SCREENS_NAMES.LOGIN, { isFromOnBoarding: true })
          }
        />
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: "80%",
          alignItems: "center",
          height: 0.1 * height,
        }}
      >
        <Text
          style={{ color: theme.colors.grey2, textAlign: "center" }}
          numberOfLines={3}
          adjustsFontSizeToFit
        >
          or simply take a tour of the app without any registration required.
        </Text>
        <Button
          type="clear"
          color={theme.colors.secondary}
          title={"Explore App"}
          buttonStyle={{
            borderRadius: STYLES.BORDER_RADIUS.BUTTON,
            marginTop: 10,
          }}
          onPress={async () => {
            await onBoardingMutation.mutateAsync();
          }}
        />
      </View>
    );
  }
};

const OnBoarding = () => {
  const { height } = useWindowDimensions();

  const { theme } = useTheme();
  const pages = useMemo(() => {
    return [
      {
        backgroundColor: theme.colors.background,
        image: (
          <Image
            source={require("assets/onboard1.webp")}
            style={{
              height: 0.2 * height,
              resizeMode: "contain",
            }}
          />
        ),
        title: <OnBoardPage1Title type="title" />,
        subtitle: <OnBoardPage1Title type="subtitle" />,
      },
      {
        backgroundColor: theme.colors.background,
        image: (
          <Image
            source={require("assets/onboard2.png")}
            style={{
              height: 0.2 * height,
              resizeMode: "contain",
            }}
          />
        ),
        title: <OnBoardPage2Title type="title" />,
        subtitle: <OnBoardPage2Title type="subtitle" />,
      },
      {
        backgroundColor: theme.colors.background,
        image: (
          <Image
            source={require("assets/onboard3.png")}
            style={{ height: 0.2 * height, resizeMode: "contain" }}
          />
        ),
        title: <OnBoardPage3Title type="title" />,
        subtitle: <OnBoardPage3Title type="subtitle" />,
      },
    ];
  }, []);
  return (
    <View style={{ flex: 1 }}>
      <Onboarding pages={pages} showSkip={false} showDone={false} />
    </View>
  );
};

export default OnBoarding;

const styles = StyleSheet.create({});
