import React, { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";
const AlertsContainer = lazy(() => import("containers/Alerts"));

const Alerts = ({ navigation }) => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <AlertsContainer navigation={navigation} isFromScreen={true} />
    </Suspense>
  );
};

export default Alerts;
