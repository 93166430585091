import { makeStyles } from "@rneui/themed";
import { CARD_STYLES } from "constants/STYLES";

const useStyles = makeStyles((theme, width) => ({
  root: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    padding: 0,
    margin: "auto",
    marginBottom: 12,
    backgroundColor: theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    borderRadius: 12,
  },
  wrapper: {
    borderWidth: 0,
    padding: 0,
    margin: 0,
    width: "100%",
    maxWidth: "100%",
    flex: 1,
    backgroundColor: theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
  },
  timeHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    marginTop: 0,
    width: "100%",
    maxWidth: "100%",
    gap: 5,
    flex: 2,
    ...(width > theme.breakpoints.tablet ? { flexDirection: "row" } : { flexDirection: "column" }),
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    margin: 0,
    backgroundColor: theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
  },
  row: {
    flexDirection: "row",
    ...(width > theme.breakpoints.tablet ? { width: "33%" } : { width: "100%" }),
    justifyContent: "space-between",
    marginVertical: 5,
  },
  status: {
    ...(width > theme.breakpoints.tablet ? { width: "fit-content" } : { width: "100%" }),
  },
  col: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  colEnd: {
    ...(width > theme.breakpoints.tablet ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }),
  },
  label: {
    color: theme.colors.whiteText,
    fontWeight: "700",
    marginRight: 5,
  },
  value: {
    color: theme.colors.whiteText
  },
  card: {
    ...CARD_STYLES,
    paddingVertical: 12,
    paddingHorizontal: 12,
    margin: 12,
    flex: 1,
    backgroundColor: theme.mode === "dark" ? theme.colors.grey5 : theme.colors.white,
    borderBottomWidth: 0,
  },
}));

export default useStyles;
