import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"

const NoAlert = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="SvgjsSvg1001"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 550 550",
    }}
    viewBox="0 0 550 550"
    {...props}
  >
    <Path
      d="M286 499.5c-102.7-44-150.8 22.8-200.8-26.4-50.6-49.8-15-113.2-36-187.7C-33-5.5 203.2 124.2 244.6 95.7 410.9-18.5 539 55.6 515.2 155.6c-9.9 41.6-42.3 91.7-21.2 144.7 47 118.6-81 253.6-208 199.2z"
      style={{
        opacity: 0.19,
        fill: "#0a96a9",
        enableBackground: "new",
      }}
    />
    <Path
      d="m123.1 267 65-27.9c6.9-3 14.2-1.6 16.2 3.2l40.4 94.2c2 4.7-2 11-8.9 13.9l-65 27.9c-6.9 3-14.2 1.6-16.2-3.2L114.2 281c-2-4.8 1.9-11 8.9-14z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#F4925D"
    />
    <Path
      d="m205.4 244.7 135-161.6c65.8 75.6 105.4 163 113.5 264.7l-210.1-13.6-38.4-89.5z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0A96A9"
    />
    <Path
      d="m448.8 306.8-203.3 27.5 208.4 13.5c-1.1-13.9-2.8-27.6-5.1-41z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0B666D"
    />
    <Path
      d="M115.2 283.3 80.9 298c-5.1 2.2-7.5 8.1-5.3 13.2l30.5 71c2.2 5.1 8.1 7.5 13.2 5.3l34.3-14.7-38.4-89.5z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0A96A9"
    />
    <Path
      d="m85.4 311.8 28.6-12.2 4.5 10.5-28.6 12.3-4.5-10.6zm22.5 52.5 28.5-12.3 4.5 10.5-28.5 12.2-4.5-10.4zM96.7 338l28.5-12.2 4.5 10.5-28.5 12.2-4.5-10.5z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0B666D"
    />
    <Path
      d="M331.4 84.4c-8.9-11.2 5-20.9 12.2-14 73.7 82.4 112.3 172.5 121.2 282.7 0 9.9-16.6 13.3-18.6-.9-9.9-103.7-46.6-189.1-114.8-267.8z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#F4925D"
    />
    <Path
      d="m170.8 378.4 56-24 41 95.7c2.8 6.6-.2 14.3-6.8 17.1l-32.1 13.7c-6.6 2.8-14.3-.2-17.1-6.8l-41-95.7z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0A96A9"
    />
    <Path d="m226.8 354.4 8.4-3.6 29.9 46.9-16.7 7.1z" fillRule="evenodd"
      clipRule="evenodd"
      fill="#E86B18"/>
    <Path
      d="m226.9 329.6-65.1 27.9c-6.9 3-15-.3-17.9-7.1l9 20.9c3 6.9 11 10.1 17.9 7.2l10.3-4.4 45.7-19.6 9.1-3.9c6.9-3 10.1-11 7.2-17.9l-9-20.9c2.9 6.7-.3 14.8-7.2 17.8z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#E86B18"
    />
    <Path d="m170.8 378.4 56-24 11.2 26z" fillRule="evenodd"
      clipRule="evenodd"
      fill="#0B666D"/>
    <Path
      d="M190 275c0 19.4-11.2 36.1-27.5 44-3.4 1.7-7.1 3-11 3.8-3.4.7-6.8 1.1-10.4 1.1-15 0-28.4-6.7-37.4-17.3C96.5 298 92 287 92 274.9c0-12.1 4.2-22.9 11.4-31.4 9-10.8 22.5-17.7 37.7-17.7 7.1 0 13.9 1.5 20 4.2 17 7.8 28.9 25.1 28.9 45z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M159.4 279.9c0 7.1-1.6 12.6-4.9 16.5-3.2 3.9-7.8 5.8-13.6 5.8s-10.4-1.9-13.7-5.8-4.9-9.4-4.9-16.5v-9.6c0-7.1 1.6-12.6 4.9-16.5s7.8-5.8 13.6-5.8 10.4 1.9 13.6 5.8c3.2 3.9 4.9 9.4 4.9 16.5l.1 9.6zm-12.2-11.3c0-3.8-.5-6.6-1.5-8.5s-2.6-2.8-4.7-2.8-3.6.9-4.6 2.6-1.5 4.4-1.5 7.9v13.4c0 4 .5 6.8 1.5 8.7s2.6 2.7 4.8 2.7c2.2 0 3.6-.9 4.6-2.6s1.5-4.6 1.5-8.4l-.1-13z"
      style={{
        fill: "#e86b18",
      }}
    />
    <Path
      d="M314.3 462.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6z"
      fill="#0A96A9"
    />
    <Circle cx={101.2} cy={168.6} r={10.9} fill="#0A96A9"/>
    <Circle cx={445.7} cy={79.9} r={14} fill="#0A96A9"/>
    <Circle cx={384.6} cy={444} r={28.1} fill="#0A96A9"/>
    <Circle
      cx={166.9}
      cy={138.9}
      r={20.4}
      fill="#0A96A9"
      transform="rotate(-10.9 166.943 138.876)"
    />
    <Circle cx={121.2} cy={494.3} r={11.9} fill="#0A96A9"/>
  </Svg>
)
export default NoAlert
