import React from "react";
import SwitchItem from "../SwitchItem";

function Preferences() {
  return (
    <SwitchItem
      setting={{
        ID: "theme",
        ICON: "brightness-6",
        TITLE: "Dark Mode",
        TEXT: "Default theme is light.",
        BADGE: "",
      }}
    />
  );
}

export default Preferences;

Preferences.propTypes = {};

Preferences.defaultProps = {};
