import { SCREENS_NAMES } from "constants/screens";
import * as Notifications from "expo-notifications";
import * as Linking from "expo-linking";
import moment from "moment";
import { Platform } from "react-native";

const prefix = Linking.createURL("/");

export const linking = {
  prefixes: [
    prefix,
    "finalyst://",
    "https://app.finalyst.co",
    "https://app.taabamehr.ir",
  ],
  config: {
    initialRouteName: SCREENS_NAMES.HOME,
    screens: {
      [SCREENS_NAMES.HOME]: {
        path: "/",
        screens: {
          [SCREENS_NAMES.MARKETS]: "markets",
          [SCREENS_NAMES.ALERTS]: "alerts",
          [SCREENS_NAMES.CREATE_ALERT]: "create-alerts",
          [SCREENS_NAMES.STRATEGIES]: "strategies",
          [SCREENS_NAMES.PRICING]: "pricing",
          [SCREENS_NAMES.PREFERENCES]: "preferences",
          [SCREENS_NAMES.ACCOUNT]: "account",
        },
      },
      [SCREENS_NAMES.FORGOT_PASSWORD]: "forgot_password",
      [SCREENS_NAMES.REGISTER]: "register",
      [SCREENS_NAMES.EMAIL_VERIFICATION]: "email-verification",
      [SCREENS_NAMES.SETTINGS]: "settings",
      [SCREENS_NAMES.DELETE_ACCOUNT]: "delete-account",
      [SCREENS_NAMES.PAYMENTS_HISTORY]: "payments-history",
      [SCREENS_NAMES.PAYMENT]: {
        path: "payment",
        parse: {
          item: (item) => JSON.parse(atob(item)),
        },
        stringify: {
          item: (itemHash) => btoa(JSON.stringify(itemHash)),
        },
      },
      [SCREENS_NAMES.VIEW_CHART]: {
        path: "view-chart/:marketId/:strategyId?/:timeFrame?/:signal_time?",
        parse: {
          marketId: Number,
          strategyId: Number,
          time: (value) => {
            console.log(value, !isNaN(parseFloat(value)), isFinite(value));
            return !isNaN(parseFloat(value)) && isFinite(value)
              ? moment(parseInt(value) * 1000)
              : value;
          },
        },
      },
      [SCREENS_NAMES.VIEW_STRATEGY]: {
        path: "view-strategy/:strategyId",
        parse: {
          strategyId: Number,
        },
      },
      ...(Platform.OS === "web" ? { [SCREENS_NAMES.NOT_FOUND]: "*" } : {}),
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();

    return Linking.createURL(
      response?.notification.request.content.data?.props?.url
    );
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }) => listener(url);

    // Listen to incoming links from deep linking
    const eventListenerSubscription = Linking.addEventListener(
      "url",
      onReceiveURL
    );

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const url = response.notification.request.content.data.url;
        listener(prefix + url);
      }
    );

    return () => {
      // Clean up the event listeners
      eventListenerSubscription.remove();
      subscription.remove();
    };
  },
};
