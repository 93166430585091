import PropTypes from "prop-types";
import { Alert, Share, View } from "react-native";
import React, { memo, useCallback, useMemo } from "react";
import { Card, Icon, Image, Text } from "@rneui/themed";
import moment from "moment";
import useStyles from "./LatestAlertStyles";
import { useTheme } from "@rneui/themed";
import { POSITION_COLOR } from "constants/POSITION";
import CustomIcon from "components/UI/Icon";
import { useAlertsContext } from "context/alerts/AlertsContext";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getMarketImageUrl } from "api/media";

/**
 * Component for rendering an latest alert.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Alert} props.alert - The alert object to be displayed.
 * @param {Function} props.getStrategyNameById - Retrieves the name of a strategy by its ID.
 * @returns {React.JSX} - The rendered component.
 */

function LatestAlert({ alert, listView = false }) {
  const { navigateToChartView, getStrategyNameById } = useAlertsContext();
  const styles = useStyles();
  const { theme } = useTheme();

  const cardContainerStyle = useMemo(() => {
    return {
      ...styles.card,
    };
  }, [styles.card, listView]);

  const onShare = useCallback(async () => {
    try {
      const message = `${alert.symbol}-${alert.time_frame}-${getStrategyNameById(
        alert.strategy_id
      )}\n${alert.event}\n${alert.time.format("YYYY-MM-DD HH:mm")}\nFinalyst Team`

      const result = await Share.share({
        message
      })

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
  }, [Share.share, JSON.stringify(alert)]);

  const onCardPress = useCallback(() => {
    navigateToChartView(alert);
  }, [JSON.stringify(alert), navigateToChartView]);

  const CardTouchableWrapper = useMemo(() => {
    return memo((props) => (
      <TouchableOpacity
        activeOpacity={0.6}
        style={{
          flex: 1,
        }}
        onPress={onCardPress}
      >
        {props.children}
      </TouchableOpacity>
    ));
  }, [onCardPress]);

  const AlertHeader = useMemo(
    (props) => {
      return memo((props) => (
        <View style={styles.container}>
          <View style={[styles.row]}>
            {/* <Icon
              type={POSITION_ICON[alert.position.toLocaleUpperCase()].type}
              name={POSITION_ICON[alert.position.toLocaleUpperCase()].name}
              color={POSITION_COLOR[alert.position.toLocaleUpperCase()]}
              size={18}
            /> */}
            <View>
              <Image
                source={{
                  uri: getMarketImageUrl({ market: alert.symbol }),
                  cache: true,
                }}
                style={{ width: 20, height: 20, borderRadius: 50 }}
              />
            </View>

            <Text
              style={[
                {
                  color: POSITION_COLOR[alert.position.toLocaleUpperCase()] ?? theme.colors.black,
                  marginLeft: 6,
                  fontSize: 16,
                },
              ]}
            >
              {alert.symbol}
            </Text>
            <Text
              style={[
                {
                  color: theme.colors.grey2,
                  marginLeft: 6,
                  fontSize: 15,
                },
              ]}
            >
              {alert.time_frame}
            </Text>
            <Icon
              type="ant-design"
              name="right"
              size={8}
              color={theme.colors.grey2}
              style={{
                marginLeft: 6,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ ...styles.textRow, ...styles.time }}>
              {moment(alert.time).format("YYYY-MM-DD HH:mm")}
            </Text>
          </View>
        </View>
      ));
    },
    [alert.position, alert.market, alert.price, styles]
  );

  const AlertBody = useMemo(() => {
    return memo((props) => (
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* <Text style={{ ...styles.textRow, ...styles.mr, fontWeight: "300" }}>
          Strategy: {getStrategyNameById(alert.strategy_id)}
        </Text> */}
        {/* <Text style={{ ...styles.textRow, ...styles.mr, fontWeight: "300" }}>
          Time Frame: {alert.time_frame}
        </Text> */}
        <View
          style={{
            maxWidth: "80%",
          }}
        >
          <Text
            style={{
              ...styles.textRow,
              ...styles.title,
              ...styles.whiteText,
              fontWeight: "semibold",
            }}
            adjustsFontSizeToFit
          >
            {alert.event}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomIcon
            type="feather"
            name="share"
            size={18}
            color={theme.colors.grey1}
            onPress={onShare}
          />
        </View>
      </View>
    ));
  }, [
    alert.strategy_id,
    getStrategyNameById,
    alert.time_frame,
    alert.event,
    styles,
  ]);
  const renderView = useMemo(() => {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <Card
          wrapperStyle={{
            borderWidth: 0,
            padding: 0,
          }}
          containerStyle={[cardContainerStyle]}
        >
          <View>
            <CardTouchableWrapper>
              <AlertHeader />
            </CardTouchableWrapper>

            <AlertBody />
            {/* <View style={styles.footer}>
              <Text style={{ ...styles.textRow, ...styles.time }}>
                {moment(alert.time).fromNow()}
              </Text>
              <CustomIcon
                type="feather"
                name="share"
                size={18}
                color={theme.colors.grey1}
                onPress={onShare}
              />
            </View> */}
          </View>
        </Card>
      </View>
    );
  }, [
    CardTouchableWrapper,
    AlertHeader,
    AlertBody,
    alert.time,
    cardContainerStyle,
    onShare,
  ]);

  return renderView;
}

export default memo(LatestAlert, (prevProps, nextProps) => {
  const result =
    JSON.stringify(prevProps.alert) === JSON.stringify(nextProps.alert) &&
    JSON.stringify(prevProps.listView) === JSON.stringify(nextProps.listView);
  return result;
});

/**
 * Prop types for the LatestAlert component.
 */
LatestAlert.propTypes = {
  /**
   * The alert object to be displayed.
   */
  alert: PropTypes.object,
};

/**
 * Default props for the LatestAlert component.
 */
LatestAlert.defaultProps = {
  alert: {},
};
