import { makeStyles } from "@rneui/themed";
import { STYLES, FLEX_POSITIONS } from "constants/STYLES";

const useStyles = makeStyles((theme) => ({
  title: {
    color:
      theme.mode === "dark"
        ? theme.colors.whiteText
        : STYLES.COLORS.TEXT.DARK_GRAY,
    paddingVertical: STYLES.PADDING.SX,
  },
}));

export default useStyles;
