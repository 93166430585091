import {
  ActivityIndicator,
  KeyboardAvoidingView,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import React, { memo, useState } from "react";
import {
  Button,
  Icon,
  Image,
  Input,
  makeStyles,
  Text,
  useTheme,
} from "@rneui/themed";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";
import { ScrollView } from "react-native-gesture-handler";
import { STYLES } from "constants/STYLES";
import { Controller } from "react-hook-form";
import useLogin from "containers/Settings/components/Login/useLogin";
import * as WebBrowser from "expo-web-browser";

const Login = memo(() => {
  const { params } = useRoute();
  const { navigate } = useNavigation();
  const dimensions = useWindowDimensions();
  const styles = useStyles(dimensions.width);
  const { theme } = useTheme();
  const [emailIsFocused, setEmailIsfocused] = useState(false);
  const [passwordIsFocused, setPasswordIsfocused] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const { control, errors, onSubmit, handleSubmit, isLoading } = useLogin();

  const openLink = async (link) => {
    try {
      await WebBrowser.openBrowserAsync(`https://finalyst.co/${link}`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior="height"
      keyboardVerticalOffset={100}
      style={styles.container}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ height: "100%" }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 500,
              flex: 1,
              padding: STYLES.PADDING.LG,
            }}
          >
            <View>
              <View>
                <Text h3 h3Style={styles.title}>
                  Sign in with Email
                </Text>
              </View>
            </View>
            <View style={styles.inputsWrapper}>
              <View style={styles.inputRoot}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      renderErrorMessage={false}
                      keyboardType="email-address"
                      label={"Email Address"}
                      labelStyle={[
                        {
                          color: emailIsFocused
                            ? theme.colors.primary
                            : theme.colors.whiteText,
                        },
                        styles.inputLabelStyle,
                      ]}
                      placeholder="example@gmail.com"
                      placeholderTextColor={theme.colors.grey3}
                      containerStyle={styles.inputContainerStyle}
                      inputContainerStyle={[
                        {
                          borderColor: emailIsFocused
                            ? theme.colors.primary
                            : theme.colors.grey3,
                        },
                        styles.inputInputContainerStyle,
                      ]}
                      inputStyle={[styles.inputStyle, { outline: "none" }]}
                      onFocus={() => setEmailIsfocused(true)}
                      onBlur={() => setEmailIsfocused(false)}
                      onChangeText={onChange}
                    />
                  )}
                  name="username"
                />

                <Text style={styles.errorText}>
                  {errors["username"] && errors["username"]?.message}
                </Text>
              </View>

              <View style={styles.inputRoot}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      renderErrorMessage={false}
                      secureTextEntry={!passwordIsVisible}
                      label={"Password"}
                      labelStyle={[
                        {
                          color: passwordIsFocused
                            ? theme.colors.primary
                            : theme.colors.whiteText,
                        },
                        styles.inputLabelStyle,
                      ]}
                      placeholder="*****"
                      placeholderTextColor={theme.colors.grey3}
                      containerStyle={styles.inputContainerStyle}
                      inputContainerStyle={[
                        {
                          borderColor: passwordIsFocused
                            ? theme.colors.primary
                            : theme.colors.grey3,
                        },
                        styles.inputInputContainerStyle,
                      ]}
                      inputStyle={[styles.inputStyle, { outline: "none" }]}
                      onFocus={() => setPasswordIsfocused(true)}
                      onBlur={() => setPasswordIsfocused(false)}
                      onChangeText={onChange}
                      rightIconContainerStyle={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginLeft: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        marginRight: 0,
                      }}
                      rightIcon={
                        <TouchableOpacity
                          onPress={() =>
                            setPasswordIsVisible(!passwordIsVisible)
                          }
                        >
                          <Icon
                            name={
                              passwordIsVisible
                                ? "eye-off-outline"
                                : "eye-outline"
                            }
                            type="material-community"
                          />
                        </TouchableOpacity>
                      }
                    />
                  )}
                  name="password"
                />
                <Text style={styles.errorText}>
                  {errors["password"] && errors["password"]?.message}
                </Text>
              </View>
            </View>

            <View>
              <Button
                type="text"
                titleStyle={styles.forgotPasswordTitleStyle}
                style={styles.forgotPasswordButtonStyle}
                buttonStyle={styles.forgotPasswordButtonStyle}
                onPress={() => {
                  navigate(SCREENS_NAMES.FORGOT_PASSWORD);
                }}
              >
                Forgot Password?
              </Button>

              <View style={styles.descriptionContainer}>
                <Text style={styles.description}>
                  by signing in, you agree to our
                </Text>
                <TouchableOpacity onPress={() => openLink("terms-of-service")}>
                  <Text style={{ alignSelf: "center" }}> Terms of Use </Text>
                </TouchableOpacity>
                <Text style={styles.description}>as well as our</Text>
                <TouchableOpacity onPress={() => openLink("privacy-policy")}>
                  <Text> Privacy Policy</Text>
                </TouchableOpacity>
              </View>

              <Button
                buttonStyle={styles.loginButtonStyle}
                titleStyle={styles.loginTitleStyle}
                onPress={handleSubmit(onSubmit)}
                disabled={isLoading}
                icon={
                  isLoading && (
                    <ActivityIndicator
                      size={"small"}
                      color={theme.colors.white}
                    />
                  )
                }
                title={"Sign In"}
              />
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ color: theme.colors.grey3 }}>
                  Don't have an account yet?
                </Text>
                <Button
                  type="text"
                  onPress={() => {
                    navigate(SCREENS_NAMES.REGISTER, {
                      isFromOnBoarding: params?.isFromOnBoarding,
                    });
                  }}
                >
                  Sign Up
                </Button>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
});

export default Login;

const useStyles = makeStyles((theme, width) => ({
  container: {
    flex: 1,
    height: "100%",
    justifyContent: "space-between",
  },
  title: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 12,
  },
  description: {
    color: theme.colors.grey2,
  },
  inputsWrapper: {
    marginTop: 24,
  },
  inputRoot: {
    marginBottom: 12,
  },
  inputLabelStyle: {
    fontWeight: "light",
  },
  inputContainerStyle: {
    paddingHorizontal: 0,
  },
  inputInputContainerStyle: {
    marginTop: 6,
    borderBottomWidth: 2,
    borderWidth: 2,
    borderRadius: STYLES.BORDER_RADIUS.LG,
    padding: 6,
  },
  inputStyle: {
    color: theme.colors.black,
    outline: "none",
  },
  forgotPasswordTitleStyle: {
    color: theme.colors.primary,
    fontSize: 16,
    fontWeight: "light",
  },
  forgotPasswordButtonStyle: {
    width: "auto",
    alignSelf: "flex-start",
    marginBottom: 12,
    paddingHorizontal: 0,
    paddingVertical: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  loginButtonStyle: {
    ...(width < theme.breakpoints.tablet ? { width: "100%" } : { width: 400 }),
    marginVertical: STYLES.MARGIN.MD,
    marginHorizontal: "auto",
    borderRadius: STYLES.BORDER_RADIUS.BUTTON,
    padding: 12,
  },
  loginTitleStyle: {
    color: theme.colors.white,
  },
  errorText: {
    paddingHorizontal: 6,
    color: theme.colors.error,
    minHeight: 20,
    margin: 5,
    marginBottom: 12,
  },
}));
