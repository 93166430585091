import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    paddingHorizontal: STYLES.PADDING.MD,
    backgroundColor: theme.colors.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4.65,
    elevation: 8,
    alignItems: "center"
  },
  background: {
    backgroundColor: theme.colors.white,
  },
  indicator: {
    backgroundColor: theme.colors.grey2,
  },
  width: {
    width: STYLES.BOTTOM_SHEET.MAX_WIDTH,
  }
}));
