import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import curlirize from "axios-curlirize";
import Constants from "expo-constants";

export const METHOD = {
  GET: "GET",
  HEAD: "HEAD",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
  POST: "POST",
};

const defaultOptions = {
  baseURL: Constants.expoConfig.extra.env.BASE_URL,
  method: METHOD.GET,
  headers: {
    "Content-Type": "application/json",
  },
};

const ApiService = () => {
  const instance = axios.create(defaultOptions);
  // if (process.env.NODE_ENV === "development") curlirize(instance);

  instance.interceptors.request.use(async (config) => {
    try {
      const token = await AsyncStorage.getItem("token");

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        // console.log('====get token', token)
      }
    } catch (error) {
      console.log(
        "===ERROR SERVER STORAGE :: Failed to retrieve token from async-storage:",
        error
      );
    }

    config.params = Object.assign({}, config?.params || {});

    return config;
  });

  const get = (url, options = {}) => {
    return trackPromise(instance.get(url, { ...defaultOptions, ...options }));
  };

  const post = (url, data, options = {}) =>
    trackPromise(instance.post(url, data, { ...defaultOptions, ...options }));

  const put = (url, data, options = {}) =>
    trackPromise(instance.put(url, data, { ...defaultOptions, ...options }));

  const patch = (url, data, options = {}) =>
    trackPromise(instance.patch(url, data, { ...defaultOptions, ...options }));

  const remove = (url, data, options = {}) =>
    trackPromise(instance.delete(url, { ...defaultOptions, ...options, data }));

  return {
    get,
    post,
    put,
    patch,
    remove,
  };
};

export default ApiService();
