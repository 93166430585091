import ViewChart from "Screens/ViewChart";
import { useEffect } from "react";
import { InteractionManager, Platform, View } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const ViewChartFullScreen = ({ navigation, route }) => {
  const insets = useSafeAreaInsets();
  useEffect(() => {
    if (Platform.OS !== "web") {
      InteractionManager.runAfterInteractions(() => {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
      });

      return () => {
        InteractionManager.runAfterInteractions(() => {
          ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        });
      };
    }
  }, []);

  return (
    <View
      style={{
        flex: 1,
        marginRight: insets.right,
      }}
    >
      <ViewChart isFullScreen={true} {...{ navigation, route }} />
    </View>
  );
};
