import { Suspense, lazy } from "react";
import SuspenseFallback from "components/SuspenseFallback";

const ViewChart = lazy(() => import("./viewChart"));

const ViewChartWeb = (props) => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <ViewChart {...props} />
    </Suspense>
  );
};

export default ViewChartWeb;
