import { makeStyles } from "@rneui/themed";
import { STYLES } from "constants/STYLES";

export const useStyles = makeStyles((theme) => ({
  title: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: STYLES.MARGIN.MD,
  },
  upgradeIcon: {
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50
  },
  titleText: {
    color: theme.colors.black,
    fontSize: 20,
    fontWeight: "bold",
  },
  text: {
    marginBottom: STYLES.MARGIN.SX,
    fontWeight: "200",
    textAlign: "left"
  },
  box: {
    marginBottom: STYLES.MARGIN.SX,
  },
  featureBox: {
    alignItems: "center",
    flexDirection: "row",
    gap: 10,
    marginTop: STYLES.MARGIN.SM,
    justifyContent: "flex-start",
  },
  featureBoxText: {
    fontWeight: "500",
  },
  buttonText: {
    fontSize: 16, // TITLE
    color: theme.colors.white,
  },
  buttonContainer: {
    marginTop: STYLES.MARGIN.SM,
    alignItems: "center",
  },
  clearButton: {
    fontSize: 14,
  },
}));
