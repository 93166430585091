import { useMutation } from "react-query";
import Toast from "react-native-toast-message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSchema from "hooks/useSchema";
import { registerUser } from "api/auth";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";
import { useState } from "react";

/**
 * Hook for handling register operations.
 * @returns {Object} - The active alert hook object.
 * @property {Function} onRegister - A helper function for register new user.
 */

const useRegister = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const schema = useSchema();

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = {
    username: schema.EMAIL(),
    password: schema.PASSWORD(),
    confirmation: schema.CONFIRMATION({ field: "Confirm Password" }),
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object(validationSchema)),
  });

  /**
   * Mutation function for register new user.
   * @param {Object} object
   * @param {string} email - The Email of new user.
   * @param {string} password - The Password of new user.
   */

  const mutation = useMutation((payload) => {
    return registerUser({ payload });
  });

  const onSave = async (payload) => {
    const result = await mutation.mutateAsync(payload, {
      onError: (e) => {
        throw new Error(e);
      },
    });

    if (result.status === 200) {
      navigation.navigate(SCREENS_NAMES.EMAIL_VERIFICATION, {
        email: payload.email,
        from: SCREENS_NAMES.REGISTER,
        isFromOnBoarding: params?.isFromOnBoarding,
      });
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully registered",
      });
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        email: data.username,
        password: data.password,
      };
      await onSave(payload);
    } catch (error) {
      Toast.show({
        text1: "Couldn't Sign Up",
        text2: error.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    control,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
  };
};

export default useRegister;

/*
  const onRegister1 = async () => {
    const payload = {
      email: "user24@gmail.com",
      password: "Welcome123",
    };

    try {
      const res = await registerUser({ payload });
      if (res.status === 200) {
        Toast.show({
          type: "success",
          position: "top",
          text1: "Successfully register",
        });
        navigation.navigate(SCREENS_NAMES.ACCOUNT);
      }
    } catch (error) {
      console.log("===error===", error);
    }
  };

*/
