import * as React from "react";
import { makeStyles } from "@rneui/themed";

export const useStyles = makeStyles((theme, props = {}) => ({
  container: {
    flexDirection: "row",
    width: "100%",
    // height: 50,
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 20
  },
  button: {
    paddingHorizontal: 4,
    padding: 4,
    borderRadius: 6,
    minWidth: 40,
    minHeight: 40,
    maxHeight: 40
  },
  title: {
    fontSize: 16,
    paddingVertical: 0
  }
}));
