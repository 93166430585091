import React, { useEffect, useRef } from "react";
import { Linking, Platform, View } from "react-native";
import { useStyles } from "./styles";
import { Button, Icon, Text, useTheme } from "@rneui/themed";
import { SCREENS_NAMES } from "constants/screens";
import LinearGradientButton from "components/LinearGradientButton";
import Constants from "expo-constants";
import ModalRenderer from "components/ModalRenderer";
import * as WebBrowser from "expo-web-browser";
import { useNavigation } from "@react-navigation/native";

const SubscriptionModal = ({ showBuySubscription, setShowBuySubscription }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    showBuySubscription && openModal();
  }, [showBuySubscription]);

  const openModal = () => {
    ref.current?.handleOpenModalPress();
  };

  const closeModal = () => {
    ref.current?.handleCloseModalPress();
  };

  const openLink = async () => {
    try {
      Platform.OS !== "web"
        ? await WebBrowser.openBrowserAsync(Constants.expoConfig.extra.env.BASE_URL + SCREENS_NAMES.PRICING)
        : navigation.navigate(SCREENS_NAMES.HOME, { screen: SCREENS_NAMES.PRICING })
    } catch (e) {
      console.error(e);
    }
  };

  const title = (
    <View style={styles.title}>
      <Icon
        name="crown-outline"
        type="material-community"
        color={theme.colors.warning}
        size={40}
        style={styles.upgradeIcon}
      />
      <Text style={styles.titleText}>Upgrade Plan</Text>
      <Icon
        name="crown-outline"
        type="material-community"
        color={theme.colors.warning}
        size={40}
        style={styles.upgradeIcon}
      />
    </View>
  );

  const content = (
    <>
      <View style={styles.box}>
        <Text style={styles.text}>
          Please upgrade your plan to access all features.
        </Text>
      </View>
      <View style={styles.box}>
        {Platform.OS !== "web" && (
          <Text style={styles.text}>
            <Text>To </Text>
            <Text style={{ fontWeight: "bold" }}>unlock </Text>
            <Text>premium features, Please use our </Text>
            <Text style={{ fontWeight: "bold" }}>website </Text>
            to purchase a subscription plan.
          </Text>
        )}
      </View>
      <View style={styles.box}>
        <View style={styles.featureBox}>
          <Icon
            name="bell-plus-outline"
            type="material-community"
            color={theme.colors.primary}
            size={25}
          />
          <Text style={styles.featureBoxText}>
            Create more Alert Notifications
          </Text>
        </View>
        <View style={styles.featureBox}>
          <Icon
            name="timetable"
            type="material-community"
            color={theme.colors.primary}
            size={25}
          />
          <Text style={styles.featureBoxText}>Access to more Time Frames</Text>
        </View>
      </View>
      <View style={styles.box}>
        <LinearGradientButton
          title="PRICING PAGE"
          colors={[
            theme.mode === "dark" ? theme.colors.grey5 : theme.colors.primary,
            theme.colors.long,
          ]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          linearGradientStyles={styles.buttonContainer}
          onPress={openLink}
        />
        <Button
          title="Not Now"
          type="clear"
          onPress={closeModal}
          titleStyle={styles.clearButton}
        />
      </View>
    </>
  );

  return (
    <ModalRenderer
      ref={ref}
      showModal={showBuySubscription}
      setShowModal={setShowBuySubscription}
      title={title}
      content={content}
      useAnimatedSnapPoints={true}
    />
  );
};

export default SubscriptionModal;
