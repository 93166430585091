import Toast from "react-native-toast-message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSchema from "hooks/useSchema";
import { forgotPassword } from "api/auth";
import { useNavigation } from "@react-navigation/native";
import { SCREENS_NAMES } from "constants/screens";
import { useState } from "react";

/**
 * Hook for handling register operations.
 * @returns {Object} - The active alert hook object.
 * @property {Function} onRegister - A helper function for register new user.
 */

const useForgotPassword = () => {
  const navigation = useNavigation();
  const schema = useSchema();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = {
    username: schema.EMAIL(),
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object(validationSchema)),
  });

  const onSave = async (payload) => {
    try {
      await forgotPassword({ payload });
      navigation.navigate(SCREENS_NAMES.EMAIL_VERIFICATION, {
        email: payload.email,
        from: SCREENS_NAMES.FORGOT_PASSWORD
      });
      Toast.show({
        type: "success",
        position: "top",
        text1: "Successfully send email",
      });
    } catch (error) {
      Toast.show({
        text1: "Error!",
        text2: error.message,
        type: "error",
      });
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        email: data.username,
      };
      await onSave(payload);
    } catch (error) {
      Toast.show({
        text1: "Error!",
        text2: error.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    control,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
  };
};

export default useForgotPassword;