import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import PropTypes from "prop-types";
import AlertItem from "../ActiveAlert";
import { useAlertsContext } from "context/alerts/AlertsContext";
import { useAlertSharedStyles } from "containers/Alerts/core/alertSharedStyles";
import { Icon, Overlay, useTheme, Button, Badge } from "@rneui/themed";
import CreateAlertModal from "components/CreateAlertModal";
import { FlatList } from "react-native-gesture-handler";
import Checkbox from "expo-checkbox";
import { useUserContext } from "context/user/UserProvider";
import { useActiveAlerts } from "./useActiveAlerts";
import NoAlert from "components/UI/NoAlert";
import NotFound from "components/UI/NotFound";
import ModalRenderer from "components/ModalRenderer";
import { STYLES } from "constants/STYLES";
import SearchAlerts from "containers/Alerts/components/SearchAlerts";
import NoMessage from "components/UI/NoMessage";

/**
 * Component for displaying a list of active alerts.
 * This component fetches and displays active alerts, including handling loading states and empty states.
 * It also provides functionality to navigate to the alert creation modal.
 *
 * @component
 * @returns {React.Element} - The rendered component.
 */
const ActiveAlerts = () => {
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const { isSignedIn } = useUserContext();
  const alertSharedStyles = useAlertSharedStyles();

  const {
    activeAlerts = [],
    getStrategyNameById,
    isLoading,
    isSuccess,
    hasCreateAlert = true,
    isFromScreen,
  } = useAlertsContext();

  const list = useMemo(() => activeAlerts, [activeAlerts]);

  const {
    displayedAlerts,
    isEditing,
    selectedItems,
    loading,
    handleConfirmDelete,
    onChangeActiveAlertSelect,
    onPressAddAlert,
    isAllItemsSelected,
    onToggleEdit,
    onSelectAllPress,
    showCreateAlertModal,
    setShowCreateAlertModal,
    deleteRef,
    showDeleteModal,
    setShowDeleteModal,
    closeDeleteModal,
    searchRef,
    showSearchModal,
    setShowSearchModal,
    handleSearch,
    handleClearSearch,
    filters,
    isFilterableData,
  } = useActiveAlerts({ list });

  /**
   * Renders an individual alert item.
   *
   * @param {Object} item - The alert item.
   * @returns {JSX.Element} - The rendered alert item component.
   */
  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = selectedItems.includes(item.id);
      return (
        <AlertItem
          alert={item}
          key={item.id}
          {...{
            getStrategyNameById,
            onChangeActiveAlertSelect,
            isSelected,
            isEditing,
          }}
        />
      );
    },
    [getStrategyNameById, onChangeActiveAlertSelect, selectedItems, isEditing]
  );

  const failedLoadingAlerts = useMemo(() => {
    return !isLoading && (!Array.isArray(list) || !isSuccess);
  }, [list, isLoading, isSuccess]);

  const isEmpty = useMemo(() => {
    return (
      (!isLoading &&
        !failedLoadingAlerts &&
        Array.isArray(activeAlerts) &&
        activeAlerts.length === 0) ||
      !Array.isArray(activeAlerts)
    );
  }, [isLoading, activeAlerts]);

  const renderEmptyScreen = useMemo(() => {
    return (
      <View style={alertSharedStyles.fullScreen}>
        <View style={alertSharedStyles.centerView}>
          <NoAlert width={150} height={150} />
          <View style={alertSharedStyles.warningTextContainer}>
            <Text style={alertSharedStyles.warningText}>
              You've no active alerts.
            </Text>
            <Text style={alertSharedStyles.warningText}>
              Try creating new ones.
            </Text>
          </View>
          {!hasCreateAlert && (
            <View style={alertSharedStyles.button}>
              <Icon
                name="bell-plus-outline"
                type="material-community"
                color={theme.colors.white}
                size={20}
              />
              <Text
                style={alertSharedStyles.buttonText}
                onPress={onPressAddAlert}
              >
                Create Alert
              </Text>
              <CreateAlertModal
                showCreateAlertModal={showCreateAlertModal}
                setShowCreateAlertModal={setShowCreateAlertModal}
              />
            </View>
          )}
        </View>
      </View>
    );
  }, [alertSharedStyles]);

  const renderFailedLoadingScreen = useMemo(() => {
    return (
      <View style={alertSharedStyles.fullScreen}>
        <View style={alertSharedStyles.centerView}>
          <NotFound width={150} height={150} />
          <View style={alertSharedStyles.warningTextContainer}>
            <Text style={alertSharedStyles.warningText}>
              There was an error while loading alerts.
            </Text>
            <Text style={alertSharedStyles.warningText}>
              Please check your network connection.
            </Text>
          </View>
        </View>
      </View>
    );
  }, [alertSharedStyles]);

  return (
    <View
      style={{
        flex: 1,
        ...(isFromScreen && Platform.OS !== "web"
          ? {
            backgroundColor: theme.colors.white,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            paddingTop: 12,
          }
          : {}),
      }}
    >
      {!failedLoadingAlerts && !isEmpty && (
        <>
          {isSignedIn && (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                paddingHorizontal: 15,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {isEditing && (
                  <Checkbox
                    value={isAllItemsSelected}
                    onValueChange={onSelectAllPress}
                    color={
                      theme.mode === "light"
                        ? theme.colors.primary
                        : theme.colors.grey2
                    }
                  />
                )}
                {!isLoading && (
                  <Text
                    style={{
                      fontSize: 16,
                      marginLeft: 10,
                      color: theme.colors.black,
                    }}
                  >
                    {displayedAlerts.length}{" "}
                    alerts
                  </Text>
                )}
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {isEditing && (
                  <TouchableOpacity
                    onPress={
                      selectedItems.length > 0
                        ? () => setShowDeleteModal(true)
                        : undefined
                    }
                  >
                    <Icon
                      type="feather"
                      name="trash-2"
                      size={20}
                      color={selectedItems.length > 0
                        ? theme.colors.error
                        : theme.colors.grey5}
                    />
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  onPress={onToggleEdit}
                  style={{ marginLeft: 12 }}
                >
                  <Icon
                    type="feather"
                    name={isEditing ? "x" : "edit"}
                    size={20}
                    color={theme.colors.black}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={{ marginLeft: 12 }}
                  onPress={() => setShowSearchModal(true)}
                >
                  <Icon
                    type="feather"
                    name="search"
                    size={20}
                    color={
                      isFilterableData ? theme.colors.primary : theme.colors.whiteText
                    }
                  />
                  {isFilterableData && (
                    <Badge
                      status="primary"
                      containerStyle={{ position: 'absolute', top: 0, left: 10 }}
                    />
                  )}
                </TouchableOpacity>

                <ModalRenderer
                  ref={searchRef}
                  showModal={showSearchModal}
                  setShowModal={setShowSearchModal}
                  title={
                    <Text
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        color: theme.colors.black,
                      }}
                    >
                      Search Alerts
                    </Text>
                  }
                  content={
                    <SearchAlerts
                      filters={filters}
                      onSearch={handleSearch}
                      onClearSearch={handleClearSearch}
                    />
                  }
                  useAnimatedSnapPoints={true}
                />

              </View>
            </View>
          )}
          {displayedAlerts.length === 0 && (
            <View style={alertSharedStyles.fullScreen}>
              <View style={alertSharedStyles.centerView}>
                <NoMessage width={150} height={150}/>
              </View>
            </View>
          )}
          <FlatList
            data={displayedAlerts}
            keyExtractor={(alert) => alert.id}
            renderItem={renderItem}
          />
        </>
      )}
      {failedLoadingAlerts && renderFailedLoadingScreen}
      {!failedLoadingAlerts && isEmpty && renderEmptyScreen}
      {loading && (
        <Overlay
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: 50,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator color={theme.colors.error}/>
          </View>
        </Overlay>
      )}
      <ModalRenderer
        ref={deleteRef}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title={
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: theme.colors.black,
            }}
          >
            Confirm Alert Removal
          </Text>
        }
        content={
          <View>
            <Text style={{ color: theme.colors.grey1 }}>
              Are you sure you want to remove {selectedItems.length} alerts?
              This action cannot be undone.
            </Text>
            <View
              style={{
                justifyContent: "space-evenly",
                width: "100%",
                marginVertical: 10,
              }}
            >
              <Button
                type="outline"
                buttonStyle={{
                  borderRadius: STYLES.BORDER_RADIUS.BUTTON,
                  borderColor: theme.colors.error,
                  padding: 12,
                  alignItems: "center",
                  marginVertical: 5,
                  marginHorizontal: "auto",
                  ...(width > theme.breakpoints.tablet
                    ? { width: 200 }
                    : { width: "100%" }),
                }}
                titleStyle={{
                  fontSize: 16, // TITLE
                  color: theme.colors.error,
                }}
                onPress={handleConfirmDelete}
                title={"Yes, Remove"}
              ></Button>
              <Button
                buttonStyle={{
                  backgroundColor: theme.colors.error,
                  borderRadius: STYLES.BORDER_RADIUS.BUTTON,
                  padding: 12,
                  alignItems: "center",
                  marginVertical: 5,
                  marginHorizontal: "auto",
                  ...(width > theme.breakpoints.tablet
                    ? { width: 200 }
                    : { width: "100%" }),
                }}
                titleStyle={{
                  fontSize: 16, // TITLE
                  color: theme.colors.white,
                }}
                onPress={closeDeleteModal}
                title={"Cancel"}
              ></Button>
            </View>
          </View>
        }
        useAnimatedSnapPoints={true}
      />
    </View>
  );
};

export default memo(ActiveAlerts, () => true);

/**
 * Prop types for the ActiveAlerts component.
 */
ActiveAlerts.propTypes = {
  /**
   * Retrieves the name of a strategy by its ID.
   */
  getStrategyNameById: PropTypes.func,
};

/**
 * Default props for the ActiveAlerts component.
 */
ActiveAlerts.defaultProps = {
  getStrategyNameById: undefined,
};
