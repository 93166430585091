import { makeStyles } from "@rneui/themed";
import { STYLES, FLEX_POSITIONS, CARD_STYLES } from "constants/STYLES";
import { Platform, StyleSheet } from "react-native";

const useStyles = makeStyles((theme) => ({
  container: {
    marginHorizontal: STYLES.MARGIN.MD,
    marginVertical: STYLES.MARGIN.SX,
  },
  webContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  warningTextWrapper: {
    ...CARD_STYLES,
    borderWidth: theme.mode === "dark" ? StyleSheet.hairlineWidth : 0,
    borderColor: theme.colors.warning,
    ...(Platform.OS === "web" && {
      margin: 0,
      padding: STYLES.PADDING.SX,
    }),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 12,
    backgroundColor: `${theme.colors.warning}33`,
  },
  warningText: {
    color: theme.colors.black,
    marginLeft: 6,
  },
}));

export default useStyles;
